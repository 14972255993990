<template>
  <div class="dropdown-main-div">
    <v-select
      class="connect-select custom-field"
      solo
      :items="getUserShops"
      item-text="store_name"
      item-value="id"
      hide-details
      v-model="selectedShop"
      :append-icon="user.s_account_id?'':'$grayDownArrow'"
    >
      <template v-slot:label>
        <span class="custom-label">創作ダイニングredish 麻布十番店</span>
      </template>
      <template v-slot:selection="{ item }">
        {{ getText(item) }}
      </template>
      <template v-slot:item="{ item }">
        {{ getText(item) }}
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "ShopListDropdown",
  computed: {
    user() {
      return this.$store.getters.user;
    },
    getUserShops() {
      return this.$store.getters.getShops;
    },
    getfirstShopObject() {
      return this.$store.getters.getFirstShopObject;
    },
    selectedShop: {
      get() {
        return this.getfirstShopObject.id;
      },
      set(value) {
        let singleShop = this.getUserShops.find(i => {
          return i.id === value;
        });
        this.$store.commit("FIRST_SHOP_OBJECT", {
          shop: singleShop
        });
        this.$root.$emit("shopChanged", this.getfirstShopObject);
        this.getAuthShopUserDataApi().finally(() => {
          this.$emit("shopChanged");
        });
        this.$emit("shopChanged");
        return this.getfirstShopObject.id;
      }
    }
  },
  methods: {
    getText(item) {
      return item.store_display_name
        ? item.store_display_name
        : item.store_name;
    }
  }
};
</script>

<style lang="scss">
.dropdown-main-div {
  .connect-select,
  .connect-select.v-text-field {
    margin: 8px 0 20px !important;
    .v-input__control > .v-input__slot {
      // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      //   0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15) !important;
    }
  }
  .connect-select .v-icon__component,
  .connect-select .v-icon__svg {
    width: 15px !important;
  }
}

.custom-label {
  font-size: 14px !important;
  color: #333333;
  position: relative;
  top: -2.5px;
}
</style>
