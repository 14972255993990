<template>
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4.06699C0.416666 4.25944 0.416667 4.74056 0.75 4.93301L7.5 8.83013C7.83333 9.02258 8.25 8.78202 8.25 8.39711L8.25 0.602887C8.25 0.217986 7.83333 -0.0225771 7.5 0.169873L0.75 4.06699Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "WhiteArrowLeft"
};
</script>

<style lang="scss" scoped></style>
