<template>
  <svg
    style="width:71px;height:26px"
    width="69"
    height="22"
    viewBox="0 0 69 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.73893 2.56521C3.40633 2.64858 3.15688 2.79101 2.90744 3.03071C2.6363 3.29473 2.49169 3.54832 2.41577 3.90614C2.19524 4.92747 3.04843 5.91406 4.15829 5.91406C5.13077 5.91406 5.9225 5.14633 5.91888 4.21184C5.91527 3.74634 5.73812 3.34684 5.38745 3.01334C4.94278 2.58952 4.31013 2.4193 3.73893 2.56521Z"
      fill="#6E77F0"
    />
    <path
      d="M3.3043 6.38565C2.87048 6.49334 2.68249 6.62188 2.07513 7.21244C1.20749 8.06355 1.22493 8.23603 0.653731 9.00029C0.650116 8.7328 0.303059 9.47275 0.653731 9.00029C0.653731 9.26084 0.458511 11.0903 0.653731 11.257C1.5184 10.5856 0.653731 10.5003 0.524221 11.263C0.914661 11.263 1.12073 11.1241 1.5184 10.5856C1.63047 10.4328 1.85461 10.1479 2.01729 9.95336C2.2812 9.63029 2.31012 9.60597 2.31735 9.69282C2.32458 9.74493 2.34266 10.5266 2.36435 11.4298C2.38966 12.6179 2.38604 13.1251 2.35712 13.2536C2.33543 13.3543 2.22336 13.6183 2.10767 13.8407C1.40633 15.1851 1 16.5 0.976147 18.5C1 20.5 0.976147 19.9721 0.976119 20.2957C0.625446 20.2957 1.12796 20.6049 0.976119 20.2957C1.16049 20.2957 1.44971 20.191 1.60155 20.0625C1.78954 19.9096 1.85099 19.7776 2.12575 18.923C2.62826 17.3667 3.05124 16.2933 3.63328 15.126C3.84658 14.6918 3.88996 14.6293 3.95865 14.6397C4.02734 14.6501 4.13218 14.9245 4.89136 17.0888C5.83131 19.7637 5.80601 19.7116 6.16391 19.8888C6.33744 19.9756 6.38805 19.986 6.61581 19.9721C7.22316 19.9374 7.66421 19.4927 7.59191 18.9821C7.58106 18.8952 7.15809 17.6446 6.65196 16.1995C6.14945 14.7543 5.7084 13.462 5.67947 13.33C5.58186 12.9166 5.47702 11.2873 5.47341 10.1931V9.7623L5.70117 9.96031C6.37721 10.5474 7.19424 11.1414 7.93173 11.5792C8.54632 11.9439 8.74154 11.9891 9.04521 11.8466C9.21151 11.7702 9.39588 11.5583 9.47542 11.3568C9.54772 11.1692 9.53326 10.7628 9.45011 10.5926C9.33081 10.3563 9.24405 10.2799 8.67285 9.92557C7.45092 9.16131 6.59412 8.40747 5.59633 7.21592C5.14804 6.68441 4.95644 6.53503 4.566 6.41691C4.3274 6.34744 4.20809 6.33007 3.88996 6.33354C3.67666 6.33354 3.41275 6.35786 3.3043 6.38565Z"
      fill="#6E77F0"
    />
    <path
      d="M19.8353 2.56521C19.5157 2.64858 19.276 2.79101 19.0363 3.03071C18.7757 3.29473 18.6368 3.54832 18.5638 3.90614C18.3519 4.92747 19.1718 5.91406 20.2382 5.91406C21.1727 5.91406 21.9335 5.14633 21.93 4.21184C21.9266 3.74634 21.7564 3.34684 21.4194 3.01334C20.9921 2.58952 20.3842 2.4193 19.8353 2.56521Z"
      fill="#6E77F0"
    />
    <path
      d="M19.4181 6.38584C19.0012 6.49353 18.8206 6.62207 18.237 7.21264C17.4032 8.06375 16.8231 8.74463 16.2742 9.50889C15.9372 9.98135 15.8677 10.1307 15.8712 10.3982C15.8712 10.6588 15.9581 10.8602 16.1457 11.027C16.3367 11.2007 16.5 11.2632 16.7466 11.2632C17.1218 11.2632 17.3198 11.1243 17.702 10.5858C17.8097 10.433 18.025 10.1481 18.1814 9.95356C18.435 9.63048 18.4628 9.60616 18.4697 9.69301C18.4767 9.74512 18.494 10.5268 18.5149 11.43C18.5392 12.6181 18.5357 13.1252 18.5079 13.2538C18.4871 13.3545 18.3794 13.6185 18.2682 13.8409C17.5943 15.1853 17.0385 16.6096 16.4826 18.4021C16.2117 19.2741 16.1873 19.4617 16.3263 19.75C16.4722 20.0592 16.8439 20.2954 17.1809 20.2954C17.3581 20.2954 17.636 20.1912 17.7819 20.0627C17.9625 19.9098 18.0216 19.7778 18.2856 18.9232C18.7685 17.3669 19.1749 16.2935 19.7342 15.1262C19.9392 14.692 19.9809 14.6294 20.0469 14.6399C20.1129 14.6503 20.2136 14.9247 20.9431 17.089C21.8464 19.7639 21.822 19.7118 22.166 19.889C22.3327 19.9758 22.3813 19.9862 22.6002 19.9723C23.1838 19.9376 23.6076 19.4929 23.5382 18.9823C23.5277 18.8954 23.1213 17.6448 22.6349 16.1997C22.1521 14.7545 21.7282 13.4622 21.7004 13.3302C21.6067 12.9168 21.5059 11.2875 21.5024 10.1933V9.76249L21.7213 9.9605C22.3709 10.5476 23.156 11.1416 23.8647 11.5794C24.4553 11.9441 24.6429 11.9893 24.9347 11.8468C25.0945 11.7704 25.2716 11.5585 25.3481 11.357C25.4175 11.1694 25.4036 10.763 25.3237 10.5928C25.2091 10.3565 25.1257 10.2801 24.5769 9.92577C23.4027 9.1615 22.5794 8.40766 21.6205 7.21611C21.1898 6.6846 21.0057 6.53522 20.6305 6.41711C20.4012 6.34763 20.2866 6.33026 19.9809 6.33373C19.7759 6.33373 19.5223 6.35805 19.4181 6.38584Z"
      fill="#6E77F0"
    />
    <path
      d="M35.7025 2.56521C35.3829 2.64858 35.1432 2.79101 34.9035 3.03071C34.6429 3.29473 34.504 3.54832 34.431 3.90614C34.2191 4.92747 35.0389 5.91406 36.1054 5.91406C37.0399 5.91406 37.8007 5.14633 37.7972 4.21184C37.7938 3.74634 37.6235 3.34684 37.2866 3.01334C36.8593 2.58952 36.2513 2.4193 35.7025 2.56521Z"
      fill="#6E77F0"
    />
    <path
      d="M35.2853 6.38584C34.8684 6.49353 34.6878 6.62207 34.1041 7.21264C33.2704 8.06375 32.6903 8.74463 32.1414 9.50889C31.8044 9.98135 31.7349 10.1307 31.7384 10.3982C31.7384 10.6588 31.8253 10.8602 32.0128 11.027C32.2039 11.2007 32.3672 11.2632 32.6138 11.2632C32.989 11.2632 33.187 11.1243 33.5692 10.5858C33.6768 10.433 33.8922 10.1481 34.0486 9.95356C34.3022 9.63048 34.3299 9.60616 34.3369 9.69301C34.3438 9.74512 34.3612 10.5268 34.3821 11.43C34.4064 12.6181 34.4029 13.1252 34.3751 13.2538C34.3543 13.3545 34.2466 13.6185 34.1354 13.8409C33.4615 15.1853 32.9056 16.6096 32.3498 18.4021C32.0788 19.2741 32.0545 19.4617 32.1935 19.75C32.3394 20.0592 32.7111 20.2954 33.0481 20.2954C33.2252 20.2954 33.5032 20.1912 33.6491 20.0627C33.8297 19.9098 33.8888 19.7778 34.1528 18.9232C34.6357 17.3669 35.0421 16.2935 35.6014 15.1262C35.8064 14.692 35.848 14.6294 35.9141 14.6399C35.9801 14.6503 36.0808 14.9247 36.8103 17.089C37.7135 19.7639 37.6892 19.7118 38.0331 19.889C38.1999 19.9758 38.2485 19.9862 38.4674 19.9723C39.051 19.9376 39.4748 19.4929 39.4053 18.9823C39.3949 18.8954 38.9885 17.6448 38.5021 16.1997C38.0192 14.7545 37.5954 13.4622 37.5676 13.3302C37.4738 12.9168 37.3731 11.2875 37.3696 10.1933V9.76249L37.5885 9.9605C38.2381 10.5476 39.0232 11.1416 39.7319 11.5794C40.3225 11.9441 40.51 11.9893 40.8019 11.8468C40.9617 11.7704 41.1388 11.5585 41.2153 11.357C41.2847 11.1694 41.2708 10.763 41.1909 10.5928C41.0763 10.3565 40.9929 10.2801 40.444 9.92577C39.2699 9.1615 38.4465 8.40766 37.4877 7.21611C37.057 6.6846 36.8729 6.53522 36.4977 6.41711C36.2684 6.34763 36.1538 6.33026 35.848 6.33373C35.6431 6.33373 35.3895 6.35805 35.2853 6.38584Z"
      fill="#6E77F0"
    />
    <path
      d="M49.213 1.43525C48.852 1.54697 48.5556 1.79619 48.3923 2.10986C48.3364 2.23018 48.0184 3.39893 47.6403 4.90713C47.013 7.4165 46.9915 7.50674 47.0173 7.829C47.1376 9.50908 48.4438 10.8712 50.038 10.9829C50.9747 11.0517 51.8727 10.6993 52.5559 9.99893L52.8438 9.69814L53.136 9.99463C54.4294 11.3267 56.4145 11.3267 57.7122 9.99463L58.0001 9.69814L58.2923 9.99463C59.5856 11.3267 61.5708 11.3267 62.8684 9.99463L63.1563 9.69814L63.4485 9.99893C64.1274 10.6993 65.0255 11.0517 65.9622 10.9829C67.5563 10.8712 68.8626 9.50908 68.9829 7.829C69.0087 7.50674 68.9872 7.4165 68.3598 4.90713C67.9817 3.39893 67.6638 2.23018 67.6079 2.10986C67.4833 1.86494 67.2126 1.61143 66.9419 1.48682L66.7442 1.39658L58.0645 1.38799C51.1724 1.38369 49.3505 1.39228 49.213 1.43525Z"
      fill="#6E77F0"
    />
    <path
      d="M52.5645 11.7392C52.2852 11.9153 51.7309 12.1388 51.3184 12.2462C50.4676 12.4653 49.3504 12.3708 48.534 12.0142L48.375 11.9454L48.3836 15.7567L48.3965 19.5724L48.5082 19.813C48.65 20.1181 48.882 20.3501 49.1871 20.4919L49.4277 20.6036L52.341 20.6165L55.25 20.6294V17.8751V15.1251H58H60.75V17.8751V20.6294L63.6633 20.6165L66.5723 20.6036L66.8129 20.4919C67.118 20.3501 67.35 20.1181 67.4918 19.813L67.6035 19.5724L67.6164 15.7567L67.625 11.9454L67.466 12.0142C66.8988 12.2634 66.0523 12.4052 65.4121 12.3579C64.7547 12.3149 63.9855 12.0743 63.4527 11.7478C63.3195 11.6704 63.1863 11.6017 63.1563 11.6017C63.1305 11.6017 62.9973 11.6661 62.8641 11.7435C61.5191 12.5298 59.6371 12.5298 58.2922 11.7435C58.159 11.6661 58.0258 11.6017 58 11.6017C57.9742 11.6017 57.841 11.6661 57.7078 11.7435C56.3629 12.5298 54.4809 12.5298 53.1359 11.7435C53.0027 11.6661 52.8695 11.6017 52.8352 11.6017C52.8051 11.6017 52.6848 11.6661 52.5645 11.7392Z"
      fill="#6E77F0"
    />
  </svg>
</template>

<script>
export default {
  name: "AllCustomersInStore"
};
</script>

<style lang="scss" scoped></style>
