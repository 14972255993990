<template>
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 8L0.803848 0.500001L11.1962 0.5L6 8Z" fill="#C4C4C4" />
  </svg>
</template>

<script>
export default {
  name: "GrayDownArrowClosedIcon"
};
</script>

<style lang="scss" scoped></style>
