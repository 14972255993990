import { render, staticRenderFns } from "./QuestionMarkIcon.vue?vue&type=template&id=6953bc0c&scoped=true&"
import script from "./QuestionMarkIcon.vue?vue&type=script&lang=js&"
export * from "./QuestionMarkIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6953bc0c",
  null
  
)

export default component.exports