import axios from "@/services/api";
import {
  BUSINESS_REQUEST,
  BUSINESS_GET_ALL,
  BUSINESS_GET_ALL_SUCCESS,
  BUSINESS_GET_BY_YEAR,
  BUSINESS_GET_BY_YEAR_SUCCESS,
  BUSINESS_REGISTER,
  BUSINESS_REGISTER_SUCCESS,
  BUSINESS_UPDATE,
  BUSINESS_UPDATE_SUCCESS
} from "@/store/actions/business";

function initialState() {
  return {
    buisnesses: [],
    businessByYear: {}
  };
}

const state = initialState();

const getters = {
  getBuisnesses: state => state.buisnesses,
  getBuisnessByYear: state => state.businessByYear
};

const actions = {
  [BUSINESS_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.id}/business-days`)
        .then(response => {
          commit("BUSINESS_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BUSINESS_GET_BY_YEAR]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(
          `/user/shops/${params.shop_id}/business-days?year=${params.year}&month=${params.month}`
        )
        .then(response => {
          commit("BUSINESS_GET_BY_YEAR_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BUSINESS_REGISTER]: ({ commit, dispatch }, { params, shop_id }) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/shops/${shop_id}/business-days`, params)
        .then(response => {
          commit("BUSINESS_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [BUSINESS_UPDATE]: (
    { commit, dispatch },
    { params, shop_id, business_id }
  ) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/shops/${shop_id}/business-days/${business_id}`, params)
        .then(response => {
          commit("BUSINESS_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [BUSINESS_REQUEST]: state => {
    state.status = "loading";
  },

  [BUSINESS_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.businesses = params.data.business_day;
  },

  [BUSINESS_GET_BY_YEAR_SUCCESS]: (state, params) => {
    state.status = "success";
    state.businessByYear = params.data.business_day;
  },

  [BUSINESS_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [BUSINESS_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
