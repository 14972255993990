<template>
  <svg
    style="width: 30px; height:20px;"
    width="27"
    height="21"
    viewBox="0 0 27 21"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3032 0.173594C10.5981 0.671709 9.39118 1.90742 8.95053 3.60292C8.31831 6.02644 9.88929 8.57449 12.4182 9.23545C15.7038 10.088 18.9033 6.88856 18.0507 3.60292C17.3802 1.03572 14.7459 -0.54484 12.3032 0.173594Z"
      fill=""
    />
    <path
      d="M9.04542 11.2965C8.06835 11.4402 6.82306 12.3119 6.37284 13.1836C6.29621 13.3464 5.03176 16.2106 3.57574 19.5441C0.510414 26.5752 0.529572 26.5178 1.17137 27.5906C1.74612 28.5581 2.82856 28.9509 3.911 28.5868C4.72523 28.3186 5.04134 27.8588 6.14294 25.3299C6.66979 24.1134 7.13917 23.0884 7.18707 23.0309C7.23496 22.9831 7.27328 27.945 7.27328 35.0049V47.0746L7.50318 47.5535C8.4132 49.5172 11.1816 49.4885 12.1012 47.5056C12.3023 47.0841 12.3023 46.9596 12.3311 38.8078L12.3598 30.5506H13.4997H14.6396L14.6684 38.8078C14.6971 46.9596 14.6971 47.0841 14.8983 47.5056C15.7891 49.4406 18.4713 49.5268 19.4675 47.6589L19.6783 47.2661L19.7262 35.0623L19.774 22.8585L20.7799 25.1767C21.9293 27.8397 22.255 28.309 23.0884 28.5868C24.1709 28.9509 25.2533 28.5581 25.8281 27.5906C26.4699 26.5178 26.489 26.5752 23.4141 19.5346C21.9581 16.1914 20.6649 13.2794 20.5404 13.0687C20.0518 12.2448 19.0652 11.5264 18.0977 11.3061C17.6092 11.1911 9.80217 11.1816 9.04542 11.2965Z"
      fill=""
    />
  </svg>
</template>

<script>
export default {
  name: "SingleCustomerIcon"
};
</script>

<style lang="scss" scoped></style>
