<template>
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.6618 2.75783V1.21815L1.7572 2.46407L0.822843 3.75097L0.698322 3.66051L2.73865 0.850914L4.77898 3.66051L4.65445 3.75097L3.72007 2.46406L2.81547 1.21817V2.75783V9.16273H2.6618V2.75783Z"
      fill="#7C7C7C"
      stroke="#7C7C7C"
    />
    <path
      d="M10.1732 7.33854V0.433594H9.01955V7.33859L7.79114 5.64687L6.85754 6.32512L9.59639 10.0963L12.3348 6.32474L11.4012 5.64725L10.1732 7.33854Z"
      fill="#7C7C7C"
    />
  </svg>
</template>

<script>
export default {
  name: "SwapVertical"
};
</script>

<style scoped></style>
