export const SHOP_USER_REQUEST = "SHOP_USER_REQUEST";

export const SHOP_USER_ALL = "SHOP_USER_ALL";
export const SHOP_USER_ALL_SUCCESS = "SHOP_USER_ALL_SUCCESS";

export const SHOP_USER_AUTH = "SHOP_USER_AUTH";
export const SHOP_USER_AUTH_SUCCESS = "SHOP_USER_AUTH_SUCCESS";

export const SHOP_USER_BY_USERID = "SHOP_USER_BY_USERID";
export const SHOP_USER_BY_USERID_SUCCESS = "SHOP_USER_BY_USERID_SUCCESS";

export const SHOP_USER_UPDATE = "SHOP_USER_UPDATE";
export const SHOP_USER_UPDATE_SUCCESS = "SHOP_USER_UPDATE_SUCCESS";

export const SHOP_USER_DELETE = "SHOP_USER_DELETE";
export const SHOP_USER_DELETE_SUCCESS = "SHOP_USER_DELETE_SUCCESS";

export const USER_SHOP_UPDATE_STATUS = "USER_SHOP_UPDATE_STATUS";
export const USER_SHOP_UPDATE_STATUS_SUCCESS = "USER_SHOP_UPDATE_STATUS_SUCCESS";