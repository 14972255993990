import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import notification from "@/store/modules/notification/notification";
import contact from "@/store/modules/contact";
import profile from "@/store/modules/profile";
import settings from "@/store/modules/settings";
import linkPosts from "@/store/modules/notification/link-post";
import faq from "@/store/modules/faq";
import shop from "@/store/modules/shop";
import master from "@/store/modules/master";
import supplier from "@/store/modules/supplier";
import mailinglist from "@/store/modules/mailinglist";
import fixedcost from "@/store/modules/fixed-cost";
import inventory from "@/store/modules/inventory";
import goal from "@/store/modules/goal";
import usershop from "@/store/modules/usershop";
import business from "@/store/modules/business";
import report from "@/store/modules/report";
import cashbook from "@/store/modules/cashbook";
import accounts from "@/store/modules/accounts";
import cashsales from "@/store/modules/cash-sales";
Vue.use(Vuex);

const getDefaultState = () => {
  return {
    modalVisible: false,
    modalComponent: null,
    // modalTitle: "Dialog Title",
    layoutVisible: true,
    apiProcessing: false,
    alert: false,
    alertText: "",
    alertSuccess: false,
    errors: {}
  };
};

export default new Vuex.Store({
  state: getDefaultState(),

  mutations: {
    showModal(state, { component }) {
      state.modalVisible = true;
      state.modalComponent = component;
      // title !== undefined
      //   ? (state.modalTitle = title)
      //   : (state.modalTitle = getDefaultState().modalTitle);
    },
    showAlert(state, { text, successStatus }) {
      state.alert = true;
      state.alertText = text;
      state.alertSuccess = successStatus;
    },
    hideAlert(state) {
      state.alert = false;
    },
    hideModal(state) {
      state.modalVisible = false;
      state.modalComponent = null;
    },
    setApiProcessing(state, payload) {
      state.apiProcessing = payload;
    },
    setFormErrors(state, payload) {
      state.errors = payload;
    },
    clearFormErrors(state) {
      state.errors = {};
    }
  },

  actions: {
    API_PROCESSING({ commit }, payload) {
      commit("setApiProcessing", payload);
    },
    SHOW_ALERT({ commit }, payload) {
      commit("showAlert", payload);
    }
  },

  getters: {
    getApiProcessingStatus: state => state.apiProcessing,
    getAlertStatus: state => state.alert,
    getAlertText: state => state.alertText,
    getAlertSuccess: state => state.alertSuccess,
    getErrors: state => state.errors,
    // getModalTitle: state => state.modalTitle,
    getModalComponent: state => state.modalComponent
  },

  modules: {
    auth,
    user,
    notification,
    contact,
    profile,
    settings,
    linkPosts,
    faq,
    shop,
    master,
    supplier,
    mailinglist,
    fixedcost,
    inventory,
    goal,
    usershop,
    business,
    report,
    cashbook,
    accounts,
    cashsales
  },

  plugins: [createPersistedState()]
});
