<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.19692 1.16792C-0.0656401 0.905422 -0.0656401 0.468543 0.19692 0.196871C0.468661 -0.0656236 0.896469 -0.0656236 1.16821 0.196871L6.99656 6.03354L12.8347 0.196871C13.0973 -0.0656236 13.5342 -0.0656236 13.7962 0.196871C14.0679 0.468543 14.0679 0.906034 13.7962 1.16792L7.96785 6.99541L13.7962 12.8321C14.0679 13.0946 14.0679 13.5315 13.7962 13.8031C13.5336 14.0656 13.0966 14.0656 12.8347 13.8031L6.99656 7.96646L1.16821 13.8031C0.896469 14.0656 0.468661 14.0656 0.19692 13.8031C-0.0656401 13.5315 -0.0656401 13.094 0.19692 12.8321L6.02527 6.99541L0.19692 1.16792Z"
      fill="#1E201D"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    height: {
      required: false,
      default: 14
    },
    width: {
      required: false,
      default: 14
    }
  }
};
</script>

<style scoped></style>
