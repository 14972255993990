import axios from "@/services/api"; 
import {
    SHOP_USER_REQUEST,
    SHOP_USER_AUTH,
    SHOP_USER_AUTH_SUCCESS,
    SHOP_USER_BY_USERID,
    SHOP_USER_BY_USERID_SUCCESS,
    SHOP_USER_UPDATE,
    SHOP_USER_UPDATE_SUCCESS,
    SHOP_USER_DELETE,
    SHOP_USER_DELETE_SUCCESS,
    USER_SHOP_UPDATE_STATUS,
    USER_SHOP_UPDATE_STATUS_SUCCESS
} from "@/store/actions/usershop";

function initialState() {
  return {
    shopAuthUser: [],
    shopUserById: {},
    userShopStatus:"",
  };
}

const state = initialState();

const getters = {
  getShopAuthUser: state => state.shopAuthUser,
  getShopUserById: state => state.shopUserById,
  userShopStatus: state => state.userShopStatus
};

const actions = {
  [SHOP_USER_AUTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/user-shops/${params.user_id}`)
        .then(response => {
          commit("SHOP_USER_AUTH_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [SHOP_USER_BY_USERID]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/user-shops/${params.user_id}`)
        .then(response => {
          commit("SHOP_USER_BY_USERID_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_USER_UPDATE]: ({ commit, dispatch }, {params,shop_id,user_id}) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/shops/${shop_id}/user-shops/${user_id}`, params)
        .then(response => {
          commit("SHOP_USER_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_USER_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/user/shops/${params.shop_id}/user-shops/${params.user_id}`)
        .then(response => {
          commit("SHOP_USER_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_SHOP_UPDATE_STATUS]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/user-shops/invitation/${params.userShopId}`, params)
        .then(response => {
          commit("USER_SHOP_UPDATE_STATUS_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [SHOP_USER_REQUEST]: state => {
    state.status = "loading";
  },

  [SHOP_USER_AUTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.shopAuthUser = params.data.user_shop;
    state.userShopStatus = params.data.user_shop[0].status;
  },

  [SHOP_USER_BY_USERID_SUCCESS]: (state, params) => {
    state.status = "success";
    state.shopUserById = params.data.user_shop;
  },
  [SHOP_USER_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  },
  [SHOP_USER_DELETE_SUCCESS]: (state, params) => {
    state.status = "success";
  },
  [USER_SHOP_UPDATE_STATUS_SUCCESS]: (state, params) => {
    state.status = "success";
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
