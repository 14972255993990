export const MAILINGLIST_REQUEST = "MAILINGLIST_REQUEST";

export const MAILINGLIST_GET_ALL = "MAILINGLIST_GET_ALL";
export const MAILINGLIST_GET_ALL_SUCCESS = "MAILINGLIST_GET_ALL_SUCCESS";

export const MAILINGLIST_REGISTER = "MAILINGLIST_REGISTER";
export const MAILINGLIST_REGISTER_SUCCESS = "MAILINGLIST_REGISTER_SUCCESS";

export const MAILINGLIST_GET = "MAILINGLIST_GET";
export const MAILINGLIST_GET_SUCCESS = "MAILINGLIST_GET_SUCCESS";

export const MAILINGLIST_UPDATE = "MAILINGLIST_UPDATE";
export const MAILINGLIST_UPDATE_SUCCESS = "MAILINGLIST_UPDATE_SUCCESS";

export const MAILINGLIST_DELETE = "MAILINGLIST_DELETE";
export const MAILINGLIST_DELETE_SUCCESS = "MAILINGLIST_DELETE_SUCCESS";