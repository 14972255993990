<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9986 4.49219C6.51264 4.49219 4.49023 6.515 4.49023 9.00096C4.49023 11.4869 6.51264 13.5097 8.9986 13.5097C11.4842 13.5097 13.507 11.4873 13.507 9.00096C13.507 6.5146 11.4842 4.49219 8.9986 4.49219Z"
      fill="white"
    />
    <path
      d="M9.00077 3.16634C8.51489 3.16634 8.12109 2.77254 8.12109 2.28707V0.879672C8.12109 0.3938 8.51489 0 9.00077 0C9.48664 0 9.88044 0.3938 9.88044 0.879672V2.28707C9.88044 2.77254 9.48624 3.16634 9.00077 3.16634Z"
      fill="white"
    />
    <path
      d="M9.00077 14.834C8.51489 14.834 8.12109 15.2278 8.12109 15.7137V17.1207C8.12109 17.6069 8.51489 18.0007 9.00077 18.0007C9.48664 18.0007 9.88044 17.6069 9.88044 17.1207V15.7137C9.88044 15.2278 9.48624 14.834 9.00077 14.834Z"
      fill="white"
    />
    <path
      d="M13.1246 4.87553C12.7814 4.53195 12.7814 3.97513 13.1246 3.63155L14.1198 2.63629C14.463 2.29311 15.0202 2.29311 15.3638 2.63629C15.7074 2.97987 15.7074 3.53709 15.3638 3.88027L14.3685 4.87553C14.0254 5.21911 13.4685 5.21911 13.1246 4.87553Z"
      fill="white"
    />
    <path
      d="M4.87568 13.1252C4.5321 12.7812 3.97528 12.7812 3.6317 13.1252L2.63644 14.12C2.29326 14.4632 2.29286 15.0208 2.63644 15.364C2.98002 15.7072 3.53724 15.7072 3.88042 15.364L4.87568 14.3683C5.21926 14.0252 5.21926 13.468 4.87568 13.1252Z"
      fill="white"
    />
    <path
      d="M14.834 8.99979C14.834 8.51392 15.2278 8.12012 15.7137 8.12012H17.1211C17.6069 8.12012 18.0007 8.51392 18.0007 8.99979C18.0007 9.48566 17.6069 9.87906 17.1211 9.87906H15.7137C15.2278 9.87906 14.834 9.48566 14.834 8.99979Z"
      fill="white"
    />
    <path
      d="M3.16634 8.99979C3.16634 8.51392 2.77254 8.12012 2.28667 8.12012H0.879672C0.3938 8.12012 0 8.51392 0 8.99979C0 9.48566 0.3938 9.87906 0.879672 9.87906H2.28707C2.77254 9.87906 3.16634 9.48566 3.16634 8.99979Z"
      fill="white"
    />
    <path
      d="M13.1249 13.1255C13.4684 12.7824 14.0257 12.7824 14.3688 13.1255L15.3641 14.1208C15.7077 14.4636 15.7077 15.0212 15.3641 15.3644C15.0205 15.7076 14.4637 15.7076 14.1201 15.3644L13.1249 14.3691C12.7813 14.0255 12.7813 13.4687 13.1249 13.1255Z"
      fill="white"
    />
    <path
      d="M4.87583 4.87543C5.21941 4.53185 5.21941 3.97503 4.87583 3.63145L3.88057 2.63659C3.53699 2.29301 2.98017 2.29301 2.63659 2.63659C2.29301 2.97977 2.29301 3.53699 2.63659 3.88017L3.63185 4.87543C3.97543 5.21941 4.53225 5.21941 4.87583 4.87543Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "WhiteSunIcon"
};
</script>

<style lang="scss" scoped></style>
