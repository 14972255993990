<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
      :fill="fillBg"
    />
    <path
      d="M6.32751 8.77127H7.39851C7.17801 7.23827 9.59301 6.97577 9.59301 5.41127C9.59301 4.11977 8.56401 3.38477 7.07301 3.38477C5.93901 3.38477 5.06751 3.87827 4.39551 4.63427L5.08851 5.27477C5.65551 4.67627 6.25401 4.38227 6.93651 4.38227C7.90251 4.38227 8.41701 4.83377 8.41701 5.51627C8.41701 6.62927 6.04401 7.05977 6.32751 8.77127ZM6.87351 11.4488C7.31451 11.4488 7.66101 11.1233 7.66101 10.6508C7.66101 10.1783 7.31451 9.85277 6.87351 9.85277C6.43251 9.85277 6.09651 10.1783 6.09651 10.6508C6.09651 11.1233 6.43251 11.4488 6.87351 11.4488Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "InfoIcon",
  props: {
    height: {
      required: false,
      default: 14
    },
    width: {
      required: false,
      default: 14
    },
    fillBg: {
      required: false,
      default: "#6B5C07"
    }
  }
};
</script>

<style scoped></style>
