import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "@/plugins/i18n";

import "@/styles/app.scss";

import "@/plugins/observe-visibility";
import "@/plugins/vee-validate";
import "@/services/api";
import "@/mixins/index";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
