<template>
  <v-navigation-drawer
    :value="shown"
    fixed
    width="100%"
    @input="updateShown"
    class="nav-menu"
    v-if="getShopAuthUser[0] && user"
  >
    <div :class="[pageLoading ? 'd-none' : '']">
      <v-toolbar elevation="0" class="mt-6 mb-8 mx-4 toolbar-align">
        <v-toolbar-title>
          <h6 class="app-title font-weight-bold text-body-1 px-1 mt-8 mb-2">
            redish Cross Point 日報管理
          </h6>
          <b class="mb-1 store-name" v-if="user.s_account_id">{{
            $store.getters.getFirstShopObject.store_display_name
              ? $store.getters.getFirstShopObject.store_display_name
              : $store.getters.getFirstShopObject.store_name
          }}</b>
          <ShopListDropdown
            class=""
            @shopChanged="pageLoading = !pageLoading"
            v-else
          ></ShopListDropdown>

          <p class="font-weight-bold px-3 mt-2 user-surname">
            {{ user.surname }}
            <span class="ml-n2" v-if="getShopAuthUser[0].user_role == 'S'"
              >共有アカウント</span
            >
            <span
              v-if="
                getShopAuthUser[0].user_role == 'M' ||
                  getShopAuthUser[0].user_role == 'P'
              "
              >さん</span
            >
          </p>
        </v-toolbar-title>

        <v-btn icon @click="_shown = false">
          <v-icon color="#971616">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list class="mx-8 mt-12">
        <v-divider></v-divider>
        <template v-for="(item, index) in menu">
          <v-list-item
            :key="index"
            v-if="!item.submenu && item.newTab && item.hideMenu != 'hide'"
            :to="item.newTab === undefined ? item.to : ''"
            :href="item.newTab !== undefined ? item.to : ''"
            :target="item.newTab ? '_blank' : ''"
            class="font-weight-medium main--text text-heading-4"
            @click="_shown = false"
          >
            <v-list-item-title
              class="font-weight-medium main--text text-heading-4"
            >
              <span class="mr-2">{{ item.text }}</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else-if="!item.submenu && !item.newTab"
            v-bind:to="
              item.submenu === false && item.type !== 'Logout' ? item.to : ''
            "
            :key="index"
            @click="handler(item)"
          >
            <v-list-item-title
              class="font-weight-medium main--text text-heading-4"
            >
              <span class="mr-2">{{ item.text }}</span>

              <v-chip
                class="notification-badge"
                color="red"
                text-color="white"
                x-small
                v-if="item.hasBadge && getNotificationUnreadCount > 0"
              >
                {{ getNotificationUnreadCount }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-title
              v-if="item.newTab"
              :to="item.newTab === undefined ? item.to : ''"
              :href="item.newTab !== undefined ? item.to : ''"
              :target="item.newTab ? '_blank' : ''"
              class="font-weight-medium main--text text-heading-4"
              @click="_shown = false"
            >
              <span
                class="mr-2"
                :to="item.newTab === undefined ? item.to : ''"
                :href="item.newTab !== undefined ? item.to : ''"
                :target="item.newTab ? '_blank' : ''"
                @click="_shown = false"
                >{{ item.text }}</span
              >
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else-if="!item.hideMenu"
            :value="item.prominent"
            :key="index"
            append-icon=""
          >
            <template v-slot:appendIcon>
              <v-icon size="15">$grayDownArrow</v-icon>
            </template>

            <template v-slot:activator>
              <v-list-item-title
                class="font-weight-medium main--text text-heading-4"
              >
                {{ item.text }}
              </v-list-item-title>
            </template>

            <template v-for="(subItem, subIndex) in item.children">
              <v-divider
                v-if="subItem.hideMenu != 'hide'"
                :key="'subItemDivider-' + subIndex"
              ></v-divider>
              <v-list-item
                class="pl-8"
                :key="subIndex"
                v-bind:to="subItem.newTab === undefined ? subItem.to : ''"
                :href="subItem.newTab !== undefined ? subItem.to : ''"
                :target="subItem.newTab ? '_blank' : ''"
                @click="_shown = false"
                v-if="subItem.hideMenu != 'hide'"
                exact
              >
                {{ subItem.show }}
                <v-list-item-title class="text-body-2">
                  <v-icon size="10" class="mr-2">$rightArrow</v-icon>
                  {{ subItem.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
          <v-divider
            v-if="item.hideMenu != 'hide'"
            :key="'divider-' + index"
          ></v-divider>
        </template>
      </v-list>
      <v-flex class="text-center mt-4 text--disabled text-caption">
        <v-col class="d-flex justify-space-around">
          <router-link
            class="text--disabled"
            :to="{ name: 'privacy_policy' }"
            target="_blank"
            rel="noopener noreferrer"
            >プライバシーポリシー
          </router-link>
          <router-link
            class="text--disabled"
            :to="{ name: 'terms_and_conditions' }"
            target="_blank"
            rel="noopener noreferrer"
            >利用規約
          </router-link>
        </v-col>
        <v-col cols="12">
          <v-btn
            @click="_shown = false"
            text
            class="text--disabled font-weight-bold"
            >{{ $t("close") }}</v-btn
          >
        </v-col>
        <v-col class="pb-0" cols="12">
          <v-btn
            outlined
            disabled
            depressed
            x-small
            class="text-lowercase font-italic version-btn"
          >
            {{ version }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <small>© リディッシュ株式会社 {{ copyrightYear }}</small>
        </v-col>
      </v-flex>
    </div>
    <div
      v-if="pageLoading"
      class="d-flex flex-column items-center justify-space-between main-desc-loader-height-98"
    >
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader
            class="mx-4 mt-4"
            type="heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="mx-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="mx-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="mx-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="mx-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="ml-8 mr-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="ml-8 mr-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="ml-8 mr-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="text" class="ml-8 mr-4"></v-skeleton-loader>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-around">
        <v-skeleton-loader type="button"></v-skeleton-loader>
        <v-skeleton-loader type="button"></v-skeleton-loader>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import ShopListDropdown from "./ShopListDropdown";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "ApplicationNavMenu",
  components: {
    ShopListDropdown
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // The User ID is necessary for push notification request from index.html
    this.user && this.user.id
      ? sessionStorage.setItem("user_id", this.user.id)
      : sessionStorage.removeItem("user_id");
    if (this.user) {
      if (
        Notification.permission == "denied" ||
        Notification.permission == "default" ||
        this.isIOSDevice()
      ) {
        this.updateNotificationPreference(0); // Email will be sent
      } else {
        this.updateNotificationPreference(1); // Notification will be sent
      }
    }
  },
  created() {
    if (this.user) {
      this.$store.dispatch("MASTER_GET_ALL");
      this.$store.dispatch("NOTIFICATION_UNREAD_COUNT_GET");
    }

    this.getAuthShopUserDataApi();
  },
  computed: {
    ...mapGetters(["user", "getShopAuthUser", "getNotificationUnreadCount"]),
    _shown: {
      get: function() {
        return this.shown;
      },
      set: function(newValue) {
        this.$emit("update:shown", newValue);
      }
    },
    copyrightYear() {
      return `${dayjs().format("YYYY")}`;
    },
    lastName() {
      return this.user?.last_name;
    },
    menu() {
      if (!this.getShopAuthUser[0]) {
        return;
      }
      return [
        {
          text: "トップ",
          to: { name: "Top" },
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: true,
          children: []
        },
        {
          text: "メニュー",
          // to: "/",
          hasTos: false,
          hasBadge: false,
          submenu: true,
          prominent: false,
          children: [
            {
              text: "日報",
              to: { name: "drList" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "月報",
              to: {
                name: "BusinessReportBudget"
              },
              hideMenu:
                this.getShopAuthUser[0].user_role == "M" ? "show" : "hide"
            },
            {
              text: "現金出納",
              to: { name: "cashbookList" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "M" ? "show" : "hide"
            },
            {
              text: "現金出納",
              to: { name: "cashbookCreate" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "P" ? "show" : "hide"
            },
            {
              text: "買掛",
              to: { name: "accountsList" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            //S account menu
            {
              text: "日報の作成",
              to: {
                name: "drInput",
                params: { date: this.currentDate.format("YYYY-MM-DD") }
              },
              hideMenu:
                this.getShopAuthUser[0].user_role == "S" ? "show" : "hide"
            },
            {
              text: "現金出納/小口の作成",
              to: { name: "cashbookCreate" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "S" ? "show" : "hide"
            },
            {
              text: "買掛/納品伝票の作成",
              to: { name: "accountsPayableCreate" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "S" ? "show" : "hide"
            }
          ]
        },
        {
          text: "お知らせ",
          to: { name: "notifications_list" },
          hasTos: false,
          hasBadge: true,
          submenu: false,
          prominent: true,
          children: []
        },
        {
          text: "リンクコラム",
          to: { name: "link_posts" },
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: true,
          children: []
        },
        {
          text: "設定・その他",
          hasTos: false,
          hasBadge: false,
          submenu: true,
          prominent: true,
          children: [
            {
              text: "売上管理店舗一覧",
              to: { name: "managedStores" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "店舗情報",
              to: {
                name: "StoreDetails",
                params: { id: this.$store.getters.getFirstShopObject.id }
              },
              hideMenu:
                this.getShopAuthUser[0].user_role == "S" ? "show" : "hide"
            },
            {
              text:
                this.getShopAuthUser[0].user_role == "M"
                  ? "メーリングリスト管理"
                  : "メーリングリスト",
              to: { name: "mailingList" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "M" ? "show" : "hide"
            },
            {
              text: "目標売上の設定",
              to: { name: "GoalSetting" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "M" ? "show" : "hide"
            },
            {
              text: "固定費設定",
              to: { name: "fixedCostList" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "M" ? "show" : "hide"
            },
            {
              text: "営業日設定",
              to: { name: "BusinessDaySetting" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "M" ? "show" : "hide"
            },
            {
              text: "取引先 / 支払先設定",
              to: { name: "SupplierListing" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "現金売上一覧",
              to: { name: "CashSalesList" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "棚卸管理",
              to: { name: "InventoryManagementList" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "プロフィール設定",
              to: { name: "edit_profile" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "P" ||
                this.getShopAuthUser[0].user_role == "M"
                  ? "show"
                  : "hide"
            },
            {
              text: "メール通知設定",
              to: { name: "settings" },
              hideMenu:
                this.getShopAuthUser[0].user_role == "P" ||
                this.getShopAuthUser[0].user_role == "M"
                  ? "show"
                  : "hide"
            },
            {
              text: "よくある質問",
              to: { name: "faq" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "お問い合わせ",
              to: { name: "contact_form" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "ラインで相談",
              to: "https://lin.ee/qmYLS7j",
              newTab: true,
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            },
            {
              text: "パスワードリセット",
              to: { name: "change_password" },
              hideMenu:
                this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
            }
          ]
        },
        // {
        //   text: "Withdraw",
        //   to: { name: "withdraw" },
        //   hasTos: false,
        //   hasBadge: false,
        //   submenu: false,
        //   prominent: false
        // },
        {
          text: "Cross Point飲食経営情報アプリ",
          to: "https://www.crosspoint-app.com/top",
          newTab: true,
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: false,
          hideMenu: this.getShopAuthUser[0].user_role != "S" ? "show" : "hide"
        },
        {
          text: "ログアウト",
          type: "Logout",
          to: "/logout",
          hasTos: false,
          hasBadge: false,
          submenu: false,
          prominent: false
        }
      ];
    }
  },
  data() {
    return {
      pageLoading: false,
      options: 4,
      subOptions: 5,
      version: "β ver. 1.0",
      notificationCount: 3,
      currentDate: dayjs()
    };
  },
  methods: {
    menuChildren() {
      return [
        {
          text: this.$t("sidebar_menu_sales_target"),
          to: "/goals"
        },
        {
          text: this.$t("sidebar_menu_fl_ratio_report"),
          to: "/fl-ratio"
        },
        {
          text: this.$t("sidebar_menu_deposit_balance"),
          to: "/balance"
        },
        {
          text: this.$t("sidebar_menu_sales_and_costs_monthly"),
          to: "/sales-costs"
        },
        {
          text: this.$t("sidebar_menu_sales_and_costs_daily"),
          to: "/sales-costs/daily"
        },
        {
          text: this.$t("sidebar_menu_sales_and_costs_food_cost"),
          to: "/sales-costs/daily/food"
        }
      ];
    },
    updateNotificationPreference(isAllowed) {
      if (this.user) {
        this.$store.dispatch("SETTINGS_PREFERENCES_UPDATE", {
          preferences: [{ name: "notification.browser", value: isAllowed }],
          hide_success_message: true
        });
      }
    },
    isIOSDevice() {
      return (
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      );
    },
    updateShown(event) {
      this.$emit("update:shown", event);
    },
    handler(item) {
      if (item.to === "/logout") {
        this.logout();
      }

      this._shown = false;
    },
    logout() {
      this.$router.push({ name: "AuthPage" });
      this.$store.dispatch("AUTH_LOGOUT");
    }
  }
};
</script>

<style lang="scss" scoped>
.user-surname {
  font-size: 0.8rem;
}
.store-name {
  padding-left: 2px !important;
  padding-top: -2px !important;
}
.app-title {
  color: #949494;
}
.v-navigation-drawer {
  will-change: initial;
}
.footer-item-text {
  color: #b4b4b4 !important;
  font-size: 10px !important;
}
.nav-menu {
  z-index: 99 !important;
}

.notification-badge {
  padding: 0 5px !important;
}
.dropdown-main-div {
  ::v-deep .connect-select {
    margin: 4px 0 !important;
  }
  ::v-deep .connect-select {
    .v-input__control {
      min-height: 20px !important;
      height: 30px !important;
      font-size: 15px !important;
      font-weight: bold !important;
      max-width: 276px !important;
    }
    .v-input__control {
      .v-input__slot {
        background-color: #f0f3f8 !important;
      }
    }
  }
  ::v-deep
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control {
    > .v-input__slot {
      box-shadow: none !important;
    }
  }
}
a {
  text-decoration: none;
}
.version-btn {
  background-color: #ededed !important;
}
.main-desc-loader-height-98 {
  height: 98%;
}
.main-desc-loader-height-90 {
  height: 90%;
}
.toolbar-align {
  ::v-deep .v-toolbar__content {
    justify-content: space-between;
  }
}
.v-toolbar__extension {
  justify-content: space-between;
}
</style>
