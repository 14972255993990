<template>
  <svg
    style="width:28px; height:29.26px"
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.84947 0.103273C5.83169 0.400604 5.11123 1.13821 4.84821 2.15028C4.47083 3.59691 5.40856 5.11787 6.91809 5.51241C8.87933 6.0213 10.7891 4.11152 10.2802 2.15028C9.87996 0.617884 8.30754 -0.325569 6.84947 0.103273Z"
      fill=""
    />
    <path
      d="M4.90558 6.74339C4.32236 6.82916 3.57903 7.34949 3.31029 7.86982C3.26455 7.96702 2.50978 9.67668 1.64066 11.6665C-0.189063 15.8634 -0.177627 15.8291 0.205472 16.4695C0.548546 17.047 1.19467 17.2815 1.84079 17.0642C2.32681 16.9041 2.5155 16.6296 3.17306 15.1201C3.48755 14.3939 3.76772 13.7821 3.79631 13.7478C3.8249 13.7192 3.84777 16.6811 3.84777 20.8952V28.0997L3.985 28.3856C4.5282 29.5578 6.18067 29.5406 6.72959 28.357C6.84967 28.1055 6.84967 28.0311 6.86682 23.1652L6.88398 18.2364H7.56441H8.24483L8.26199 23.1652C8.27914 28.0311 8.27914 28.1055 8.39922 28.357C8.93098 29.5121 10.532 29.5635 11.1267 28.4485L11.2524 28.2141L11.281 20.9295L11.3096 13.6449L11.91 15.0286C12.5962 16.6182 12.7906 16.8984 13.288 17.0642C13.9341 17.2815 14.5803 17.047 14.9233 16.4695C15.3064 15.8291 15.3179 15.8634 13.4824 11.6608C12.6133 9.66524 11.8414 7.927 11.7671 7.8012C11.4754 7.30947 10.8865 6.88062 10.309 6.74911C10.0174 6.6805 5.3573 6.67478 4.90558 6.74339Z"
      fill=""
    />
    <path
      d="M19.7215 0.103273C18.7038 0.400604 17.9833 1.13821 17.7203 2.15028C17.3429 3.59691 18.2806 5.11787 19.7902 5.51241C21.7514 6.0213 23.6612 4.11152 23.1523 2.15028C22.752 0.617884 21.1796 -0.325569 19.7215 0.103273Z"
      fill=""
    />
    <path
      d="M17.7777 6.74339C17.1944 6.82916 16.4511 7.34949 16.1824 7.86982C16.1366 7.96702 15.3819 9.67668 14.5127 11.6665C12.683 15.8634 12.6944 15.8291 13.0775 16.4695C13.4206 17.047 14.0667 17.2815 14.7129 17.0642C15.1989 16.9041 15.3876 16.6296 16.0451 15.1201C16.3596 14.3939 16.6398 13.7821 16.6684 13.7478C16.697 13.7192 16.7198 16.6811 16.7198 20.8952V28.0997L16.8571 28.3856C17.4003 29.5578 19.0527 29.5406 19.6017 28.357C19.7217 28.1055 19.7217 28.0311 19.7389 23.1652L19.756 18.2364H20.4365H21.1169L21.1341 23.1652C21.1512 28.0311 21.1512 28.1055 21.2713 28.357C21.8031 29.5121 23.4041 29.5635 23.9987 28.4485L24.1245 28.2141L24.1531 20.9295L24.1817 13.6449L24.7821 15.0286C25.4682 16.6182 25.6626 16.8984 26.1601 17.0642C26.8062 17.2815 27.4523 17.047 27.7954 16.4695C28.1785 15.8291 28.1899 15.8634 26.3545 11.6608C25.4854 9.66524 24.7135 7.927 24.6391 7.8012C24.3475 7.30947 23.7586 6.88062 23.1811 6.74911C22.8895 6.6805 18.2294 6.67478 17.7777 6.74339Z"
      fill=""
    />
  </svg>
</template>

<script>
export default {
  name: "PairCustomersIcon"
};
</script>

<style lang="scss" scoped></style>
