import axios from "@/services/api";
import {
  FAQ_GET_ALL,
  FAQ_GET_ALL_SUCCESS,
  FAQ_GET_ALL_ERROR,
  FAQ_REQUEST
} from "@/store/actions/faq";

function initialState() {
  return {
    faqs: {},
    errors: {}
  };
}

const state = initialState();

const getters = {
  faqs: state => state.faqs
};

const actions = {
  [FAQ_GET_ALL]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("FAQ_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get("/user/faqs")
        .then(response => {
          commit("FAQ_GET_ALL_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("FAQ_GET_ALL_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [FAQ_REQUEST]: state => {
    state.status = "loading";
  },
  [FAQ_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.faqs = params.faq_categories;
    state.pagination = params.pagination;
  },
  [FAQ_GET_ALL_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
