export const GOAL_REQUEST = "GOAL_REQUEST";

export const GOAL_REGISTER = "GOAL_REGISTER";
export const GOAL_REGISTER_SUCCESS = "GOAL_REGISTER_SUCCESS";

export const GOAL_GET_BY_YEAR = "GOAL_GET_BY_YEAR";
export const GOAL_GET_BY_YEAR_SUCCESS = "GOAL_GET_BY_YEAR_SUCCESS";

export const GOAL_DELETE = "GOAL_DELETE";
export const GOAL_DELETE_SUCCESS = "GOAL_DELETE_SUCCESS";
