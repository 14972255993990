<template>
  <svg
    style="width:73px;height:26px"
    width="70px"
    height="50px"
    viewBox="0 0 70 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.8562 0.564718C3.54535 0.648092 3.31221 0.790522 3.07907 1.03022C2.82565 1.29424 2.6905 1.54784 2.61954 1.90565C2.41343 2.92698 3.21084 3.91357 4.24814 3.91357C5.15705 3.91357 5.89702 3.14584 5.89364 2.21135C5.89026 1.74585 5.7247 1.34635 5.39695 1.01285C4.98135 0.589035 4.39006 0.418813 3.8562 0.564718Z"
      fill="#6E77F0"
    />
    <path
      d="M3.44991 4.38535C3.04445 4.49305 2.86875 4.62158 2.30111 5.21215C1.49019 6.06326 0.92592 6.74414 0.392064 7.50841C0.0643162 7.98086 -0.00326061 8.13024 0.000118233 8.39773C0.000118233 8.65827 0.0845892 8.85976 0.267047 9.02651C0.452883 9.2002 0.611688 9.26274 0.851586 9.26274C1.2165 9.26274 1.40909 9.12378 1.78077 8.58532C1.88551 8.43247 2.095 8.14761 2.24705 7.95307C2.4937 7.62999 2.52073 7.60568 2.52749 7.69252C2.53425 7.74463 2.55114 8.52626 2.57142 9.42948C2.59507 10.6176 2.59169 11.1248 2.56466 11.2533C2.54438 11.354 2.43964 11.6181 2.33152 11.8404C1.67602 13.1848 1.13541 14.6091 0.594794 16.4016C0.331245 17.2736 0.307593 17.4612 0.442746 17.7495C0.584658 18.0587 0.946193 18.2949 1.27394 18.2949C1.44626 18.2949 1.71657 18.1907 1.85848 18.0622C2.03418 17.9093 2.09162 17.7773 2.34841 16.9227C2.81807 15.3664 3.2134 14.293 3.75739 13.1257C3.95674 12.6915 3.99729 12.629 4.06148 12.6394C4.12568 12.6498 4.22367 12.9242 4.93322 15.0885C5.81172 17.7634 5.78807 17.7113 6.12258 17.8885C6.28476 17.9753 6.33206 17.9857 6.54493 17.9718C7.11258 17.9371 7.52479 17.4924 7.45722 16.9818C7.44708 16.8949 7.05176 15.6443 6.57872 14.1992C6.10906 12.754 5.69684 11.4617 5.66981 11.3297C5.57858 10.9163 5.4806 9.28705 5.47722 8.19277V7.762L5.69008 7.96002C6.32193 8.54711 7.08555 9.14115 7.77483 9.57886C8.34923 9.94362 8.53169 9.98878 8.81551 9.84635C8.97094 9.76993 9.14326 9.55802 9.21759 9.35653C9.28517 9.16894 9.27165 8.76249 9.19394 8.59227C9.08244 8.35604 9.00135 8.27962 8.46749 7.92528C7.32544 7.16101 6.52466 6.40717 5.5921 5.21562C5.17312 4.68411 4.99404 4.53473 4.62913 4.41662C4.40613 4.34714 4.29462 4.32977 3.99729 4.33325C3.79793 4.33325 3.55128 4.35756 3.44991 4.38535Z"
      fill="#6E77F0"
    />
    <path
      d="M19.2937 0.564718C18.9828 0.648092 18.7497 0.790522 18.5166 1.03022C18.2632 1.29424 18.128 1.54784 18.057 1.90565C17.8509 2.92698 18.6483 3.91357 19.6856 3.91357C20.5946 3.91357 21.3345 3.14584 21.3311 2.21135C21.3278 1.74585 21.1622 1.34635 20.8344 1.01285C20.4189 0.589035 19.8276 0.418813 19.2937 0.564718Z"
      fill="#6E77F0"
    />
    <path
      d="M18.8874 4.38535C18.482 4.49305 18.3063 4.62158 17.7386 5.21215C16.9277 6.06326 16.3634 6.74414 15.8296 7.50841C15.5018 7.98086 15.4342 8.13024 15.4376 8.39773C15.4376 8.65827 15.5221 8.85976 15.7045 9.02651C15.8904 9.2002 16.0492 9.26274 16.2891 9.26274C16.654 9.26274 16.8466 9.12378 17.2183 8.58532C17.323 8.43247 17.5325 8.14761 17.6845 7.95307C17.9312 7.62999 17.9582 7.60568 17.965 7.69252C17.9717 7.74463 17.9886 8.52626 18.0089 9.42948C18.0326 10.6176 18.0292 11.1248 18.0022 11.2533C17.9819 11.354 17.8771 11.6181 17.769 11.8404C17.1135 13.1848 16.5729 14.6091 16.0323 16.4016C15.7687 17.2736 15.7451 17.4612 15.8802 17.7495C16.0222 18.0587 16.3837 18.2949 16.7114 18.2949C16.8838 18.2949 17.1541 18.1907 17.296 18.0622C17.4717 17.9093 17.5291 17.7773 17.7859 16.9227C18.2556 15.3664 18.6509 14.293 19.1949 13.1257C19.3942 12.6915 19.4348 12.629 19.499 12.6394C19.5632 12.6498 19.6612 12.9242 20.3707 15.0885C21.2492 17.7634 21.2256 17.7113 21.5601 17.8885C21.7223 17.9753 21.7696 17.9857 21.9824 17.9718C22.5501 17.9371 22.9623 17.4924 22.8947 16.9818C22.8846 16.8949 22.4893 15.6443 22.0162 14.1992C21.5466 12.754 21.1343 11.4617 21.1073 11.3297C21.0161 10.9163 20.9181 9.28705 20.9147 8.19277V7.762L21.1276 7.96002C21.7594 8.54711 22.523 9.14115 23.2123 9.57886C23.7867 9.94362 23.9692 9.98878 24.253 9.84635C24.4084 9.76993 24.5808 9.55802 24.6551 9.35653C24.7227 9.16894 24.7092 8.76249 24.6314 8.59227C24.5199 8.35604 24.4388 8.27962 23.905 7.92528C22.7629 7.16101 21.9622 6.40717 21.0296 5.21562C20.6106 4.68411 20.4315 4.53473 20.0666 4.41662C19.8436 4.34714 19.7321 4.32977 19.4348 4.33325C19.2354 4.33325 18.9888 4.35756 18.8874 4.38535Z"
      fill="#6E77F0"
    />
    <path
      d="M34.7253 0.564718C34.4145 0.648092 34.1813 0.790522 33.9482 1.03022C33.6948 1.29424 33.5596 1.54784 33.4887 1.90565C33.2826 2.92698 34.08 3.91357 35.1173 3.91357C36.0262 3.91357 36.7662 3.14584 36.7628 2.21135C36.7594 1.74585 36.5938 1.34635 36.2661 1.01285C35.8505 0.589035 35.2592 0.418813 34.7253 0.564718Z"
      fill="#6E77F0"
    />
    <path
      d="M34.3191 4.38535C33.9136 4.49305 33.7379 4.62158 33.1702 5.21215C32.3593 6.06326 31.7951 6.74414 31.2612 7.50841C30.9335 7.98086 30.8659 8.13024 30.8693 8.39773C30.8693 8.65827 30.9537 8.85976 31.1362 9.02651C31.322 9.2002 31.4808 9.26274 31.7207 9.26274C32.0856 9.26274 32.2782 9.12378 32.6499 8.58532C32.7547 8.43247 32.9641 8.14761 33.1162 7.95307C33.3628 7.62999 33.3899 7.60568 33.3966 7.69252C33.4034 7.74463 33.4203 8.52626 33.4406 9.42948C33.4642 10.6176 33.4608 11.1248 33.4338 11.2533C33.4135 11.354 33.3088 11.6181 33.2007 11.8404C32.5452 13.1848 32.0045 14.6091 31.4639 16.4016C31.2004 17.2736 31.1767 17.4612 31.3119 17.7495C31.4538 18.0587 31.8153 18.2949 32.1431 18.2949C32.3154 18.2949 32.5857 18.1907 32.7276 18.0622C32.9033 17.9093 32.9608 17.7773 33.2176 16.9227C33.6872 15.3664 34.0825 14.293 34.6265 13.1257C34.8259 12.6915 34.8664 12.629 34.9306 12.6394C34.9948 12.6498 35.0928 12.9242 35.8024 15.0885C36.6809 17.7634 36.6572 17.7113 36.9917 17.8885C37.1539 17.9753 37.2012 17.9857 37.4141 17.9718C37.9817 17.9371 38.3939 17.4924 38.3264 16.9818C38.3162 16.8949 37.9209 15.6443 37.4479 14.1992C36.9782 12.754 36.566 11.4617 36.539 11.3297C36.4477 10.9163 36.3497 9.28705 36.3464 8.19277V7.762L36.5592 7.96002C37.1911 8.54711 37.9547 9.14115 38.644 9.57886C39.2184 9.94362 39.4008 9.98878 39.6847 9.84635C39.8401 9.76993 40.0124 9.55802 40.0867 9.35653C40.1543 9.16894 40.1408 8.76249 40.0631 8.59227C39.9516 8.35604 39.8705 8.27962 39.3366 7.92528C38.1946 7.16101 37.3938 6.40717 36.4612 5.21562C36.0423 4.68411 35.8632 4.53473 35.4983 4.41662C35.2753 4.34714 35.1638 4.32977 34.8664 4.33325C34.6671 4.33325 34.4204 4.35756 34.3191 4.38535Z"
      fill="#6E77F0"
    />
    <path
      d="M50.1619 0.564718C49.851 0.648092 49.6179 0.790522 49.3847 1.03022C49.1313 1.29424 48.9962 1.54784 48.9252 1.90565C48.7191 2.92698 49.5165 3.91357 50.5538 3.91357C51.4627 3.91357 52.2027 3.14584 52.1993 2.21135C52.1959 1.74585 52.0304 1.34635 51.7026 1.01285C51.287 0.589035 50.6957 0.418813 50.1619 0.564718Z"
      fill="#6E77F0"
    />
    <path
      d="M49.7556 4.38535C49.3501 4.49305 49.1744 4.62158 48.6068 5.21215C47.7959 6.06326 47.2316 6.74414 46.6977 7.50841C46.37 7.98086 46.3024 8.13024 46.3058 8.39773C46.3058 8.65827 46.3903 8.85976 46.5727 9.02651C46.7585 9.2002 46.9174 9.26274 47.1572 9.26274C47.5222 9.26274 47.7148 9.12378 48.0864 8.58532C48.1912 8.43247 48.4007 8.14761 48.5527 7.95307C48.7994 7.62999 48.8264 7.60568 48.8332 7.69252C48.8399 7.74463 48.8568 8.52626 48.8771 9.42948C48.9007 10.6176 48.8974 11.1248 48.8703 11.2533C48.85 11.354 48.7453 11.6181 48.6372 11.8404C47.9817 13.1848 47.4411 14.6091 46.9005 16.4016C46.6369 17.2736 46.6133 17.4612 46.7484 17.7495C46.8903 18.0587 47.2519 18.2949 47.5796 18.2949C47.7519 18.2949 48.0222 18.1907 48.1641 18.0622C48.3398 17.9093 48.3973 17.7773 48.6541 16.9227C49.1237 15.3664 49.5191 14.293 50.0631 13.1257C50.2624 12.6915 50.303 12.629 50.3671 12.6394C50.4313 12.6498 50.5293 12.9242 51.2389 15.0885C52.1174 17.7634 52.0937 17.7113 52.4282 17.8885C52.5904 17.9753 52.6377 17.9857 52.8506 17.9718C53.4182 17.9371 53.8305 17.4924 53.7629 16.9818C53.7527 16.8949 53.3574 15.6443 52.8844 14.1992C52.4147 12.754 52.0025 11.4617 51.9755 11.3297C51.8842 10.9163 51.7863 9.28705 51.7829 8.19277V7.762L51.9957 7.96002C52.6276 8.54711 53.3912 9.14115 54.0805 9.57886C54.6549 9.94362 54.8374 9.98878 55.1212 9.84635C55.2766 9.76993 55.4489 9.55802 55.5233 9.35653C55.5908 9.16894 55.5773 8.76249 55.4996 8.59227C55.3881 8.35604 55.307 8.27962 54.7732 7.92528C53.6311 7.16101 52.8303 6.40717 51.8978 5.21562C51.4788 4.68411 51.2997 4.53473 50.9348 4.41662C50.7118 4.34714 50.6003 4.32977 50.303 4.33325C50.1036 4.33325 49.8569 4.35756 49.7556 4.38535Z"
      fill="#6E77F0"
    />
    <path
      d="M65.5955 0.564718C65.2846 0.648092 65.0515 0.790522 64.8183 1.03022C64.5649 1.29424 64.4298 1.54784 64.3588 1.90565C64.1527 2.92698 64.9501 3.91357 65.9874 3.91357C66.8963 3.91357 67.6363 3.14584 67.6329 2.21135C67.6295 1.74585 67.464 1.34635 67.1362 1.01285C66.7206 0.589035 66.1293 0.418813 65.5955 0.564718Z"
      fill="#6E77F0"
    />
    <path
      d="M65.1892 4.38535C64.7837 4.49305 64.608 4.62158 64.0404 5.21215C63.2294 6.06326 62.6652 6.74414 62.1313 7.50841C61.8036 7.98086 61.736 8.13024 61.7394 8.39773C61.7394 8.65827 61.8238 8.85976 62.0063 9.02651C62.1921 9.2002 62.3509 9.26274 62.5908 9.26274C62.9558 9.26274 63.1484 9.12378 63.52 8.58532C63.6248 8.43247 63.8343 8.14761 63.9863 7.95307C64.233 7.62999 64.26 7.60568 64.2667 7.69252C64.2735 7.74463 64.2904 8.52626 64.3107 9.42948C64.3343 10.6176 64.3309 11.1248 64.3039 11.2533C64.2836 11.354 64.1789 11.6181 64.0708 11.8404C63.4153 13.1848 62.8747 14.6091 62.3341 16.4016C62.0705 17.2736 62.0469 17.4612 62.182 17.7495C62.3239 18.0587 62.6855 18.2949 63.0132 18.2949C63.1855 18.2949 63.4558 18.1907 63.5977 18.0622C63.7734 17.9093 63.8309 17.7773 64.0877 16.9227C64.5573 15.3664 64.9527 14.293 65.4966 13.1257C65.696 12.6915 65.7365 12.629 65.8007 12.6394C65.8649 12.6498 65.9629 12.9242 66.6725 15.0885C67.551 17.7634 67.5273 17.7113 67.8618 17.8885C68.024 17.9753 68.0713 17.9857 68.2842 17.9718C68.8518 17.9371 69.2641 17.4924 69.1965 16.9818C69.1863 16.8949 68.791 15.6443 68.318 14.1992C67.8483 12.754 67.4361 11.4617 67.4091 11.3297C67.3178 10.9163 67.2199 9.28705 67.2165 8.19277V7.762L67.4293 7.96002C68.0612 8.54711 68.8248 9.14115 69.5141 9.57886C70.0885 9.94362 70.2709 9.98878 70.5548 9.84635C70.7102 9.76993 70.8825 9.55802 70.9569 9.35653C71.0244 9.16894 71.0109 8.76249 70.9332 8.59227C70.8217 8.35604 70.7406 8.27962 70.2067 7.92528C69.0647 7.16101 68.2639 6.40717 67.3314 5.21562C66.9124 4.68411 66.7333 4.53473 66.3684 4.41662C66.1454 4.34714 66.0339 4.32977 65.7365 4.33325C65.5372 4.33325 65.2905 4.35756 65.1892 4.38535Z"
      fill="#6E77F0"
    />
  </svg>
</template>

<script>
export default {
  name: "AllCustomers"
};
</script>

<style lang="scss" scoped></style>
