<template>
  <div id="app">
    <v-app
      class="main-app-container"
      :style="{ background: $vuetify.theme.themes[theme].background }"
    >
      <v-main :class="!showAppBar() ? '' : 'pt-16'">
        <ModalDialog />
        <ConfirmDialog ref="confirm"></ConfirmDialog>

        <v-badge
          v-if="showAppBar()"
          class="nav-menu-badge"
          :color="getNotificationUnreadCount > 0 ? 'red' : 'transparent'"
          :content="
            getNotificationUnreadCount > 0 ? getNotificationUnreadCount : ''
          "
        >
          <v-btn
            class="nav-menu-button"
            fab
            x-small
            fixed
            @click="drawer = !drawer"
          >
            <v-icon color="primary" size="20">mdi-menu</v-icon>
          </v-btn>
        </v-badge>

        <ApplicationNavMenu :shown.sync="drawer" />

        <ApplicationBar v-if="showAppBar()" />
        <v-progress-linear
          color="secondary"
          height="5"
          indeterminate
          :active="getApiProcessingStatus"
          fixed
          top
          class="app-progress-bar"
        ></v-progress-linear>

        <template v-if="$route.meta.fullWidthPage">
          <router-view :key="$route.fullPath"></router-view>
        </template>
        <template v-else>
          <template
            v-if="
              $route.meta.showShopDropdown && $store.getters.getShops.length > 0
            "
          >
            <ShopListDropdown
              class="px-3 mx-auto"
              style="max-width: 375px; margin-top: 15px;"
            />
          </template>
          <router-view
            :key="$route.fullPath"
            class="ma-auto"
            style="max-width: 375px; "
          ></router-view>
        </template>

        <v-snackbar
          v-model="alertStatus"
          :color="getAlertSuccessColor()"
          :timeout="15000"
        >
          {{ getAlertText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="alertStatus = false"
            >
              {{ $t("close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import ApplicationBar from "@/components/layout/ApplicationBar";
import ApplicationNavMenu from "@/components/layout/ApplicationNavMenu";
import ConfirmDialog from "@/components/ui/ConfirmDialog";
import ModalDialog from "@/components/ui/ModalDialog";
import ShopListDropdown from "@/components/layout/ShopListDropdown";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalDialog,
    ConfirmDialog,
    ApplicationNavMenu,
    ApplicationBar,
    ShopListDropdown
  },
  watch: {
    $route: function(to, from) {
      let isShop = this.$store.getters.getFirstShopObject.id;
      let loggedIn = this.$store.getters.token;

      if (loggedIn) {
        if (isShop === 0) {
          if (to.path == "/shop-registration/details") {
            this.$router.push({ name: "ShopRegForm" }).catch(() => {});
          } else {
            this.$router.push({ name: "add_store" }).catch(() => {});
          }
        }

        if (isShop != 0) {
          let isUserShopStatus = this.$store.getters.userShopStatus;
          if (isUserShopStatus === 0) {
            this.$router.push({ name: "ConfirmInvitaion" }).catch(() => {});
          }
        }

        let userRole = this.$store.getters.getShopAuthUser[0].user_role;
        if (userRole != undefined && userRole != "") {
          if (
            this.$route.meta.forRoles &&
            !this.$route.meta.forRoles.includes(userRole)
          ) {
            this.$router.push({ name: "Top" }).catch(() => {});
          }
        }
      }
    }
  },
  beforeMount() {
    if (this.$store.getters.isLoggedIn) {
      this.$store.dispatch("SHOP_GET_ALL");
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    alertStatus: {
      get: function() {
        return this.$store.getters.getAlertStatus;
      },
      set: function() {
        this.$store.commit("hideAlert");
      }
    },
    ...mapGetters([
      "getAlertText",
      "getApiProcessingStatus",
      "getAlertSuccess",
      "getNotificationUnreadCount"
    ])
  },
  data() {
    return {
      drawer: false
    };
  },
  methods: {
    closeAlert() {
      this.$store.commit("hideAlert");
    },
    getAlertSuccessColor() {
      if (typeof this.getAlertSuccess === "boolean") {
        return this.getAlertSuccess ? "blue lighten-2" : "";
      }
      if (typeof this.getAlertSuccess === "string") {
        if (this.getAlertSuccess == "info") {
          return "info";
        }
      }
      return "";
    },
    showAppBar() {
      return this.isLoggedIn && !this.$route.meta.isWithoutHeader;
    }
  },
  mounted() {
    this.$root.confirm = this.$refs.confirm;
  }
};
</script>

<style scoped>
::v-deep .v-badge__badge {
  display: flex;
  justify-content: center;
}
.nav-menu-badge {
  position: fixed;
  right: 42px;
  top: 16px;
  z-index: 10;
}
.nav-menu-badge.v-badge >>> .v-badge__wrapper {
  top: 5px !important;
  z-index: 5;
  left: 24px !important;
}
.nav-menu-badge.v-badge >>> .v-badge__wrapper .v-badge__badge {
  height: 15px !important;
  min-width: 15px !important;
  width: 15px !important;
  line-height: 0.7 !important;
  padding: 4px 4px !important;
  font-size: 10px !important;
}

.nav-menu-button {
  background-color: rgba(255, 255, 255, 0.65) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
.app-progress-bar {
  z-index: 10;
}
</style>
