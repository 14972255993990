export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST";
export const NOTIFICATION_GET = "NOTIFICATION_GET";
export const NOTIFICATION_GET_SUCCESS = "NOTIFICATION_GET_SUCCESS";
export const NOTIFICATION_GET_ERROR = "NOTIFICATION_GET_ERROR";

export const NOTIFICATION_GET_DETAIL = "NOTIFICATION_GET_DETAIL";
export const NOTIFICATION_GET_DETAIL_SUCCESS =
  "NOTIFICATION_GET_DETAIL_SUCCESS";
export const NOTIFICATION_GET_DETAIL_ERROR = "NOTIFICATION_GET_DETAIL_ERROR";

export const NOTIFICATION_LIST_TITLE = "NOTIFICATION_LIST_TITLE";
export const NOTIFICATION_READ_STATUS_UPDATE =
  "NOTIFICATION_READ_STATUS_UPDATE";
export const NOTIFICATION_READ_STATUS_UPDATE_SUCCESS =
  "NOTIFICATION_READ_STATUS_UPDATE_SUCCESS";
export const NOTIFICATION_READ_STATUS_UPDATE_ERROR =
  "NOTIFICATION_READ_STATUS_UPDATE_ERROR";

export const NOTIFICATION_UNREAD_COUNT_GET = "NOTIFICATION_UNREAD_COUNT_GET";
export const NOTIFICATION_UNREAD_COUNT_GET_SUCCESS =
  "NOTIFICATION_UNREAD_COUNT_GET_SUCCESS";
export const NOTIFICATION_UNREAD_COUNT_GET_ERROR =
  "NOTIFICATION_UNREAD_COUNT_GET_ERROR";
