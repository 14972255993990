export const BUSINESS_REQUEST = "BUSINESS_REQUEST";

export const BUSINESS_GET_ALL = "BUSINESS_GET_ALL";
export const BUSINESS_GET_ALL_SUCCESS = "BUSINESS_GET_ALL_SUCCESS";

export const BUSINESS_REGISTER = "BUSINESS_REGISTER";
export const BUSINESS_REGISTER_SUCCESS = "BUSINESS_REGISTER_SUCCESS";

export const BUSINESS_GET_BY_YEAR = "BUSINESS_GET_BY_YEAR";
export const BUSINESS_GET_BY_YEAR_SUCCESS = "BUSINESS_GET_BY_YEAR_SUCCESS";

export const BUSINESS_UPDATE = "BUSINESS_UPDATE";
export const BUSINESS_UPDATE_SUCCESS = "BUSINESS_UPDATE_SUCCESS";

export const BUSINESS_DELETE = "BUSINESS_DELETE";
export const BUSINESS_DELETE_SUCCESS = "BUSINESS_DELETE_SUCCESS";