<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0859 0C10.0023 0 0 9.83039 0 21.9141C0 33.9977 10.0023 44 22.0859 44C34.1696 44 44 33.9977 44 21.9141C44 9.83039 34.1696 0 22.0859 0ZM32.933 29.1148C33.9384 30.1202 33.9384 31.7556 32.933 32.762C31.9352 33.7588 30.2998 33.7752 29.2858 32.762L22.0859 25.5595L14.7134 32.7628C13.7079 33.7683 12.0725 33.7683 11.0662 32.7628C10.0607 31.7573 10.0607 30.122 11.0662 29.1156L18.2677 21.9141L11.0662 14.7125C10.0607 13.7062 10.0607 12.0708 11.0662 11.0653C12.0725 10.0598 13.7079 10.0598 14.7134 11.0653L22.0859 18.2686L29.2858 11.0653C30.2895 10.0616 31.9249 10.0581 32.933 11.0653C33.9384 12.0708 33.9384 13.7062 32.933 14.7125L25.7314 21.9141L32.933 29.1148Z"
      fill="#FF5959"
    />
  </svg>
</template>

<script>
export default {
  name: "CloseViewIcon"
};
</script>
