import axios from "@/services/api";
import {
  USER_REQUEST,
  USER_REPORT_GET,
  USER_REPORT_GET_SUCCESS,
  USER_REPORT_GET_ERROR,
  USER_REGISTER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_ERROR,
  USER_WITHDRAW_ACCOUNT,
  USER_WITHDRAW_ACCOUNT_SUCCESS,
  USER_WITHDRAW_ACCOUNT_ERROR
} from "@/store/actions/user";

function initialState() {
  return {
    report: {}
  };
}

const state = initialState();

const getters = {
  getUserReport: state => state.report
};

const actions = {
  [USER_REGISTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("USER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/register`, params)
        .then(response => {
          commit("USER_REGISTER_SUCCESS", response.data);
          const token = response.data.data.access_token;
          const user = response.data.data.user;
          sessionStorage.setItem("user_id", user.id);

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          commit("AUTH_SUCCESS", { token, user });
          resolve(response);
        })
        .catch(error => {
          commit("USER_REGISTER_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_CHANGE_PASSWORD]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/settings/change-password`, params)
        .then(response => {
          commit("USER_CHANGE_PASSWORD_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("USER_CHANGE_PASSWORD_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [USER_WITHDRAW_ACCOUNT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/user/withdraw/${params.id}`, params)
        .then(response => {
          commit("USER_WITHDRAW_ACCOUNT_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("USER_WITHDRAW_ACCOUNT_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },

  [USER_REPORT_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.report = params.data;
  },

  [USER_REPORT_GET_ERROR]: state => {
    state.status = "error";
  },

  [USER_REGISTER_SUCCESS]: state => {
    state.status = "success";
  },

  [USER_REGISTER_ERROR]: state => {
    state.status = "error";
  },

  [USER_CHANGE_PASSWORD_SUCCESS]: state => {
    state.status = "success";
  },

  [USER_CHANGE_PASSWORD_ERROR]: state => {
    state.status = "error";
  },

  [USER_WITHDRAW_ACCOUNT_SUCCESS]: state => {
    state.status = "success";
  },

  [USER_WITHDRAW_ACCOUNT_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
