import axios from "@/services/api";
import {
  INVENTORY_REQUEST,
  INVENTORY_GET_ALL,
  INVENTORY_GET_ALL_SUCCESS,
  INVENTORY_GET_BY_THIS_MONTH,
  INVENTORY_GET_BY_THIS_MONTH_SUCCESS,
  INVENTORY_GET_BY_PRE_MONTH,
  INVENTORY_GET_BY_PRE_MONTH_SUCCESS,
  INVENTORY_REGISTER,
  INVENTORY_REGISTER_SUCCESS,
  INVENTORY_UPDATE,
  INVENTORY_UPDATE_SUCCESS
} from "@/store/actions/inventory";

function initialState() {
  return {
    inventories: [],
    inventoryByThisMonth: {},
    inventoryByPreMonth: {}
  };
}

const state = initialState();

const getters = {
  getInventories: state => state.inventories,
  getInventoryByThisMonth: state => state.inventoryByThisMonth,
  getInventoryByPreMonth: state => state.inventoryByPreMonth
};

const actions = {
  [INVENTORY_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("INVENTORY_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.id}/inventories`)
        .then(response => {
          commit("INVENTORY_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [INVENTORY_GET_BY_THIS_MONTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("INVENTORY_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(
          `/user/shops/${params.shop_id}/this_month_inventory?year_month=${params.year_month}`
        )
        .then(response => {
          commit("INVENTORY_GET_BY_THIS_MONTH_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [INVENTORY_GET_BY_PRE_MONTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("INVENTORY_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(
          `/user/shops/${params.shop_id}/pre_month_inventory?year_month=${params.year_month}`
        )
        .then(response => {
          commit("INVENTORY_GET_BY_PRE_MONTH_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [INVENTORY_REGISTER]: ({ commit, dispatch }, { params, shop_id }) => {
    return new Promise((resolve, reject) => {
      commit("INVENTORY_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/shops/${shop_id}/inventories`, params)
        .then(response => {
          commit("INVENTORY_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [INVENTORY_UPDATE]: (
    { commit, dispatch },
    { params, shop_id, inventory_id }
  ) => {
    return new Promise((resolve, reject) => {
      commit("INVENTORY_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/shops/${shop_id}/inventories/${inventory_id}`, params)
        .then(response => {
          commit("INVENTORY_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [INVENTORY_REQUEST]: state => {
    state.status = "loading";
  },

  [INVENTORY_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.inventories = params.data.inventory;
  },

  [INVENTORY_GET_BY_THIS_MONTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.inventoryByThisMonth = params.data.inventory;
  },

  [INVENTORY_GET_BY_PRE_MONTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.inventoryByPreMonth = params.data.inventory;
  },

  [INVENTORY_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [INVENTORY_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
