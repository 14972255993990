<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4889 0.000151858C11.2228 0.0439452 11.885 0.490089 12.2596 1.30711C12.6422 2.14211 12.5039 2.94721 11.8676 3.60176C10.4705 5.03912 9.04271 6.44696 7.62627 7.86555C6.50935 8.98423 5.3981 10.1086 4.26631 11.212C4.05517 11.4181 3.77755 11.5987 3.49797 11.6838C2.59787 11.9579 1.68407 12.1868 0.773015 12.4236C0.180046 12.5776 -0.112431 12.2947 0.0398684 11.6986C0.282687 10.7496 0.535476 9.80281 0.814658 8.86399C0.881912 8.63779 1.03617 8.41511 1.20489 8.24522C3.67901 5.7576 6.16291 3.28015 8.64193 0.79762C9.10567 0.333294 9.6218 -0.00825489 10.4889 0.000151858ZM1.19746 11.2613C2.24439 10.9229 3.20765 10.9141 3.96719 10.1285C6.20553 7.81393 8.50801 5.56131 10.7829 3.2823C10.946 3.11925 11.1223 2.95698 11.2384 2.76226C11.5 2.32354 11.4678 1.87134 11.1622 1.46508C10.8437 1.04142 10.3819 0.948355 9.91917 1.12333C9.64546 1.22676 9.4087 1.4639 9.19286 1.67837C6.93751 3.92278 4.72106 6.20746 2.42328 8.4067C1.6004 9.19401 1.56696 10.1854 1.19746 11.2613Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
export default {
  name: "EditIcon",
  props: {
    height: {
      required: false,
      default: 13
    },
    width: {
      required: false,
      default: 13
    }
  }
};
</script>

<style scoped>
svg {
  position: relative;
}
</style>
