import axios from "axios";
import store from "@/store/index.js";

// if (process.env.NODE_ENV === "production") {
//   axios.defaults.withCredentials = true;
// }

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    store.commit("clearFormErrors");
    const token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    config.baseURL = process.env.VUE_APP_BASEURL;

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response?.status === 200) {
      if (
        response?.data?.data?.message &&
        !response?.data?.data?.hide_success_message
      )
        store.commit("showAlert", {
          text: response?.data?.data?.message,
          successStatus: true
        });
    }
    return response;
  },
  function(error) {
    // Do something with response error
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      store.commit("AUTH_LOGOUT");
    }

    if (error?.response?.status === 422) {
      if (error.response.config.url == "/user/register") {
        // do nothing
      } else if (
        error?.response?.data?.error?.message ||
        error?.response?.data?.message
      ) {
        store.commit("showAlert", {
          text:
            error?.response?.data?.error?.message ||
            error?.response?.data?.message,
          successStatus: false
        });
      }
    }
    return Promise.reject(error?.response);
  }
);

export default axios;
