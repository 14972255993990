import axios from "@/services/api";
import {
  GOAL_REQUEST,
  GOAL_GET_BY_YEAR,
  GOAL_GET_BY_YEAR_SUCCESS,
  GOAL_REGISTER,
  GOAL_REGISTER_SUCCESS
} from "@/store/actions/goal";

function initialState() {
  return {
    goals: [],
    businessYears: {},
    goalByYear: {},
    goalSales: [],
    goalLastAnnualSale: 0
  };
}

const state = initialState();

const getters = {
  getGoals: state => state.goals,
  getBusinessYears: state => state.businessYears,
  getGoalByYear: state => state.goalByYear,
  getGoalSales: state => state.goalSales,
  getGoalLastAnnualSale: state => state.goalLastAnnualSale
};

const actions = {
  [GOAL_GET_BY_YEAR]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("GOAL_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/goals`)
        .then(response => {
          commit("GOAL_GET_BY_YEAR_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [GOAL_REGISTER]: ({ commit, dispatch }, { params, shop_id }) => {
    return new Promise((resolve, reject) => {
      commit("GOAL_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/shops/${shop_id}/goals`, params)
        .then(response => {
          commit("GOAL_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [GOAL_REQUEST]: state => {
    state.status = "loading";
  },

  [GOAL_GET_BY_YEAR_SUCCESS]: (state, params) => {
    state.status = "success";
    state.goalByYear = params.data.goal;
    state.goalSales = params.data.goal_sales;
    state.businessYears = params.data.business_years;
    state.goalLastAnnualSale = parseInt(params.data.annual_sale);
  },

  [GOAL_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
