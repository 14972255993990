import axios from "@/services/api";

function initialState() {
  return {
    topPageReports: {},
    dailyReports: [], // all responses
    monthlyReports: [],
    monthlyReport: {},
    reportDetails: {},
    yearlyReportList: []
  };
}

const state = initialState();
const getters = {
  getTopPageReports: state => state.topPageReports,
  getMonthDailyReports: state => state.monthDailyReports,
  getReportDetails: state => state.reportDetails,
  getMonthlyReports: state => state.monthlyReports,
  getMonthlyReport: state => state.monthlyReport,
  getYearlyReportList: state => state.yearlyReportList
};

const actions = {
  ["GET_DAILY_REPORTS"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get(`user/shops/${params.shop_id}/daily-reports`, {
          params: { ...params.params }
        })
        .then(response => {
          commit("GET_DAILY_REPORTS_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["GET_DAILY_REPORT_BY_MONTH"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get(
          `user/shops/${params.shop_id}/daily-reports/get-monthly-listing/${params.date}`
        )
        .then(response => {
          commit("GET_DAILY_REPORT_BY_MONTH_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["GET_DAILY_REPORT_BY_DATE"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get(
          `user/shops/${params.shop_id}/daily-reports/get-by-date/${params.date}`
        )
        .then(response => {
          const { data } = response;
          commit("FETCH_DAILY_REPORT_SUCCESS", data.daily_report);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["GET_CUMULATED_REPORT_BY_DATE"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .get(
          `user/shops/${params.shop_id}/daily-reports/get-cumulated-report/${params.date}`
        )
        .then(response => {
          const { data } = response;
          commit("GET_CUMULATED_REPORT_BY_DATE_SUCCESS", data.cumulated_report);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["GET_REPORT_DETAILS_BY_DATE"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((ressolve, reject) => {
      return axios
        .get(
          `/user/shops/${params.shop_id}/daily-reports/details/${params.date}`
        )
        .then(response => {
          commit("GET_REPORT_DETAILS_BY_DATE_SUCCESS", response.data);
          ressolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["UPDATE_DAILY_REPORT_FORM"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    return new Promise((resolve, reject) => {
      axios
        .put(`user/shops/${params.shop_id}/daily-reports/update/form`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["GET_MONTHLY_REPORT"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    const { shop_id } = params;
    const { date } = params;
    return new Promise((ressolve, reject) => {
      axios
        .get(
          `/user/shops/${shop_id}/daily-reports/get-monthly-reports/${date}`,
          {
            params: { ...params.param }
          }
        )
        .then(response => {
          commit("GET_MONTHLY_REPORT_SUCCESS", response.data.data);
          ressolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["GET_YEARLY_REPORT"]: ({ commit, dispatch }, params) => {
    dispatch("API_PROCESSING", true, { root: true });
    const { shop_id } = params;
    const { year } = params;
    return new Promise((ressolve, reject) => {
      axios
        .get(
          `/user/shops/${shop_id}/daily-reports/get-yearly-listing/${year}`,
          {
            params: { ...params.param }
          }
        )
        .then(response => {
          commit("GET_YEARLY_REPORT_SUCCESS", response.data.data);
          ressolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  ["DAILY_REPORT_DELETE"]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/user/shops/${params.shop_id}/daily-reports/${params.date}`)
        .then(response => {
          commit("DAILY_REPORT_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
};

const mutations = {
  ["GET_DAILY_REPORTS_SUCCESS"]: (state, params) => {
    state.status = true;
    state.topPageReports = params.data;
  },
  ["GET_DAILY_REPORT_BY_MONTH_SUCCESS"]: (state, params) => {
    state.status = true;
    state.monthDailyReports = params.data; // Need to fetch different key in components
  },
  ["FETCH_DAILY_REPORT_SUCCESS"]: (state, data) => {
    state.status = true;
  },
  ["GET_CUMULATED_REPORT_BY_DATE_SUCCESS"]: (state, data) => {
    state.status = true;
  },
  ["GET_REPORT_DETAILS_BY_DATE_SUCCESS"]: (state, params) => {
    state.status = true;
    state.reportDetails = params.data;
  },
  ["GET_MONTHLY_REPORT_SUCCESS"]: (state, data) => {
    state.status = true;
    state.monthlyReport = data;
  },
  ["GET_YEARLY_REPORT_SUCCESS"]: (state, data) => {
    state.status = true;
    state.yearlyReportList = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
