import axios from "@/services/api";
import {
  LINK_POST_REQUEST,
  LINK_POST_GET,
  LINK_POST_GET_SUCCESS,
  LINK_POST_GET_ERROR,
  LINK_POST_GET_DETAIL,
  LINK_POST_GET_DETAIL_SUCCESS,
  LINK_POST_GET_DETAIL_ERROR,
  LINK_POST_CLICK_UPDATE,
  LINK_POST_CLICK_UPDATE_SUCCESS,
  LINK_POST_CLICK_UPDATE_ERROR
} from "@/store/actions/notification/link-post";

function initializeState() {
  return {
    linkPosts: {},
    pagination: {},
    linkPostDetails: {}
  };
}

const state = initializeState();

const getters = {
  getLinkPosts: state => state.linkPosts,
  getLinkPostsPagination: state => state.pagination,
  getLinkPost: state => state.linkPostDetails
};

const actions = {
  [LINK_POST_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("LINK_POST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/notifications-link-post`, params)
        .then(response => {
          commit("LINK_POST_GET_SUCCESS", {
            linkPosts: response.data.data.notification,
            pagination: response.data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("LINK_POST_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [LINK_POST_GET_DETAIL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("LINK_POST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/notifications/${params.id}`, params)
        .then(response => {
          commit("LINK_POST_GET_DETAIL_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("LINK_POST_GET_DETAIL_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [LINK_POST_CLICK_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("LINK_POST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/click-notification-link-post`, params)
        .then(response => {
          commit("LINK_POST_CLICK_UPDATE_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("LINK_POST_CLICK_UPDATE_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [LINK_POST_REQUEST]: state => {
    state.status = "loading";
  },

  [LINK_POST_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.linkPosts = params.linkPosts;
    state.pagination = params.pagination;
  },

  [LINK_POST_GET_ERROR]: state => {
    state.status = "error";
  },

  [LINK_POST_GET_DETAIL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.notificationDetails = params.data.notification;
  },

  [LINK_POST_GET_DETAIL_ERROR]: state => {
    state.status = "error";
  },

  [LINK_POST_CLICK_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [LINK_POST_CLICK_UPDATE_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
