<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6761 8.47282C15.5117 8.43463 15.3474 8.47282 15.2035 8.56828C14.6694 8.98833 14.053 9.332 13.375 9.56111C12.7381 9.79023 12.0396 9.90479 11.2999 9.90479C9.63575 9.90479 8.1154 9.27472 7.0265 8.26279C5.9376 7.25087 5.2596 5.83799 5.2596 4.29146C5.2596 3.6423 5.38287 3.01223 5.58833 2.43944C5.81432 1.82846 6.14305 1.27477 6.5745 0.797444C6.75941 0.587422 6.71832 0.281934 6.49232 0.110098C6.3485 0.014633 6.18414 -0.0235529 6.01978 0.014633C4.27343 0.453771 2.75307 1.42751 1.66417 2.72583C0.61636 4.00506 0 5.58978 0 7.30814C0 9.38928 0.903994 11.2795 2.38326 12.6542C3.86252 14.0289 5.87596 14.869 8.13595 14.869C10.0261 14.869 11.7725 14.258 13.1695 13.2461C14.5872 12.215 15.6144 10.7449 16.0459 9.0647C16.1281 8.7974 15.9637 8.53009 15.6761 8.47282Z"
      fill="#2E57AB"
    />
  </svg>
</template>

<script>
export default {
  name: "MoonIcon"
};
</script>
