<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 0C4.24891 0 0 4.24928 0 9.5C0 14.7511 4.24928 19 9.5 19C14.7511 19 19 14.7507 19 9.5C19 4.24891 14.7507 0 9.5 0ZM9.5 17.5156C5.0694 17.5156 1.48438 13.9303 1.48438 9.5C1.48438 5.0694 5.0697 1.48438 9.5 1.48438C13.9306 1.48438 17.5156 5.0697 17.5156 9.5C17.5156 13.9306 13.9303 17.5156 9.5 17.5156Z"
      fill="black"
    />
    <path
      d="M9.5 4.78223C9.09009 4.78223 8.75781 5.1145 8.75781 5.52441V10.3038C8.75781 10.7137 9.09009 11.046 9.5 11.046C9.90991 11.046 10.2422 10.7137 10.2422 10.3038V5.52441C10.2422 5.1145 9.90991 4.78223 9.5 4.78223Z"
      fill="black"
    />
    <path
      d="M9.5 13.959C10.0534 13.959 10.502 13.5104 10.502 12.957C10.502 12.4037 10.0534 11.9551 9.5 11.9551C8.94664 11.9551 8.49805 12.4037 8.49805 12.957C8.49805 13.5104 8.94664 13.959 9.5 13.959Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "WarningIcon"
};
</script>

<style lang="scss" scoped></style>
