<template>
  <div>
    <svg
      width="34"
      height="27"
      viewBox="0 0 34 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.51858 22.3249H4.00684V10.0002C4.00684 9.69345 4.25546 9.44482 4.56219 9.44482H8.96322C9.26995 9.44482 9.51858 9.69345 9.51858 10.0002V22.3249Z"
        fill="#6E77F0"
      />
      <path
        d="M19.7578 22.3281H14.2461V7.57391C14.2461 7.26718 14.4947 7.01855 14.8014 7.01855H19.2025C19.5092 7.01855 19.7578 7.26718 19.7578 7.57391V22.3281Z"
        fill="#6E77F0"
      />
      <path
        d="M29.999 22.3311H24.4873V10.8918C24.4873 10.5851 24.7359 10.3364 25.0427 10.3364H29.4437C29.7504 10.3364 29.999 10.5851 29.999 10.8918V22.3311Z"
        fill="#6E77F0"
      />
      <path
        d="M33.0018 26.2841H0.998238C0.446945 26.2841 0 25.8371 0 25.2858C0 24.7345 0.446945 24.2876 0.998238 24.2876H33.0018C33.5531 24.2876 34 24.7345 34 25.2858C34 25.8371 33.5531 26.2841 33.0018 26.2841Z"
        fill="#6E77F0"
      />
      <path
        d="M33.5849 3.79852C33.618 3.38132 33.3749 2.86903 32.8181 2.73946L29.5927 1.95551C29.0568 1.82487 28.5172 2.15396 28.387 2.68968C28.2568 3.2254 28.5855 3.76525 29.1212 3.89542L30.0334 4.11716L27.4475 5.71294L17.6424 0.130794C17.3605 -0.0297891 17.0179 -0.0432985 16.7244 0.094658L6.97799 4.66939L2.31888 1.76158C1.85124 1.4695 1.23546 1.61218 0.943505 2.07989C0.65162 2.54753 0.794168 3.16331 1.26188 3.45526L6.38191 6.65062C6.6692 6.82997 7.0279 6.8514 7.33456 6.70746L17.1043 2.12175L26.9742 7.74083C27.231 7.87553 27.589 7.94534 27.9924 7.7228L31.1272 5.78828L30.9184 6.76602C30.6887 8.06659 32.5298 8.43594 32.8708 7.18302L33.5628 3.94247C33.5706 3.89701 33.5736 3.9034 33.5849 3.79852V3.79852Z"
        fill="#6E77F0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "AverageSalesIcon"
};
</script>

<style lang="scss" scoped></style>
