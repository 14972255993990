<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00177 3.73145C6.09077 3.73145 3.72217 6.10005 3.72217 9.01104C3.72217 11.922 6.09077 14.2906 9.00177 14.2906C11.9128 14.2906 14.2814 11.922 14.2814 9.01104C14.2814 6.10005 11.9128 3.73145 9.00177 3.73145Z"
      fill="#8C7023"
    />
    <path
      d="M14.6088 12.6472C14.5624 12.4748 14.326 12.4424 14.2253 12.5898C13.7824 13.2378 13.2151 13.7997 12.5648 14.2454C12.4182 14.3459 12.4511 14.5802 12.6227 14.6263L15.0026 15.2668C15.1515 15.3068 15.2878 15.1705 15.2477 15.0217L14.6088 12.6472Z"
      fill="#8C7023"
    />
    <path
      d="M9.17304 0.310672C9.09662 0.174541 8.90066 0.174542 8.82424 0.310674L7.61038 2.47306C7.52295 2.6288 7.66961 2.81786 7.84495 2.78388C8.21947 2.7113 8.60644 2.675 8.99864 2.675C9.39085 2.675 9.77783 2.7113 10.1524 2.78388C10.3277 2.81786 10.4744 2.62879 10.3869 2.47305L9.17304 0.310672Z"
      fill="#8C7023"
    />
    <path
      d="M3.00169 2.76587C2.85269 2.72551 2.71607 2.86201 2.75631 3.01105L3.39451 5.37474C3.44102 5.547 3.67707 5.57916 3.77737 5.4316C4.2164 4.78573 4.77228 4.22963 5.41859 3.78596C5.56497 3.68548 5.53233 3.45143 5.36096 3.405L3.00169 2.76587Z"
      fill="#8C7023"
    />
    <path
      d="M2.77278 7.84848C2.80671 7.67269 2.61661 7.52591 2.46087 7.61423L0.306793 8.83575C0.171667 8.91237 0.171668 9.10707 0.306793 9.1837L2.46091 10.4052C2.61664 10.4936 2.80673 10.3468 2.7728 10.171C2.70054 9.79664 2.6644 9.40834 2.6644 9.00972C2.6644 8.6111 2.70052 8.22282 2.77278 7.84848Z"
      fill="#8C7023"
    />
    <path
      d="M3.77825 12.5898C3.67753 12.4424 3.44114 12.4748 3.39476 12.6472L2.75577 15.0217C2.71571 15.1705 2.85201 15.3068 3.00087 15.2668L5.3808 14.6263C5.5524 14.5802 5.5853 14.3459 5.43871 14.2454C4.78844 13.7997 4.22111 13.2378 3.77825 12.5898Z"
      fill="#8C7023"
    />
    <path
      d="M9.00124 15.3468C8.59934 15.3468 8.2079 15.3101 7.82696 15.2366C7.65155 15.2028 7.50598 15.3923 7.59428 15.5475L8.82738 17.716C8.90407 17.8509 9.09842 17.8509 9.1751 17.716L10.4082 15.5475C10.4965 15.3922 10.351 15.2027 10.1756 15.2366C9.79461 15.31 9.40315 15.3468 9.00124 15.3468Z"
      fill="#8C7023"
    />
    <path
      d="M12.6379 3.41058C12.4666 3.45708 12.434 3.6912 12.5803 3.79177C13.2252 4.23509 13.7837 4.79407 14.2263 5.43953C14.3268 5.58606 14.5612 5.55358 14.6077 5.3821L15.2505 3.01356C15.291 2.86443 15.1543 2.72769 15.0051 2.76816L12.6379 3.41058Z"
      fill="#8C7023"
    />
    <path
      d="M17.693 9.18967C17.8283 9.11308 17.8283 8.91817 17.693 8.84158L15.5304 7.61741C15.3761 7.5301 15.189 7.6728 15.2226 7.84683C15.2953 8.224 15.3357 8.61228 15.3357 9.01048C15.3357 9.41253 15.2946 9.80459 15.2206 10.1852C15.1867 10.3594 15.374 10.5025 15.5284 10.4151L17.693 9.18967Z"
      fill="#8C7023"
    />
  </svg>
</template>

<script>
export default {
  name: "SunIconAlt"
};
</script>
