export const SUPPLIER_REQUEST = "SUPPLIER_REQUEST";

export const SUPPLIER_GET_ALL = "SUPPLIER_GET_ALL";
export const SUPPLIER_GET_ALL_SUCCESS = "SUPPLIER_GET_ALL_SUCCESS";

export const SUPPLIER_REGISTER = "SUPPLIER_REGISTER";
export const SUPPLIER_REGISTER_SUCCESS = "SUPPLIER_REGISTER_SUCCESS";

export const SUPPLIER_UPDATE = "SUPPLIER_UPDATE";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";

export const SUPPLIER_DELETE = "SUPPLIER_DELETE";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";

export const SUPPLIER_GET = "SUPPLIER_GET";
export const SUPPLIER_GET_SUCCESS = "SUPPLIER_GET_SUCCESS";

// export const SUPPLIER_ACCOUNT_PAYABLE_GET_ALL =
//   "SUPPLIER_ACCOUNT_PAYABLE_GET_ALL";
// export const SUPPLIER_ACCOUNT_PAYABLE_GET_ALL_SUCCESS =
//   "SUPPLIER_ACCOUNT_PAYABLE_GET_ALL_SUCCESS";

// export const SUPPLIER_CASH_FLOW_GET_ALL = "SUPPLIER_CASH_FLOW_GET_ALL";
// export const SUPPLIER_CASH_FLOW_GET_ALL_SUCCESS =
//   "SUPPLIER_CASH_FLOW_GET_ALL_SUCCESS";
