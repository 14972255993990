<template>
  <div>
    <svg
      width="95"
      height="23"
      viewBox="0 0 95 23"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1219_1101"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="95"
        height="23"
      >
        <rect width="95" height="23" rx="5" fill="#F4F8FC" />
      </mask>
      <g mask="url(#mask0_1219_1101)">
        <path
          d="M2.88055 2.93005C2.56995 3.01107 2.337 3.14949 2.10405 3.38244C1.85085 3.63902 1.7158 3.88547 1.64491 4.23321C1.43897 5.22577 2.23572 6.18457 3.27217 6.18457C4.18033 6.18457 4.91969 5.43846 4.91631 4.5303C4.91293 4.07791 4.74751 3.68966 4.42003 3.36556C4.00477 2.95368 3.41396 2.78825 2.88055 2.93005Z"
          fill=""
        />
        <path
          d="M2.4754 6.64241C2.07027 6.74707 1.89471 6.87198 1.32754 7.44591C0.517281 8.27305 -0.0465208 8.93475 -0.579938 9.67749C-0.907416 10.1366 -0.974938 10.2818 -0.971562 10.5418C-0.971562 10.795 -0.88716 10.9908 -0.704853 11.1528C-0.519169 11.3216 -0.360494 11.3824 -0.120794 11.3824C0.24382 11.3824 0.436256 11.2474 0.807623 10.7241C0.91228 10.5755 1.1216 10.2987 1.27352 10.1096C1.51997 9.79565 1.54698 9.77202 1.55373 9.85642C1.56048 9.90706 1.57736 10.6667 1.59762 11.5444C1.62125 12.6991 1.61788 13.192 1.59087 13.3169C1.57061 13.4148 1.46595 13.6714 1.35792 13.8874C0.702965 15.194 0.162795 16.5782 -0.377375 18.3202C-0.640708 19.1676 -0.66434 19.3499 -0.529298 19.6301C-0.387503 19.9306 -0.0262644 20.1602 0.301214 20.1602C0.473393 20.1602 0.743477 20.0589 0.885272 19.934C1.06083 19.7854 1.11822 19.6571 1.3748 18.8266C1.84407 17.3141 2.23907 16.2709 2.78262 15.1366C2.98181 14.7146 3.02232 14.6538 3.08646 14.6639C3.15061 14.6741 3.24852 14.9408 3.95749 17.0441C4.83526 19.6436 4.81163 19.593 5.14586 19.7652C5.30791 19.8496 5.35518 19.8597 5.56787 19.8462C6.13505 19.8124 6.54693 19.3803 6.4794 18.884C6.46928 18.7996 6.07428 17.5842 5.60163 16.1798C5.13236 14.7753 4.72048 13.5194 4.69347 13.3912C4.60232 12.9894 4.50441 11.406 4.50103 10.3426V9.92394L4.71373 10.1164C5.34505 10.6869 6.10804 11.2642 6.79675 11.6896C7.37069 12.0441 7.55299 12.088 7.83658 11.9496C7.99188 11.8753 8.16406 11.6694 8.23833 11.4736C8.30585 11.2912 8.29235 10.8962 8.2147 10.7308C8.10329 10.5012 8.02226 10.427 7.48885 10.0826C6.34774 9.33988 5.54761 8.60728 4.61582 7.44929C4.19719 6.93275 4.01826 6.78758 3.65364 6.67279C3.43082 6.60527 3.31941 6.58839 3.02232 6.59177C2.82313 6.59177 2.57668 6.6154 2.4754 6.64241Z"
          fill=""
        />
        <path
          d="M18.3044 2.93005C17.9938 3.01107 17.7608 3.14949 17.5279 3.38244C17.2747 3.63902 17.1396 3.88547 17.0687 4.23321C16.8628 5.22577 17.6595 6.18457 18.696 6.18457C19.6042 6.18457 20.3435 5.43846 20.3401 4.5303C20.3368 4.07791 20.1713 3.68966 19.8439 3.36556C19.4286 2.95368 18.8378 2.78825 18.3044 2.93005Z"
          fill=""
        />
        <path
          d="M17.8992 6.64241C17.4941 6.74707 17.3185 6.87198 16.7514 7.44591C15.9411 8.27305 15.3773 8.93475 14.8439 9.67749C14.5164 10.1366 14.4489 10.2818 14.4523 10.5418C14.4523 10.795 14.5367 10.9908 14.719 11.1528C14.9047 11.3216 15.0633 11.3824 15.303 11.3824C15.6676 11.3824 15.8601 11.2474 16.2315 10.7241C16.3361 10.5755 16.5454 10.2987 16.6973 10.1096C16.9438 9.79565 16.9708 9.77202 16.9776 9.85642C16.9843 9.90706 17.0012 10.6667 17.0214 11.5444C17.0451 12.6991 17.0417 13.192 17.0147 13.3169C16.9944 13.4148 16.8898 13.6714 16.7817 13.8874C16.1268 15.194 15.5866 16.5782 15.0465 18.3202C14.7831 19.1676 14.7595 19.3499 14.8945 19.6301C15.0363 19.9306 15.3976 20.1602 15.725 20.1602C15.8972 20.1602 16.1673 20.0589 16.3091 19.934C16.4847 19.7854 16.542 19.6571 16.7986 18.8266C17.2679 17.3141 17.6629 16.2709 18.2064 15.1366C18.4056 14.7146 18.4461 14.6538 18.5103 14.6639C18.5744 14.6741 18.6723 14.9408 19.3813 17.0441C20.2591 19.6436 20.2355 19.593 20.5697 19.7652C20.7317 19.8496 20.779 19.8597 20.9917 19.8462C21.5589 19.8124 21.9708 19.3803 21.9032 18.884C21.8931 18.7996 21.4981 17.5842 21.0255 16.1798C20.5562 14.7753 20.1443 13.5194 20.1173 13.3912C20.0261 12.9894 19.9282 11.406 19.9249 10.3426V9.92394L20.1376 10.1164C20.7689 10.6869 21.5319 11.2642 22.2206 11.6896C22.7945 12.0441 22.9768 12.088 23.2604 11.9496C23.4157 11.8753 23.5879 11.6694 23.6622 11.4736C23.7297 11.2912 23.7162 10.8962 23.6385 10.7308C23.5271 10.5012 23.4461 10.427 22.9127 10.0826C21.7716 9.33988 20.9714 8.60728 20.0396 7.44929C19.621 6.93275 19.4421 6.78758 19.0775 6.67279C18.8547 6.60527 18.7432 6.58839 18.4461 6.59177C18.247 6.59177 18.0005 6.6154 17.8992 6.64241Z"
          fill=""
        />
        <path
          d="M33.7262 2.93005C33.4157 3.01107 33.1827 3.14949 32.9498 3.38244C32.6965 3.63902 32.5615 3.88547 32.4906 4.23321C32.2847 5.22577 33.0814 6.18457 34.1179 6.18457C35.026 6.18457 35.7654 5.43846 35.762 4.5303C35.7586 4.07791 35.5932 3.68966 35.2657 3.36556C34.8505 2.95368 34.2597 2.78825 33.7262 2.93005Z"
          fill=""
        />
        <path
          d="M33.3201 6.64241C32.915 6.74707 32.7394 6.87198 32.1723 7.44591C31.362 8.27305 30.7982 8.93475 30.2648 9.67749C29.9373 10.1366 29.8698 10.2818 29.8732 10.5418C29.8732 10.795 29.9576 10.9908 30.1399 11.1528C30.3256 11.3216 30.4842 11.3824 30.7239 11.3824C31.0885 11.3824 31.281 11.2474 31.6523 10.7241C31.757 10.5755 31.9663 10.2987 32.1182 10.1096C32.3647 9.79565 32.3917 9.77202 32.3985 9.85642C32.4052 9.90706 32.4221 10.6667 32.4423 11.5444C32.466 12.6991 32.4626 13.192 32.4356 13.3169C32.4153 13.4148 32.3107 13.6714 32.2026 13.8874C31.5477 15.194 31.0075 16.5782 30.4674 18.3202C30.204 19.1676 30.1804 19.3499 30.3154 19.6301C30.4572 19.9306 30.8185 20.1602 31.1459 20.1602C31.3181 20.1602 31.5882 20.0589 31.73 19.934C31.9056 19.7854 31.9629 19.6571 32.2195 18.8266C32.6888 17.3141 33.0838 16.2709 33.6273 15.1366C33.8265 14.7146 33.867 14.6538 33.9312 14.6639C33.9953 14.6741 34.0932 14.9408 34.8022 17.0441C35.68 19.6436 35.6564 19.593 35.9906 19.7652C36.1526 19.8496 36.1999 19.8597 36.4126 19.8462C36.9798 19.8124 37.3917 19.3803 37.3241 18.884C37.314 18.7996 36.919 17.5842 36.4464 16.1798C35.9771 14.7753 35.5652 13.5194 35.5382 13.3912C35.447 12.9894 35.3491 11.406 35.3458 10.3426V9.92394L35.5585 10.1164C36.1898 10.6869 36.9528 11.2642 37.6415 11.6896C38.2154 12.0441 38.3977 12.088 38.6813 11.9496C38.8366 11.8753 39.0088 11.6694 39.0831 11.4736C39.1506 11.2912 39.1371 10.8962 39.0594 10.7308C38.948 10.5012 38.867 10.427 38.3336 10.0826C37.1925 9.33988 36.3923 8.60728 35.4605 7.44929C35.0419 6.93275 34.863 6.78758 34.4984 6.67279C34.2755 6.60527 34.1641 6.58839 33.867 6.59177C33.6679 6.59177 33.4214 6.6154 33.3201 6.64241Z"
          fill=""
        />
        <path
          d="M49.1491 2.93005C48.8385 3.01107 48.6056 3.14949 48.3726 3.38244C48.1194 3.63902 47.9844 3.88547 47.9135 4.23321C47.7075 5.22577 48.5043 6.18457 49.5407 6.18457C50.4489 6.18457 51.1882 5.43846 51.1849 4.5303C51.1815 4.07791 51.0161 3.68966 50.6886 3.36556C50.2733 2.95368 49.6825 2.78825 49.1491 2.93005Z"
          fill=""
        />
        <path
          d="M48.744 6.64241C48.3388 6.74707 48.1633 6.87198 47.5961 7.44591C46.7858 8.27305 46.222 8.93475 45.6886 9.67749C45.3611 10.1366 45.2936 10.2818 45.297 10.5418C45.297 10.795 45.3814 10.9908 45.5637 11.1528C45.7494 11.3216 45.9081 11.3824 46.1478 11.3824C46.5124 11.3824 46.7048 11.2474 47.0762 10.7241C47.1808 10.5755 47.3902 10.2987 47.5421 10.1096C47.7885 9.79565 47.8155 9.77202 47.8223 9.85642C47.829 9.90706 47.8459 10.6667 47.8662 11.5444C47.8898 12.6991 47.8864 13.192 47.8594 13.3169C47.8392 13.4148 47.7345 13.6714 47.6265 13.8874C46.9715 15.194 46.4313 16.5782 45.8912 18.3202C45.6278 19.1676 45.6042 19.3499 45.7393 19.6301C45.8811 19.9306 46.2423 20.1602 46.5698 20.1602C46.7419 20.1602 47.012 20.0589 47.1538 19.934C47.3294 19.7854 47.3868 19.6571 47.6434 18.8266C48.1126 17.3141 48.5076 16.2709 49.0512 15.1366C49.2504 14.7146 49.2909 14.6538 49.355 14.6639C49.4192 14.6741 49.5171 14.9408 50.226 17.0441C51.1038 19.6436 51.0802 19.593 51.4144 19.7652C51.5765 19.8496 51.6237 19.8597 51.8364 19.8462C52.4036 19.8124 52.8155 19.3803 52.748 18.884C52.7378 18.7996 52.3428 17.5842 51.8702 16.1798C51.4009 14.7753 50.989 13.5194 50.962 13.3912C50.8709 12.9894 50.773 11.406 50.7696 10.3426V9.92394L50.9823 10.1164C51.6136 10.6869 52.3766 11.2642 53.0653 11.6896C53.6392 12.0441 53.8215 12.088 54.1051 11.9496C54.2604 11.8753 54.4326 11.6694 54.5069 11.4736C54.5744 11.2912 54.5609 10.8962 54.4833 10.7308C54.3718 10.5012 54.2908 10.427 53.7574 10.0826C52.6163 9.33988 51.8162 8.60728 50.8844 7.44929C50.4657 6.93275 50.2868 6.78758 49.9222 6.67279C49.6994 6.60527 49.588 6.58839 49.2909 6.59177C49.0917 6.59177 48.8452 6.6154 48.744 6.64241Z"
          fill=""
        />
        <path
          d="M64.569 2.93005C64.2584 3.01107 64.0255 3.14949 63.7925 3.38244C63.5393 3.63902 63.4043 3.88547 63.3334 4.23321C63.1274 5.22577 63.9242 6.18457 64.9606 6.18457C65.8688 6.18457 66.6082 5.43846 66.6048 4.5303C66.6014 4.07791 66.436 3.68966 66.1085 3.36556C65.6932 2.95368 65.1024 2.78825 64.569 2.93005Z"
          fill=""
        />
        <path
          d="M64.1639 6.64241C63.7587 6.74707 63.5832 6.87198 63.016 7.44591C62.2058 8.27305 61.642 8.93475 61.1085 9.67749C60.7811 10.1366 60.7135 10.2818 60.7169 10.5418C60.7169 10.795 60.8013 10.9908 60.9836 11.1528C61.1693 11.3216 61.328 11.3824 61.5677 11.3824C61.9323 11.3824 62.1247 11.2474 62.4961 10.7241C62.6008 10.5755 62.8101 10.2987 62.962 10.1096C63.2084 9.79565 63.2355 9.77202 63.2422 9.85642C63.249 9.90706 63.2658 10.6667 63.2861 11.5444C63.3097 12.6991 63.3064 13.192 63.2793 13.3169C63.2591 13.4148 63.1544 13.6714 63.0464 13.8874C62.3914 15.194 61.8513 16.5782 61.3111 18.3202C61.0478 19.1676 61.0241 19.3499 61.1592 19.6301C61.301 19.9306 61.6622 20.1602 61.9897 20.1602C62.1619 20.1602 62.432 20.0589 62.5737 19.934C62.7493 19.7854 62.8067 19.6571 63.0633 18.8266C63.5325 17.3141 63.9275 16.2709 64.4711 15.1366C64.6703 14.7146 64.7108 14.6538 64.7749 14.6639C64.8391 14.6741 64.937 14.9408 65.646 17.0441C66.5237 19.6436 66.5001 19.593 66.8343 19.7652C66.9964 19.8496 67.0437 19.8597 67.2563 19.8462C67.8235 19.8124 68.2354 19.3803 68.1679 18.884C68.1578 18.7996 67.7628 17.5842 67.2901 16.1798C66.8208 14.7753 66.409 13.5194 66.3819 13.3912C66.2908 12.9894 66.1929 11.406 66.1895 10.3426V9.92394L66.4022 10.1164C67.0335 10.6869 67.7965 11.2642 68.4852 11.6896C69.0592 12.0441 69.2415 12.088 69.5251 11.9496C69.6804 11.8753 69.8525 11.6694 69.9268 11.4736C69.9943 11.2912 69.9808 10.8962 69.9032 10.7308C69.7918 10.5012 69.7107 10.427 69.1773 10.0826C68.0362 9.33988 67.2361 8.60728 66.3043 7.44929C65.8857 6.93275 65.7067 6.78758 65.3421 6.67279C65.1193 6.60527 65.0079 6.58839 64.7108 6.59177C64.5116 6.59177 64.2652 6.6154 64.1639 6.64241Z"
          fill=""
        />
        <path
          d="M79.8522 2.92907C79.5416 3.0101 79.3087 3.14852 79.0757 3.38146C78.8225 3.63804 78.6875 3.8845 78.6166 4.23223C78.4106 5.22479 79.2074 6.18359 80.2438 6.18359C81.152 6.18359 81.8914 5.43748 81.888 4.52932C81.8846 4.07693 81.7192 3.68868 81.3917 3.36458C80.9765 2.9527 80.3856 2.78728 79.8522 2.92907Z"
          fill=""
        />
        <path
          d="M79.4471 6.64143C79.0419 6.74609 78.8664 6.871 78.2992 7.44493C77.489 8.27207 76.9252 8.93378 76.3917 9.67651C76.0643 10.1357 75.9967 10.2808 76.0001 10.5408C76.0001 10.794 76.0845 10.9898 76.2668 11.1518C76.4525 11.3207 76.6112 11.3814 76.8509 11.3814C77.2155 11.3814 77.4079 11.2464 77.7793 10.7231C77.884 10.5745 78.0933 10.2977 78.2452 10.1086C78.4917 9.79467 78.5187 9.77104 78.5254 9.85544C78.5322 9.90608 78.549 10.6657 78.5693 11.5435C78.5929 12.6981 78.5896 13.191 78.5625 13.3159C78.5423 13.4138 78.4376 13.6704 78.3296 13.8865C77.6746 15.193 77.1345 16.5772 76.5943 18.3192C76.331 19.1666 76.3073 19.3489 76.4424 19.6291C76.5842 19.9296 76.9454 20.1592 77.2729 20.1592C77.4451 20.1592 77.7152 20.0579 77.857 19.933C78.0325 19.7844 78.0899 19.6561 78.3465 18.8256C78.8158 17.3132 79.2108 16.27 79.7543 15.1356C79.9535 14.7136 79.994 14.6528 80.0581 14.663C80.1223 14.6731 80.2202 14.9398 80.9292 17.0431C81.8069 19.6426 81.7833 19.592 82.1175 19.7642C82.2796 19.8486 82.3269 19.8587 82.5395 19.8452C83.1067 19.8114 83.5186 19.3793 83.4511 18.883C83.441 18.7986 83.046 17.5832 82.5733 16.1788C82.104 14.7744 81.6922 13.5185 81.6651 13.3902C81.574 12.9884 81.4761 11.4051 81.4727 10.3416V9.92296L81.6854 10.1154C82.3167 10.686 83.0797 11.2633 83.7684 11.6886C84.3424 12.0431 84.5247 12.087 84.8083 11.9486C84.9636 11.8743 85.1357 11.6684 85.21 11.4726C85.2775 11.2903 85.264 10.8953 85.1864 10.7298C85.075 10.5003 84.9939 10.426 84.4605 10.0816C83.3194 9.3389 82.5193 8.6063 81.5875 7.44831C81.1689 6.93177 80.9899 6.7866 80.6253 6.67182C80.4025 6.60429 80.2911 6.58741 79.994 6.59079C79.7948 6.59079 79.5484 6.61442 79.4471 6.64143Z"
          fill=""
        />
        <path
          d="M94.8522 2.93005C94.5416 3.01107 94.3087 3.14949 94.0757 3.38244C93.8225 3.63902 93.6875 3.88547 93.6166 4.23321C93.4106 5.22577 94.2074 6.18457 95.2438 6.18457C96.152 6.18457 96.8914 5.43846 96.888 4.5303C96.8846 4.07791 96.7192 3.68966 96.3917 3.36556C95.9765 2.95368 95.3856 2.78825 94.8522 2.93005Z"
          fill=""
        />
        <path
          d="M94.4471 6.64241C94.0419 6.74707 93.8664 6.87198 93.2992 7.44591C92.489 8.27305 91.9252 8.93475 91.3917 9.67749C91.0643 10.1366 90.9967 10.2818 91.0001 10.5418C91.0001 10.795 91.0845 10.9908 91.2668 11.1528C91.4525 11.3216 91.6112 11.3824 91.8509 11.3824C92.2155 11.3824 92.4079 11.2474 92.7793 10.7241C92.884 10.5755 93.0933 10.2987 93.2452 10.1096C93.4917 9.79565 93.5187 9.77202 93.5254 9.85642C93.5322 9.90706 93.549 10.6667 93.5693 11.5444C93.5929 12.6991 93.5896 13.192 93.5625 13.3169C93.5423 13.4148 93.4376 13.6714 93.3296 13.8874C92.6746 15.194 92.1345 16.5782 91.5943 18.3202C91.331 19.1676 91.3073 19.3499 91.4424 19.6301C91.5842 19.9306 91.9454 20.1602 92.2729 20.1602C92.4451 20.1602 92.7152 20.0589 92.857 19.934C93.0325 19.7854 93.0899 19.6571 93.3465 18.8266C93.8158 17.3141 94.2108 16.2709 94.7543 15.1366C94.9535 14.7146 94.994 14.6538 95.0581 14.6639C95.1223 14.6741 95.2202 14.9408 95.9292 17.0441C96.8069 19.6436 96.7833 19.593 97.1175 19.7652C97.2796 19.8496 97.3269 19.8597 97.5395 19.8462C98.1067 19.8124 98.5186 19.3803 98.4511 18.884C98.441 18.7996 98.046 17.5842 97.5733 16.1798C97.104 14.7753 96.6922 13.5194 96.6651 13.3912C96.574 12.9894 96.4761 11.406 96.4727 10.3426V9.92394L96.6854 10.1164C97.3167 10.6869 98.0797 11.2642 98.7684 11.6896C99.3424 12.0441 99.5247 12.088 99.8083 11.9496C99.9636 11.8753 100.136 11.6694 100.21 11.4736C100.278 11.2912 100.264 10.8962 100.186 10.7308C100.075 10.5012 99.9939 10.427 99.4605 10.0826C98.3194 9.33988 97.5193 8.60728 96.5875 7.44929C96.1689 6.93275 95.9899 6.78758 95.6253 6.67279C95.4025 6.60527 95.2911 6.58839 94.994 6.59177C94.7948 6.59177 94.5484 6.6154 94.4471 6.64241Z"
          fill=""
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "MultipleCustomersIcon"
};
</script>

<style lang="scss" scoped></style>
