<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="19.75"
      fill="#F8F8F8"
      stroke="#CDCDCD"
      stroke-width="0.5"
    />
    <g clip-path="url(#clip0_352:4424)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.287 19.4649C16.9384 19.4376 15.0375 17.507 15.0381 15.1492C15.0386 12.7219 17.0221 10.787 19.4605 10.8349C21.8021 10.8808 23.6975 12.8451 23.6665 15.1938C23.6348 17.5949 21.6892 19.4924 19.287 19.4649ZM21.9316 15.1227C21.9154 13.6978 20.7665 12.5603 19.3467 12.563C17.9259 12.5659 16.7816 13.7084 16.7724 15.1335C16.763 16.593 17.9421 17.7605 19.4005 17.7351C20.8197 17.7105 21.9481 16.5462 21.9316 15.1227Z"
        fill="#ABABAB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3742 21.1885C20.3502 21.1885 21.3267 21.1709 22.3023 21.192C24.8369 21.2466 26.6178 23.019 26.6823 25.5399C26.6964 26.0853 26.6923 26.6309 26.6807 27.1763C26.6691 27.7274 26.3402 28.0572 25.825 28.0585C25.3107 28.0599 24.9777 27.735 24.9645 27.1823C24.9515 26.6661 24.9688 26.1488 24.9564 25.632C24.9183 23.9993 23.8713 22.9342 22.2559 22.9199C20.3323 22.9028 18.4086 22.9047 16.485 22.9188C14.8159 22.9312 13.7748 23.9915 13.7464 25.6796C13.7383 26.1674 13.7456 26.6553 13.7423 27.1434C13.7388 27.6934 13.4299 28.0426 12.9161 28.0577C12.3542 28.0744 12.0159 27.7201 12.0221 27.1296C12.0307 26.3266 11.9669 25.5082 12.0994 24.7242C12.4391 22.7088 14.1177 21.2861 16.1872 21.2034C17.2477 21.1612 18.3113 21.1961 19.3737 21.1961C19.3742 21.1931 19.3742 21.1907 19.3742 21.1885Z"
        fill="#ABABAB"
      />
    </g>
    <defs>
      <clipPath id="clip0_352:4424">
        <rect
          width="17.2973"
          height="17.2973"
          fill="white"
          transform="translate(10.8105 10.8105)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AvatarIcon",
  props: {
    height: {
      required: false,
      default: 40
    },
    width: {
      required: false,
      default: 40
    }
  }
};
</script>

<style scoped></style>
