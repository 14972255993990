export const CASHBOOK_REQUEST = "CASHBOOK_REQUEST";

export const CASHBOOK_GET_ALL = "CASHBOOK_GET_ALL";
export const CASHBOOK_GET_ALL_SUCCESS = "CASHBOOK_GET_ALL_SUCCESS";

export const CASHBOOK_GET = "CASHBOOK_GET";
export const CASHBOOK_GET_SUCCESS = "CASHBOOK_GET_SUCCESS";

export const CASHBOOK_REGISTER = "CASHBOOK_REGISTER";
export const CASHBOOK_REGISTER_SUCCESS = "CASHBOOK_REGISTER_SUCCESS";

export const CASHBOOK_GET_BY_YEAR_MONTH = "CASHBOOK_GET_BY_YEAR_MONTH";
export const CASHBOOK_GET_BY_YEAR_MONTH_SUCCESS = "CASHBOOK_GET_BY_YEAR_MONTH_SUCCESS";

export const CASHBOOK_GET_PREVIOUS_MONTH = "CASHBOOK_GET_PREVIOUS_MONTH";
export const CASHBOOK_GET_PREVIOUS_MONTH_SUCCESS = "CASHBOOK_GET_PREVIOUS_MONTH_SUCCESS";

export const CASHBOOK_UPDATE = "CASHBOOK_UPDATE";
export const CASHBOOK_UPDATE_SUCCESS = "CASHBOOK_UPDATE_SUCCESS";

export const CASHBOOK_DELETE = "CASHBOOK_DELETE";
export const CASHBOOK_DELETE_SUCCESS = "CASHBOOK_DELETE_SUCCESS";