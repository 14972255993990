export const SHOP_REQUEST = "SHOP_REQUEST";

export const SHOP_GET_ALL = "SHOP_GET_ALL";
export const SHOP_GET_ALL_SUCCESS = "SHOP_GET_ALL_SUCCESS";

export const ALL_SHOP_GET = "ALL_SHOP_GET";
export const ALL_SHOP_GET_SUCCESS = "ALL_SHOP_GET_SUCCESS";

export const SHOP_REGISTER = "SHOP_REGISTER";
export const SHOP_REGISTER_SUCCESS = "SHOP_REGISTER_SUCCESS";

export const SHOP_DELETE = "SHOP_DELETE";
export const SHOP_DELETE_SUCCESS = "SHOP_DELETE_SUCCESS";

export const SHOP_GET = "SHOP_GET";
export const SHOP_GET_SUCCESS = "SHOP_GET_SUCCESS";

export const SHOP_REGISTER_SHARED_ACCOUNT = "SHOP_REGISTER_SHARED_ACCOUNT";
export const SHOP_REGISTER_SHARED_ACCOUNT_SUCCESS =
  "SHOP_REGISTER_SHARED_ACCOUNT_SUCCESS";

export const SHOP_UPDATE_SHARED_ACCOUNT = "SHOP_UPDATE_SHARED_ACCOUNT";
export const SHOP_UPDATE_SHARED_ACCOUNT_SUCCESS =
  "SHOP_UPDATE_SHARED_ACCOUNT_SUCCESS";

export const FIRST_SHOP_OBJECT = "FIRST_SHOP_OBJECT";

export const SHOP_UPDATE = "SHOP_UPDATE";
export const SHOP_UPDATE_SUCCESS = "SHOP_UPDATE_SUCCESS";

export const SHOP_INVITE_USER = "SHOP_INVITE_USER";
export const SHOP_INVITE_USER_SUCCESS = "SHOP_INVITE_USER_SUCCESS";

export const SHOP_USER_REGISTER = "SHOP_USER_REGISTER";
export const SHOP_USER_REGISTER_SUCCESS = "SHOP_USER_REGISTER_SUCCESS";
