import axios from "@/services/api"; 
import {
  MAILINGLIST_REQUEST,
  MAILINGLIST_GET_ALL,
  MAILINGLIST_GET_ALL_SUCCESS,
  MAILINGLIST_REGISTER,
  MAILINGLIST_REGISTER_SUCCESS,
  MAILINGLIST_GET,
  MAILINGLIST_GET_SUCCESS,
  MAILINGLIST_UPDATE,
  MAILINGLIST_UPDATE_SUCCESS,
  MAILINGLIST_DELETE,
  MAILINGLIST_DELETE_SUCCESS
} from "@/store/actions/mailinglist";

function initialState() {
  return {
    mailinglists: [],
    mailinglist: {}
  };
}

const state = initialState();

const getters = {
  getMailingLists: state => state.mailinglists,
  getMailingList: state => state.mailinglist
};

const actions = {
  [MAILINGLIST_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("MAILINGLIST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/mailing-lists`)
        .then(response => {
          commit("MAILINGLIST_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [MAILINGLIST_REGISTER]:({ commit, dispatch },{params,shop_id})=>{
    return new Promise((resolve,reject) => {
      commit('MAILINGLIST_REQUEST');
      dispatch('API_PROCESSING', true, { root: true });
      axios
        .post(`/user/shops/${shop_id}/mailing-lists`, params)
        .then(response => {
          commit("MAILINGLIST_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    })
  },
  [MAILINGLIST_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("MAILINGLIST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/mailing-lists/${params.id}`)
        .then(response => {
          commit("MAILINGLIST_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [MAILINGLIST_UPDATE]: ({ commit, dispatch }, {params,shop_id,mailing_list_id}) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/shops/${shop_id}/mailing-lists/${mailing_list_id}`, params)
        .then(response => {
          commit("MAILINGLIST_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [MAILINGLIST_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SUPPLIER_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/user/shops/${params.shop_id}/mailing-lists/${params.mailing_list_id}`)
        .then(response => {
          commit("MAILINGLIST_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
};

const mutations = {
  [MAILINGLIST_REQUEST]: state => {
    state.status = "loading";
  },

  [MAILINGLIST_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.mailinglists = params.data.mailing_list;
  },

  [MAILINGLIST_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [MAILINGLIST_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.mailinglist = params.data.mailing_list;
  },

  [MAILINGLIST_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [MAILINGLIST_DELETE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
