import axios from "@/services/api"; 
import {
    CASHSALES_REQUEST,
    CASHSALES_GET_ALL,
    CASHSALES_GET_ALL_SUCCESS,
    CASHSALES_GET_BY_YEAR_MONTH,
    CASHSALES_GET_BY_YEAR_MONTHL_SUCCESS
} from "@/store/actions/cash-sales";

function initialState() {
  return {
    cashsales: [],
    cashsalesByYearMonth: {}
  };
}

const state = initialState();

const getters = {
  getCashSales: state => state.cashsales,
  getCashsalesByYearMonth: state => state.cashsalesByYearMonth,
};

const actions = {
  [CASHSALES_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("CASHSALES_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/daily-cash-sales`)
        .then(response => {
          commit("CASHSALES_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [CASHSALES_GET_BY_YEAR_MONTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("BUSINESS_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(
          `/user/shops/${params.shop_id}/daily-cash-sales?year=${params.year}&month=${params.month}`
        )
        .then(response => {
          commit("CASHSALES_GET_BY_YEAR_MONTHL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
};

const mutations = {
  [CASHSALES_REQUEST]: state => {
    state.status = "loading";
  },

  [CASHSALES_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.cashsales = params.data.daily_cash_sales;
  },
  [CASHSALES_GET_BY_YEAR_MONTHL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.cashsalesByYearMonth = params.data.daily_cash_sales;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
