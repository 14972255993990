<template>
  <svg
    style="width: 55px; height: 30px;"
    width="50"
    height="25"
    viewBox="0 0 43 21"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3032 0.173594C18.5981 0.671709 17.3912 1.90742 16.9505 3.60292C16.3183 6.02644 17.8893 8.57449 20.4182 9.23545C23.7038 10.088 26.9033 6.88856 26.0507 3.60292C25.3802 1.03572 22.7459 -0.54484 20.3032 0.173594Z"
      fill=""
    />
    <path
      d="M17.146 11.0687C16.2725 11.1897 15.1591 11.924 14.7565 12.6583C14.688 12.7954 13.5575 15.208 12.2556 18.0159C11 21 11.447 20.1577 11 21C12.2556 20.9529 11 20.9529 12.2556 20.9529C13.2352 21 12.2291 20.9529 15.4845 20.9529C15.4417 21.0013 16.4181 19.0164 15.4845 20.9529C15.5273 20.9126 15.237 10.867 15.3141 20.953L15.4845 20.9529C18.7399 20.9529 17.6213 20.9529 20.0837 20.9529H21.4222H22.379C26.8053 20.9529 22.379 20.9529 26.8053 20.9529H26.9971H27.8703C29.7018 20.9529 28.8819 20.9529 29.6434 20.9529C30.223 20.9529 28.617 20.9529 30.5091 20.9529C31.3162 20.9529 31.1171 20.9529 29.993 18.0078C28.6912 15.1918 27.5349 12.7389 27.4236 12.5614C26.9868 11.8675 26.1047 11.2624 25.2396 11.0768C24.8028 10.98 17.8227 10.9719 17.146 11.0687Z"
      fill=""
    />
    <path
      d="M1.04479 2.40112C0.946604 2.47332 0.84522 2.64012 0.823474 2.7678C0.799346 2.89922 1.59301 8.07069 1.6712 8.31075C1.88111 8.94733 2.4173 9.36244 3.02423 9.35332L3.27739 9.34807L3.27859 9.45208C3.2785 9.50922 3.37714 11.3257 3.49645 13.4886C3.73013 17.7243 3.73696 17.8073 3.91306 18.1466C4.02573 18.3612 4.28948 18.6366 4.49291 18.7499C5.46563 19.2963 6.55951 18.4193 6.38048 17.2335C6.35313 17.0361 4.47574 9.59435 4.37529 9.26696C4.34777 9.1838 4.35065 9.18336 4.58508 9.11378C5.00877 8.98826 5.32672 8.67396 5.48011 8.23032C5.61748 7.83619 5.62114 7.87931 5.16712 4.9209C4.72216 2.0216 4.74506 2.13235 4.55001 1.97744C4.27463 1.76092 3.91881 1.88611 3.81816 2.23091L3.77347 2.38228L4.02493 4.02074L4.27588 5.65591L4.03064 5.69354L3.78541 5.73118L3.5395 4.12884C3.27861 2.44815 3.26125 2.37351 3.10961 2.23211C3.0166 2.14556 2.8265 2.08063 2.71975 2.09701C2.59569 2.11605 2.40372 2.26986 2.34873 2.39257C2.32693 2.44297 2.30692 2.5435 2.30358 2.61795C2.29974 2.68912 2.40273 3.45638 2.53475 4.31665L2.77562 5.88615L2.53039 5.92379L2.28515 5.96142L2.03219 4.31312L1.77973 2.6681L1.68593 2.53798C1.52898 2.32345 1.24165 2.26 1.04479 2.40112Z"
      fill=""
    />
    <path
      d="M41.1074 2.32562C40.4345 2.52491 39.4668 3.09377 38.9934 3.56852C38.5026 4.05762 38.1298 4.61581 37.8716 5.23922C37.7357 5.55838 37.729 5.60156 37.3878 7.82394L37.0409 10.0829L37.1222 10.3164C37.2794 10.7689 37.6314 11.0848 38.1527 11.2329C38.3912 11.3035 38.4156 11.3174 38.3916 11.375C38.3781 11.4137 37.9664 12.7412 37.4776 14.3255C36.6363 17.0729 36.5949 17.2196 36.6018 17.4451C36.6247 18.1082 37.0859 18.6721 37.7815 18.8911C38.6465 19.1633 39.5844 18.7395 39.8644 17.9494C39.9397 17.7298 39.9448 17.598 39.9618 14.7069C39.9713 13.0457 39.9764 11.6591 39.9715 11.6176C39.9654 11.5588 39.9864 11.5451 40.0891 11.5404C40.1571 11.5407 40.2738 11.494 40.3505 11.4377L40.4938 11.3407L41.1636 6.97903L41.8334 2.61732L41.7696 2.51572C41.6937 2.39525 41.5435 2.29058 41.4217 2.27187C41.37 2.26394 41.2305 2.28672 41.1074 2.32562Z"
      fill=""
    />
  </svg>
</template>

<script>
export default {
  name: "InStoreCustomerIcon",
  props: {}
};
</script>

<style lang="scss" scoped></style>
