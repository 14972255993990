export const INVENTORY_REQUEST = "INVENTORY_REQUEST";

export const INVENTORY_GET_ALL = "INVENTORY_GET_ALL";
export const INVENTORY_GET_ALL_SUCCESS = "INVENTORY_GET_ALL_SUCCESS";

export const INVENTORY_REGISTER = "INVENTORY_REGISTER";
export const INVENTORY_REGISTER_SUCCESS = "INVENTORY_REGISTER_SUCCESS";

export const INVENTORY_GET_BY_THIS_MONTH = "INVENTORY_GET_BY_THIS_MONTH";
export const INVENTORY_GET_BY_THIS_MONTH_SUCCESS = "INVENTORY_GET_BY_THIS_MONTH_SUCCESS";

export const INVENTORY_GET_BY_PRE_MONTH = "INVENTORY_GET_BY_PRE_MONTH";
export const INVENTORY_GET_BY_PRE_MONTH_SUCCESS = "INVENTORY_GET_BY_PRE_MONTH_SUCCESS";

export const INVENTORY_GET_PREVIOUS_MONTH = "INVENTORY_GET_PREVIOUS_MONTH";
export const INVENTORY_GET_PREVIOUS_MONTH_SUCCESS = "INVENTORY_GET_PREVIOUS_MONTH_SUCCESS";

export const INVENTORY_UPDATE = "INVENTORY_UPDATE";
export const INVENTORY_UPDATE_SUCCESS = "INVENTORY_UPDATE_SUCCESS";

export const INVENTORY_DELETE = "INVENTORY_DELETE";
export const INVENTORY_DELETE_SUCCESS = "INVENTORY_DELETE_SUCCESS";