<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 11 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1L5.5 5L1 1"
      stroke="#7689B0"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AvatarIcon",
  props: {
    height: {
      required: false,
      default: 6
    },
    width: {
      required: false,
      default: 11
    }
  }
};
</script>

<style scoped></style>
