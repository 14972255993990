<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :height="options.height"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    content-class="mx-3"
    overlay-color="rgb(8, 18, 75)"
    overlay-opacity="0.2"
  >
    <v-card
      :height="options.height"
      :max-width="options.width"
      class="d-flex flex-column justify-center "
    >
      <v-card-text
        v-show="!!message"
        class="font-weight-bold primary--text text-center account-edit-message-text"
        ><div v-if="checkMessage">
          <div>このユーザーを店舗管理メンバーから</div>
          <div>除外しますか？</div>
          <div class="fw-400 text-85">
            <div>※招待することで</div>
            <div>メンバーへの再追加が可能です</div>
          </div>
        </div>
        <span v-else>{{ message }}</span>
      </v-card-text>
      <div class="d-flex align-center justify-space-around mt-6">
        <v-btn
          elevation="3"
          outlined
          color="white"
          class="primary--text btn-blue-outline"
          @click.native="cancel"
          width="140px"
          >{{ options.cancelLable }}</v-btn
        >
        <v-btn
          color="primary"
          width="140px"
          @click.native="agree"
          elevation="3"
          >{{ options.actionLable }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 360,
      zIndex: 250,
      height: 240,
      cancelLable: "キャンセル",
      actionLable: "削除する"
    }
  }),
  computed: {
    checkMessage() {
      if (this.message) {
        if (this.message.includes("このユーザーを店舗管理メンバーから")) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    open(message, options) {
      this.dialog = true;

      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.account-edit-message-text {
  white-space: pre-wrap;
}
</style>
