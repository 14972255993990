import axios from "@/services/api";
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_GET,
  NOTIFICATION_GET_SUCCESS,
  NOTIFICATION_GET_ERROR,
  NOTIFICATION_GET_DETAIL,
  NOTIFICATION_GET_DETAIL_SUCCESS,
  NOTIFICATION_GET_DETAIL_ERROR,
  NOTIFICATION_LIST_TITLE,
  NOTIFICATION_READ_STATUS_UPDATE,
  NOTIFICATION_READ_STATUS_UPDATE_SUCCESS,
  NOTIFICATION_READ_STATUS_UPDATE_ERROR,
  NOTIFICATION_UNREAD_COUNT_GET,
  NOTIFICATION_UNREAD_COUNT_GET_SUCCESS,
  NOTIFICATION_UNREAD_COUNT_GET_ERROR
} from "@/store/actions/notification/notification";

function initializeState() {
  return {
    notifications: [],
    pagination: {},
    notificationDetails: {},
    relatedPosts: [],
    notificationListTitle: "",
    unreadCount: 0
  };
}

const state = initializeState();

const getters = {
  getNotifications: state => state.notifications,
  getNotificationsPagination: state => state.pagination,
  getNotification: state => state.notificationDetails,
  getNotificationRelatedPosts: state => state.relatedPosts,
  getNotificationListTitle: state => state.notificationListTitle,
  getNotificationUnreadCount: state => state.unreadCount
};

const actions = {
  [NOTIFICATION_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("NOTIFICATION_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/notifications`, params)
        .then(response => {
          commit("NOTIFICATION_GET_SUCCESS", {
            notifications: response.data.data.notification,
            pagination: response.data.paginate
          });

          resolve(response);
        })
        .catch(error => {
          commit("NOTIFICATION_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [NOTIFICATION_GET_DETAIL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("NOTIFICATION_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/notifications/${params.id}`, params)
        .then(response => {
          commit("NOTIFICATION_GET_DETAIL_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("NOTIFICATION_GET_DETAIL_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [NOTIFICATION_READ_STATUS_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("NOTIFICATION_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/read-notification`, params)
        .then(response => {
          commit("NOTIFICATION_READ_STATUS_UPDATE_SUCCESS", response.data);

          resolve(response);
        })
        .catch(error => {
          commit("NOTIFICATION_READ_STATUS_UPDATE_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [NOTIFICATION_UNREAD_COUNT_GET]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit("NOTIFICATION_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/unread-notification`, {
          params: {
            npr: 1
          }
        })
        .then(response => {
          commit("NOTIFICATION_UNREAD_COUNT_GET_SUCCESS", response.data.data);

          resolve(response);
        })
        .catch(error => {
          commit("NOTIFICATION_UNREAD_COUNT_GET_ERROR");

          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [NOTIFICATION_REQUEST]: state => {
    state.status = "loading";
  },

  [NOTIFICATION_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.notifications = params.notifications;
    state.pagination = params.pagination;
  },

  [NOTIFICATION_GET_ERROR]: state => {
    state.status = "error";
  },

  [NOTIFICATION_GET_DETAIL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.notificationDetails = params.data.notification;
    state.relatedPosts = params.data.related_posts;
  },

  [NOTIFICATION_GET_DETAIL_ERROR]: state => {
    state.status = "error";
  },

  [NOTIFICATION_LIST_TITLE]: (state, params) => {
    state.notificationListTitle = params.notificationListTitle;
  },

  [NOTIFICATION_READ_STATUS_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [NOTIFICATION_READ_STATUS_UPDATE_ERROR]: state => {
    state.status = "error";
  },

  [NOTIFICATION_UNREAD_COUNT_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.unreadCount = params.unreadNotification;
  },

  [NOTIFICATION_UNREAD_COUNT_GET_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
