<template>
  <svg
    style="width:95px; height:23px;"
    width="95"
    height="23"
    viewBox="0 0 95 23"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_13_27"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="95"
      height="23"
    >
      <rect width="95" height="23" rx="5" fill="#F4F8FC" />
    </mask>
    <g mask="url(#mask0_13_27)">
      <path
        d="M9.27657 2.92877C8.96597 3.00979 8.73303 3.14821 8.50008 3.38116C8.24687 3.63774 8.11183 3.88419 8.04093 4.23193C7.83499 5.22449 8.63174 6.18329 9.66819 6.18329C10.5764 6.18329 11.3157 5.43718 11.3123 4.52902C11.309 4.07663 11.1435 3.68838 10.8161 3.36428C10.4008 2.9524 9.80999 2.78697 9.27657 2.92877Z"
        fill=""
      />
      <path
        d="M8.87109 6.64161C8.46596 6.74627 8.29041 6.87119 7.72323 7.44512C6.91297 8.27225 6.34917 8.93396 5.81575 9.67669C5.48827 10.1358 5.42075 10.281 5.42413 10.541C5.42413 10.7942 5.50853 10.99 5.69084 11.152C5.87652 11.3208 6.0352 11.3816 6.2749 11.3816C6.63951 11.3816 6.83195 11.2466 7.20331 10.7233C7.30797 10.5747 7.51729 10.2979 7.66921 10.1088C7.91566 9.79486 7.94267 9.77122 7.94942 9.85562C7.95618 9.90627 7.97306 10.6659 7.99331 11.5437C8.01694 12.6983 8.01357 13.1912 7.98656 13.3161C7.9663 13.414 7.86165 13.6706 7.75361 13.8866C7.09866 15.1932 6.55849 16.5774 6.01832 18.3194C5.75498 19.1668 5.73135 19.3491 5.86639 19.6293C6.00819 19.9298 6.36943 20.1594 6.6969 20.1594C6.86908 20.1594 7.13917 20.0581 7.28096 19.9332C7.45652 19.7846 7.51391 19.6563 7.77049 18.8258C8.23976 17.3133 8.63476 16.2701 9.17831 15.1358C9.3775 14.7138 9.41801 14.653 9.48215 14.6631C9.5463 14.6733 9.64421 14.94 10.3532 17.0433C11.231 19.6428 11.2073 19.5922 11.5416 19.7644C11.7036 19.8488 11.7509 19.8589 11.9636 19.8454C12.5307 19.8116 12.9426 19.3795 12.8751 18.8832C12.865 18.7988 12.47 17.5834 11.9973 16.179C11.528 14.7745 11.1162 13.5187 11.0892 13.3904C10.998 12.9886 10.9001 11.4052 10.8967 10.3418V9.92315L11.1094 10.1156C11.7407 10.6861 12.5037 11.2634 13.1924 11.6888C13.7664 12.0433 13.9487 12.0872 14.2323 11.9488C14.3876 11.8745 14.5598 11.6686 14.634 11.4728C14.7015 11.2905 14.688 10.8955 14.6104 10.73C14.499 10.5005 14.418 10.4262 13.8845 10.0818C12.7434 9.33909 11.9433 8.60648 11.0115 7.44849C10.5929 6.93196 10.4139 6.78678 10.0493 6.672C9.82651 6.60448 9.7151 6.5876 9.41801 6.59097C9.21882 6.59097 8.97237 6.61461 8.87109 6.64161Z"
        fill=""
      />
      <path
        d="M24.6971 2.92877C24.3865 3.00979 24.1536 3.14821 23.9206 3.38116C23.6674 3.63774 23.5324 3.88419 23.4615 4.23193C23.2555 5.22449 24.0523 6.18329 25.0887 6.18329C25.9969 6.18329 26.7362 5.43718 26.7329 4.52902C26.7295 4.07663 26.5641 3.68838 26.2366 3.36428C25.8213 2.9524 25.2305 2.78697 24.6971 2.92877Z"
        fill=""
      />
      <path
        d="M24.2913 6.64161C23.8862 6.74627 23.7106 6.87119 23.1435 7.44512C22.3332 8.27225 21.7694 8.93396 21.236 9.67669C20.9085 10.1358 20.841 10.281 20.8444 10.541C20.8444 10.7942 20.9288 10.99 21.1111 11.152C21.2967 11.3208 21.4554 11.3816 21.6951 11.3816C22.0597 11.3816 22.2522 11.2466 22.6235 10.7233C22.7282 10.5747 22.9375 10.2979 23.0894 10.1088C23.3359 9.79486 23.3629 9.77122 23.3697 9.85562C23.3764 9.90627 23.3933 10.6659 23.4135 11.5437C23.4372 12.6983 23.4338 13.1912 23.4068 13.3161C23.3865 13.414 23.2819 13.6706 23.1738 13.8866C22.5189 15.1932 21.9787 16.5774 21.4385 18.3194C21.1752 19.1668 21.1516 19.3491 21.2866 19.6293C21.4284 19.9298 21.7897 20.1594 22.1171 20.1594C22.2893 20.1594 22.5594 20.0581 22.7012 19.9332C22.8767 19.7846 22.9341 19.6563 23.1907 18.8258C23.66 17.3133 24.055 16.2701 24.5985 15.1358C24.7977 14.7138 24.8382 14.653 24.9024 14.6631C24.9665 14.6733 25.0644 14.94 25.7734 17.0433C26.6512 19.6428 26.6275 19.5922 26.9618 19.7644C27.1238 19.8488 27.1711 19.8589 27.3838 19.8454C27.951 19.8116 28.3628 19.3795 28.2953 18.8832C28.2852 18.7988 27.8902 17.5834 27.4175 16.179C26.9483 14.7745 26.5364 13.5187 26.5094 13.3904C26.4182 12.9886 26.3203 11.4052 26.317 10.3418V9.92315L26.5296 10.1156C27.161 10.6861 27.924 11.2634 28.6127 11.6888C29.1866 12.0433 29.3689 12.0872 29.6525 11.9488C29.8078 11.8745 29.98 11.6686 30.0543 11.4728C30.1218 11.2905 30.1083 10.8955 30.0306 10.73C29.9192 10.5005 29.8382 10.4262 29.3048 10.0818C28.1637 9.33909 27.3635 8.60648 26.4317 7.44849C26.0131 6.93196 25.8342 6.78678 25.4696 6.672C25.2467 6.60448 25.1353 6.5876 24.8382 6.59097C24.639 6.59097 24.3926 6.61461 24.2913 6.64161Z"
        fill=""
      />
      <path
        d="M40.1204 2.92877C39.8098 3.00979 39.5768 3.14821 39.3439 3.38116C39.0907 3.63774 38.9556 3.88419 38.8847 4.23193C38.6788 5.22449 39.4755 6.18329 40.512 6.18329C41.4201 6.18329 42.1595 5.43718 42.1561 4.52902C42.1527 4.07663 41.9873 3.68838 41.6598 3.36428C41.2446 2.9524 40.6538 2.78697 40.1204 2.92877Z"
        fill=""
      />
      <path
        d="M39.7154 6.64161C39.3102 6.74627 39.1347 6.87119 38.5675 7.44512C37.7572 8.27225 37.1934 8.93396 36.66 9.67669C36.3325 10.1358 36.265 10.281 36.2684 10.541C36.2684 10.7942 36.3528 10.99 36.5351 11.152C36.7208 11.3208 36.8795 11.3816 37.1192 11.3816C37.4838 11.3816 37.6762 11.2466 38.0476 10.7233C38.1522 10.5747 38.3616 10.2979 38.5135 10.1088C38.7599 9.79486 38.7869 9.77122 38.7937 9.85562C38.8004 9.90627 38.8173 10.6659 38.8376 11.5437C38.8612 12.6983 38.8578 13.1912 38.8308 13.3161C38.8106 13.414 38.7059 13.6706 38.5979 13.8866C37.9429 15.1932 37.4028 16.5774 36.8626 18.3194C36.5993 19.1668 36.5756 19.3491 36.7107 19.6293C36.8525 19.9298 37.2137 20.1594 37.5412 20.1594C37.7134 20.1594 37.9834 20.0581 38.1252 19.9332C38.3008 19.7846 38.3582 19.6563 38.6148 18.8258C39.084 17.3133 39.479 16.2701 40.0226 15.1358C40.2218 14.7138 40.2623 14.653 40.3264 14.6631C40.3906 14.6733 40.4885 14.94 41.1974 17.0433C42.0752 19.6428 42.0516 19.5922 42.3858 19.7644C42.5479 19.8488 42.5951 19.8589 42.8078 19.8454C43.375 19.8116 43.7869 19.3795 43.7194 18.8832C43.7092 18.7988 43.3142 17.5834 42.8416 16.179C42.3723 14.7745 41.9604 13.5187 41.9334 13.3904C41.8423 12.9886 41.7444 11.4052 41.741 10.3418V9.92315L41.9537 10.1156C42.585 10.6861 43.348 11.2634 44.0367 11.6888C44.6106 12.0433 44.793 12.0872 45.0765 11.9488C45.2318 11.8745 45.404 11.6686 45.4783 11.4728C45.5458 11.2905 45.5323 10.8955 45.4547 10.73C45.3433 10.5005 45.2622 10.4262 44.7288 10.0818C43.5877 9.33909 42.7876 8.60648 41.8558 7.44849C41.4371 6.93196 41.2582 6.78678 40.8936 6.672C40.6708 6.60448 40.5594 6.5876 40.2623 6.59097C40.0631 6.59097 39.8166 6.61461 39.7154 6.64161Z"
        fill=""
      />
      <path
        d="M55.5409 2.92877C55.2303 3.00979 54.9973 3.14821 54.7644 3.38116C54.5112 3.63774 54.3761 3.88419 54.3052 4.23193C54.0993 5.22449 54.896 6.18329 55.9325 6.18329C56.8406 6.18329 57.58 5.43718 57.5766 4.52902C57.5732 4.07663 57.4078 3.68838 57.0803 3.36428C56.6651 2.9524 56.0743 2.78697 55.5409 2.92877Z"
        fill=""
      />
      <path
        d="M55.1356 6.64161C54.7304 6.74627 54.5549 6.87119 53.9877 7.44512C53.1774 8.27225 52.6136 8.93396 52.0802 9.67669C51.7527 10.1358 51.6852 10.281 51.6886 10.541C51.6886 10.7942 51.773 10.99 51.9553 11.152C52.141 11.3208 52.2997 11.3816 52.5394 11.3816C52.904 11.3816 53.0964 11.2466 53.4678 10.7233C53.5724 10.5747 53.7818 10.2979 53.9337 10.1088C54.1801 9.79486 54.2071 9.77122 54.2139 9.85562C54.2206 9.90627 54.2375 10.6659 54.2578 11.5437C54.2814 12.6983 54.278 13.1912 54.251 13.3161C54.2308 13.414 54.1261 13.6706 54.0181 13.8866C53.3631 15.1932 52.823 16.5774 52.2828 18.3194C52.0194 19.1668 51.9958 19.3491 52.1309 19.6293C52.2727 19.9298 52.6339 20.1594 52.9614 20.1594C53.1335 20.1594 53.4036 20.0581 53.5454 19.9332C53.721 19.7846 53.7784 19.6563 54.035 18.8258C54.5042 17.3133 54.8992 16.2701 55.4428 15.1358C55.642 14.7138 55.6825 14.653 55.7466 14.6631C55.8108 14.6733 55.9087 14.94 56.6176 17.0433C57.4954 19.6428 57.4718 19.5922 57.806 19.7644C57.9681 19.8488 58.0153 19.8589 58.228 19.8454C58.7952 19.8116 59.2071 19.3795 59.1396 18.8832C59.1294 18.7988 58.7344 17.5834 58.2618 16.179C57.7925 14.7745 57.3806 13.5187 57.3536 13.3904C57.2625 12.9886 57.1646 11.4052 57.1612 10.3418V9.92315L57.3739 10.1156C58.0052 10.6861 58.7682 11.2634 59.4569 11.6888C60.0308 12.0433 60.2131 12.0872 60.4967 11.9488C60.652 11.8745 60.8242 11.6686 60.8985 11.4728C60.966 11.2905 60.9525 10.8955 60.8749 10.73C60.7634 10.5005 60.6824 10.4262 60.149 10.0818C59.0079 9.33909 58.2078 8.60648 57.276 7.44849C56.8573 6.93196 56.6784 6.78678 56.3138 6.672C56.091 6.60448 55.9796 6.5876 55.6825 6.59097C55.4833 6.59097 55.2368 6.61461 55.1356 6.64161Z"
        fill=""
      />
    </g>
    <path
      d="M69.2022 2.04805C68.8413 2.15977 68.5448 2.40898 68.3815 2.72266C68.3257 2.84297 68.0077 4.01172 67.6296 5.51992C67.0022 8.0293 66.9808 8.11953 67.0065 8.4418C67.1268 10.1219 68.4331 11.484 70.0272 11.5957C70.964 11.6645 71.862 11.3121 72.5452 10.6117L72.8331 10.3109L73.1253 10.6074C74.4186 11.9395 76.4038 11.9395 77.7015 10.6074L77.9893 10.3109L78.2815 10.6074C79.5749 11.9395 81.56 11.9395 82.8577 10.6074L83.1456 10.3109L83.4378 10.6117C84.1167 11.3121 85.0147 11.6645 85.9515 11.5957C87.5456 11.484 88.8518 10.1219 88.9722 8.4418C88.9979 8.11953 88.9765 8.0293 88.3491 5.51992C87.971 4.01172 87.653 2.84297 87.5972 2.72266C87.4725 2.47773 87.2018 2.22422 86.9311 2.09961L86.7335 2.00937L78.0538 2.00078C71.1616 1.99648 69.3397 2.00508 69.2022 2.04805Z"
      fill=""
    />
    <path
      d="M72.5538 12.352C72.2745 12.5281 71.7202 12.7516 71.3077 12.859C70.457 13.0781 69.3398 12.9836 68.5234 12.627L68.3644 12.5582L68.373 16.3695L68.3859 20.1852L68.4976 20.4258C68.6394 20.7309 68.8714 20.9629 69.1765 21.1047L69.4171 21.2164L72.3304 21.2293L75.2394 21.2422V18.4879V15.7379H77.9894H80.7394V18.4879V21.2422L83.6527 21.2293L86.5616 21.2164L86.8023 21.1047C87.1073 20.9629 87.3394 20.7309 87.4812 20.4258L87.5929 20.1852L87.6058 16.3695L87.6144 12.5582L87.4554 12.627C86.8882 12.8762 86.0417 13.018 85.4015 12.9707C84.7441 12.9277 83.9749 12.6871 83.4421 12.3605C83.3089 12.2832 83.1757 12.2145 83.1456 12.2145C83.1198 12.2145 82.9866 12.2789 82.8534 12.3563C81.5085 13.1426 79.6265 13.1426 78.2816 12.3563C78.1484 12.2789 78.0152 12.2145 77.9894 12.2145C77.9636 12.2145 77.8304 12.2789 77.6972 12.3563C76.3523 13.1426 74.4702 13.1426 73.1253 12.3563C72.9921 12.2789 72.8589 12.2145 72.8245 12.2145C72.7945 12.2145 72.6741 12.2789 72.5538 12.352Z"
      fill=""
    />
  </svg>
</template>

<script>
export default {
  name: "GroupsInStoreIcon"
};
</script>

<style lang="scss" scoped></style>
