import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import SwapVertical from "@/components/icons/SwapVertical";
import QuestionMarkIcon from "@/components/icons/QuestionMarkIcon";
import PlayIcon from "@/components/icons/PlayIcon";
import FlagIcon from "@/components/icons/FlagIcon";
import RightArrowIcon from "@/components/icons/RightArrowIcon";
import NewTabIcon from "@/components/icons/NewTabIcon";
import BlueDownArrowIcon from "@/components/icons/BlueDownArrowIcon";
import ReloadIcon from "@/components/icons/ReloadIcon";
import GrayRightArrowIcon from "@/components/icons/GrayRightArrowIcon";
import BackButtonIcon from "@/components/icons/BackButtonIcon";
import GrayDownArrowIcon from "@/components/icons/GrayDownArrowIcon";
import CircleCheckedIcon from "@/components/icons/CircleCheckedIcon";
import AvatarIcon from "@/components/icons/AvatarIcon";
import PhoneIcon from "@/components/icons/PhoneIcon";
import EditIcon from "@/components/icons/EditIcon";
import ArrowDown from "@/components/icons/ArrowDown";
import InfoIcon from "@/components/icons/InfoIcon";
import CloseIcon from "@/components/icons/CloseIcon";
import ClearIcon from "@/components/icons/ClearIcon";
import GrayDownArrowClosedIcon from "@/components/icons/GrayDownArrowClosedIcon";
import SunIcon from "@/components/icons/SunIcon";
import SunAlt from "@/components/icons/SunAlt";
import MoonIcon from "@/components/icons/MoonIcon";
import CloudIcon from "@/components/icons/CloudIcon";
import PrinterIcon from "@/components/icons/PrinterIcon";
import UmbrellaIcon from "@/components/icons/UmbrellaIcon";
import EditIconGray from "@/components/icons/EditIconGray";
import WhiteCloudIcon from "@/components/icons/WhiteCloudIcon";
import WhiteSunIcon from "@/components/icons/WhiteSunIcon";
import WhiteArrowLeft from "@/components/icons/WhiteArrowLeftIcon";
import WarningIcon from "@/components/icons/WarningIcon";
import GroupsInStoreIcon from "@/components/icons/GroupsInStoreIcon";
import LunchMealIcon from "@/components/icons/LunchMealIcon";
import DinnerMealIcon from "@/components/icons/DinnerMealIcon";
import CloudyIcon from "@/components/icons/CloudyIcon";
import CalenderIcon from "@/components/icons/CalenderIcon";
import SalesIcon from "@/components/icons/SalesIcon";
import GrowthIcon from "@/components/icons/GrowthIcon";
import CustomerIcon from "@/components/icons/CustomerIcon";
import DiningIcon from "@/components/icons/DiningIcon";
import TeaIcon from "@/components/icons/TeaIcon";
import TakeAwayWithDrink from "@/components/icons/TakeAwayWithDrink";
import DeliveryIcon from "@/components/icons/DeliveryIcon";
import TakeAwayIcon from "@/components/icons/TakeAwayIcon";
import TotalCustomers from "@/components/icons/TotalCustomers";
import CustomerUnitPriceIcon from "@/components/icons/CustomerUnitPriceIcon";
import InStoreCustomerUnitIcon from "@/components/icons/InStoreCustomerUnitIcon";
import PairIcon from "@/components/icons/PairIcon";
import GroupIcon from "@/components/icons/GroupIcon";
import DropDownIcon from "@/components/icons/DropDownIcon";
import CustomerStoreIcon from "@/components/icons/CustomerStoreIcon";
import CustomerUnitDinnerIcon from "@/components/icons/CustomerUnitDinnerIcon";
import GoNextIcon from "@/components/icons/GoNextIcon";
import CarretDownIcon from "@/components/icons/CarretDownIcon";
import SunnyIcon from "@/components/icons/SunnyIcon";
import CancelIcon from "@/components/icons/CancelIcon";
import CustomersInStoreIcon from "@/components/icons/CustomersInStoreIcon";
import InStoreCustomerIcon from "@/components/icons/InStoreCustomerIcon";
import SingleCustomerIcon from "@/components/icons/SingleCustomerIcon";
import PairCustomersIcon from "@/components/icons/PairCustomersIcon";
import MultipleCustomersIcon from "@/components/icons/MultipleCustomersIcon";
import CloseView from "@/components/icons/CloseView";
import AverageSalesIcon from "@/components/icons/AverageSalesIcon";
import AllCustomersIcon from "@/components/icons/AllCustomersIcon";
import AllCustomersInStore from "@/components/icons/AllCustomersInStore";
import GroupsIcon from "@/components/icons/GroupsIcon";
import InstoreCustomerSpendIcon from "@/components/icons/InstoreCustomerSpendIcon";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#495BC4",
        secondary: "#0094FF",
        accent: "#69E3FF",
        background: "#EAEDF0"
      }
    }
  },
  icons: {
    values: {
      question: {
        component: QuestionMarkIcon
      },
      SwapVertical: {
        component: SwapVertical
      },
      play: {
        component: PlayIcon
      },
      flag: {
        component: FlagIcon
      },
      rightArrow: {
        component: RightArrowIcon
      },
      grayRightArrow: {
        component: GrayRightArrowIcon
      },
      newTab: {
        component: NewTabIcon
      },
      blueDownArrow: {
        component: BlueDownArrowIcon
      },
      grayDownArrow: {
        component: GrayDownArrowIcon
      },
      reload: {
        component: ReloadIcon
      },
      backButton: {
        component: BackButtonIcon
      },
      circleChecked: {
        component: CircleCheckedIcon
      },
      edit: {
        component: EditIcon
      },
      editGray: {
        component: EditIconGray
      },
      phone: {
        component: PhoneIcon
      },
      avatar: {
        component: AvatarIcon
      },
      arrowDown: {
        component: ArrowDown
      },
      infoIcon: {
        component: InfoIcon
      },
      infoIconGrey: {
        component: InfoIcon,
        props: { fillBg: "#BDBDBD" }
      },
      closeIcon: {
        component: CloseIcon
      },

      clearIcon: {
        component: ClearIcon
      },
      grayDownArrowClosed: {
        component: GrayDownArrowClosedIcon
      },
      umbrella: {
        component: UmbrellaIcon
      },
      sun: {
        component: SunIcon
      },
      sunAlt: {
        component: SunAlt
      },
      moon: {
        component: MoonIcon
      },
      cloud: {
        component: CloudIcon
      },
      printer: {
        component: PrinterIcon
      },
      whiteCloud: {
        component: WhiteCloudIcon
      },
      whiteSun: {
        component: WhiteSunIcon
      },
      whiteArrowLeft: {
        component: WhiteArrowLeft
      },
      warning: {
        component: WarningIcon
      },
      groupsStore: {
        component: GroupsInStoreIcon,
        props: { fillBg: "#BDBDBD" }
      },
      lunch: {
        component: LunchMealIcon
      },
      dinner: {
        component: DinnerMealIcon
      },
      cloudy: {
        component: CloudyIcon
      },
      calendar: {
        component: CalenderIcon
      },
      sale: {
        component: SalesIcon
      },
      growth: {
        component: GrowthIcon
      },
      customer: {
        component: CustomerIcon
      },
      dining: {
        component: DiningIcon
      },
      tea: {
        component: TeaIcon
      },
      takeAwayWithDrink: {
        component: TakeAwayWithDrink
      },
      delivery: {
        component: DeliveryIcon
      },
      takeAway: {
        component: TakeAwayIcon
      },
      totalCustomers: {
        component: TotalCustomers
      },
      customerUnit: {
        component: CustomerUnitPriceIcon
      },
      inStoreCustomerUnit: {
        component: InStoreCustomerUnitIcon
      },
      pair: {
        component: PairIcon
      },
      group: {
        component: GroupIcon
      },
      dropDown: {
        component: DropDownIcon
      },
      customerStore: {
        component: CustomerStoreIcon
      },
      customerDinner: {
        component: CustomerUnitDinnerIcon
      },
      goNext: {
        component: GoNextIcon
      },
      carretDown: {
        component: CarretDownIcon
      },
      sunny: {
        component: SunnyIcon
      },
      cancel: {
        component: CancelIcon
      },
      customersInStore: {
        component: CustomersInStoreIcon
      },
      groupsInStore: {
        component: GroupsInStoreIcon
      },
      instoreCustomer: {
        component: InStoreCustomerIcon
      },
      singleCustomer: {
        component: SingleCustomerIcon
      },
      pairCustomers: {
        component: PairCustomersIcon
      },
      multipleCustomers: {
        component: MultipleCustomersIcon
      },
      closeView: {
        component: CloseView
      },
      averageSales: {
        component: AverageSalesIcon
      },
      allCustomers: {
        component: AllCustomersIcon
      },
      allCustomersInStore: {
        component: AllCustomersInStore
      },
      groups: {
        component: GroupsIcon
      },
      instoreCustomerSpend: {
        component: InstoreCustomerSpendIcon
      }
    }
  }
};

export default new Vuetify(opts);
