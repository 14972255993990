import Vue from "vue";
import i18n from "@/plugins/i18n";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  max,
  min,
  size,
  numeric,
  min_value,
  confirmed
} from "vee-validate/dist/rules.umd";

extend("required", {
  ...required,
  message(value, placeholders) {
    if (placeholders.args && typeof placeholders.args != "undefined") {
      return (
        placeholders.args + i18n.t("field_required_message_with_field_name")
      );
    } else {
      return i18n.t("field_required_message");
    }
  },
  params: ["args"]
});

extend("max", {
  ...max,
  message: i18n.t("field_max_message")
});

extend("min", {
  ...min,
  message: i18n.t("field_min_message")
});

extend("numeric", {
  ...numeric,
  message: i18n.t("field_numeric_message")
});

extend("size", {
  ...size,
  message: i18n.t("field_size_message")
});

extend("email", {
  ...email,
  message: i18n.t("field_email_message")
});

extend("min_value", {
  ...min_value,
  message: i18n.t("field_min_value_message")
});

extend("password_confirmed", {
  ...confirmed,
  message: i18n.t("field_password_confirmed_message")
});

extend("url", {
  message: i18n.t("field_must_be_url_message"),
  validate: value => {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }

    return false;
  }
});

extend("verify_password", {
  message: i18n.t("field_verify_password_message"),
  validate: value => {
    // var strongRegex = new RegExp(
    //   "(?=.{8,})((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_])).*"
    // );

    var strongRegex = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$"
    );

    let length = value.length;
    return strongRegex.test(value) && length >= 8 && length <= 20;
  }
});

extend("only_english_lang_allowed", {
  message: i18n.t("field_only_english_char_allowed_message"),
  validate: value => {
    // validation allowing only english language
    var strongRegex = new RegExp(`[^\u0000-\u0080]+`);
    return !strongRegex.test(value);
  }
});

extend("enter_half_width_numbers_hyphens", {
  message: i18n.t("field_enter_half_width_numbers_hyphens"),
  validate: value => {
    // validation allowing only english language

    let regexElevenPhoneHyphen = /^\(?\d{3}\)?[- ]?(\d{4})[- ]?(\d{4})$|^\(?\d{3}\)?[- ]?(\d{3})[- ]?(\d{4})$|^\(?\d{2}\)?[- ]?(\d{4})[- ]?(\d{4})$/;
    let regexTenPhoneHyphen = /^(1-)?\d{3}-\d{3}-\d{4}$/;
    return regexElevenPhoneHyphen.test(value)
      ? true
      : regexTenPhoneHyphen.test(value); // Validate 10 or 11 digit phone number hyphens
  }
});

extend("enter_half_width_amount", {
  message: i18n.t("field_enter_half_width_amount"),
  validate: value => {
    // validation allowing only "￥" and ","
    let regexAllowChars = /[^￥,/0-9]/; 
    return !regexAllowChars.test(value) ? true : false;
  }
});

extend("dont_allow_sysmbol", {
  message: i18n.t("field_dont_allow_sysmbol"),
  validate: value => {
    // validation not allowing only "@"
    let regexAllowChars = /[@]/; 
    console.log(regexAllowChars.test(value));
    return !regexAllowChars.test(value) ? true : false;
  }
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
