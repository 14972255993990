import axios from "@/services/api";
import {
  SHOP_REQUEST,
  SHOP_GET_ALL,
  SHOP_GET_ALL_SUCCESS,
  SHOP_REGISTER,
  SHOP_REGISTER_SUCCESS,
  SHOP_DELETE,
  SHOP_DELETE_SUCCESS,
  SHOP_GET,
  SHOP_GET_SUCCESS,
  FIRST_SHOP_OBJECT,
  SHOP_REGISTER_SHARED_ACCOUNT,
  SHOP_REGISTER_SHARED_ACCOUNT_SUCCESS,
  SHOP_UPDATE_SHARED_ACCOUNT,
  SHOP_UPDATE_SHARED_ACCOUNT_SUCCESS,
  SHOP_UPDATE,
  SHOP_UPDATE_SUCCESS,
  SHOP_INVITE_USER,
  SHOP_INVITE_USER_SUCCESS,
  SHOP_USER_REGISTER,
  SHOP_USER_REGISTER_SUCCESS
} from "@/store/actions/shop";

function initialState() {
  return {
    shops: [],
    shop: {},
    allshops: [],
    firstShopObject: {}
  };
}

const state = initialState();

const getters = {
  getShops: state => state.shops,
  getAllShops: state => state.allshops,
  getShop: state => state.shop,
  getFirstShopObject: state => state.firstShopObject
};

const actions = {
  [SHOP_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops`, params)
        .then(response => {
          commit("SHOP_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_GET]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.id}`)
        .then(response => {
          commit("SHOP_GET_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_DELETE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .delete(`/user/shops/${params.id}`)
        .then(response => {
          commit("SHOP_DELETE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_REGISTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/shops`, params)
        .then(response => {
          commit("SHOP_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_UPDATE]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });

      axios
        .put(`/user/shops/${params.shop_id}`, params)
        .then(response => {
          commit("SHOP_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_REGISTER_SHARED_ACCOUNT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/shops/${params.shop_id}/shared-accounts`, params)
        .then(response => {
          commit("SHOP_REGISTER_SHARED_ACCOUNT_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_UPDATE_SHARED_ACCOUNT]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/shops/${params.shop_id}/shared-accounts`, params)
        .then(response => {
          commit("SHOP_UPDATE_SHARED_ACCOUNT_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_INVITE_USER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/user-shops/invitation`, params)
        .then(response => {
          commit("SHOP_INVITE_USER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },
  [SHOP_USER_REGISTER]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("SHOP_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .post(`/user/shops/${params.shop_id}/add-user`, params)
        .then(response => {
          commit("SHOP_USER_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [SHOP_REQUEST]: state => {
    state.status = "loading";
  },

  [SHOP_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.allshops = params.data.shops;

    state.shops = state.allshops.filter(shop => {
      return shop?.user_shop.find(userShop => {
        if (
          sessionStorage.getItem("user_id") == userShop.user_id &&
          userShop.shop_status == 1
        ) {
          return (shop.order = userShop.shop_display_order);
        }
      });
    });

    state.shops.sort((a, b) =>
      parseInt(a.order) > parseInt(b.order) ? 1 : -1
    );
    if (state.shops.length > 0) {
      state.firstShopObject = state.shops[0];
    } else {
      state.firstShopObject = { id: 0 };
    }
  },

  [SHOP_REGISTER_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_UPDATE_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_REGISTER_SHARED_ACCOUNT_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_UPDATE_SHARED_ACCOUNT_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_INVITE_USER_SUCCESS]: state => {
    state.status = "success";
  },

  [SHOP_GET_SUCCESS]: (state, params) => {
    state.status = "success";
    state.shop = params.data.shop;
  },

  [SHOP_DELETE_SUCCESS]: state => {
    state.status = "success";
  },

  [FIRST_SHOP_OBJECT]: (state, params) => {
    state.firstShopObject = params.shop;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
