<template>
  <svg
    style="width: 36px; height: 19px;"
    width="36"
    height="19"
    viewBox="0 0 36 19"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8495 0.103273C10.8317 0.400604 10.1112 1.13821 9.84821 2.15028C9.47083 3.59691 10.4086 5.11787 11.9181 5.51241C13.8793 6.0213 15.7891 4.11152 15.2802 2.15028C14.88 0.617884 13.3075 -0.325569 11.8495 0.103273Z"
      fill=""
    />
    <path
      d="M9.90558 5.74339C9.32236 5.82916 8.57903 6.34948 8.31029 6.86981C8.26455 6.96702 7.50978 8.67667 6.64066 10.6665C4.81094 14.8634 4.82237 14.8291 5.20547 15.4695C5.54855 16.047 6.19467 16.2815 6.84079 16.0642C7.32681 15.9041 7.5155 15.6296 8.17306 14.1201C8.48754 13.3939 8.76772 12.7821 8.79631 12.7478C8.8249 12.7192 8.84777 13.0223 8.84777 17.2364C11.884 17.2364 9.5 17.2364 11.884 17.2364H12.5644H13.2448C16.1267 17.2364 14.5 17.2364 16.1267 17.2364L16.3096 12.6449L16.91 14.0286C17.5962 15.6182 17.7906 15.8984 18.288 16.0642C18.9341 16.2815 19.5803 16.047 19.9233 15.4695C20.3064 14.8291 20.3179 14.8634 18.4824 10.6608C17.6133 8.66524 16.8414 6.92699 16.7671 6.8012C16.4754 6.30946 15.8865 5.88062 15.309 5.74911C15.0174 5.68049 10.3573 5.67477 9.90558 5.74339Z"
      fill=""
    />
    <path
      d="M22.9056 6.04869C22.3224 6.13446 21.579 6.65479 21.3103 7.17511C21.2645 7.27232 20.5098 8.98197 19.6407 10.9718C17.8109 15.1687 17.8224 15.1344 18.2055 15.7748C18.5485 16.3523 19.1947 16.5868 19.8408 16.3695C20.3268 16.2094 20.5155 15.9349 21.1731 14.4254C21.4875 13.6992 21.7677 13.0874 21.7963 13.0531C21.8249 13.0245 21.8478 13.3276 21.8478 17.5417C24.884 17.5417 22.5 17.5417 24.884 17.5417H25.5644H26.2448C29.1267 17.5417 27.5 17.5417 29.1267 17.5417L29.3096 12.9502L29.91 14.3339C30.5962 15.9235 30.7906 16.2037 31.288 16.3695C31.9341 16.5868 32.5803 16.3523 32.9233 15.7748C33.3064 15.1344 33.3179 15.1687 31.4824 10.9661C30.6133 8.97054 29.8414 7.23229 29.7671 7.1065C29.4754 6.61476 28.8865 6.18592 28.309 6.05441C28.0174 5.98579 23.3573 5.98008 22.9056 6.04869Z"
      fill=""
    />
    <path
      d="M24.7215 0.103273C23.7038 0.400604 22.9833 1.13821 22.7203 2.15028C22.3429 3.59691 23.2806 5.11787 24.7902 5.51241C26.7514 6.0213 28.6612 4.11152 28.1523 2.15028C27.752 0.617884 26.1796 -0.325569 24.7215 0.103273Z"
      fill=""
    />
    <path
      d="M2.38908 11.0161C2.15783 11.0692 1.99221 11.163 1.81408 11.3411C1.75158 11.402 1.67971 11.4614 1.65158 11.4739C1.56096 11.5114 1.46252 11.5005 1.3344 11.438C1.17658 11.363 1.0719 11.338 0.912521 11.338C0.651583 11.338 0.456271 11.4192 0.270333 11.6052C0.0843959 11.7895 -0.00154158 11.9973 2.09175e-05 12.2614C0.00158342 12.4817 0.0922084 12.7083 0.237521 12.8567L0.293771 12.9161H0.798458H1.30158L1.30627 13.6333L1.31096 14.3489L1.36565 14.4567C1.4844 14.688 1.73908 14.7864 1.98596 14.6942C2.10627 14.6489 2.22346 14.5317 2.26877 14.4114C2.30158 14.3239 2.30315 14.2989 2.30315 13.6192V12.9161H3.61408H4.92502L4.98908 12.8427C5.13596 12.677 5.2094 12.4802 5.2094 12.2536C5.2094 11.9958 5.12346 11.7833 4.94377 11.6036C4.76877 11.427 4.55315 11.338 4.30315 11.338C4.14533 11.338 4.02815 11.3661 3.87346 11.4395C3.6719 11.5364 3.5719 11.5145 3.38752 11.3302C3.17033 11.1145 2.93596 11.0098 2.6469 11.0005C2.54221 10.9973 2.44846 11.002 2.38908 11.0161Z"
      fill=""
    />
    <path
      d="M0.305859 15.9973L0.310547 18.6113L0.344922 18.6848C0.391797 18.7879 0.507422 18.9051 0.604297 18.9488L0.685547 18.9863H2.60742H4.5293L4.60273 18.952C4.71211 18.902 4.80742 18.8051 4.85898 18.6926L4.9043 18.5957L4.90898 15.9895L4.91211 13.3848H3.8418H2.77148V13.866C2.77148 14.4066 2.75898 14.4957 2.65898 14.6863C2.58398 14.8316 2.38711 15.0223 2.24023 15.0926C2.01523 15.202 1.79492 15.2254 1.56055 15.166C1.2418 15.0848 0.979297 14.8285 0.871484 14.4941C0.848047 14.4207 0.843359 14.3316 0.837109 13.8957L0.830859 13.3848H0.566797H0.302734L0.305859 15.9973Z"
      fill=""
    />
    <path d="M1.5 17.5, L35.5 17.5" fill="" />
  </svg>
</template>

<script>
export default {
  name: "CustomersInStoreIcon"
};
</script>

<style lang="scss" scoped></style>
