<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50056 3.74316C5.42869 3.74316 3.74316 5.42902 3.74316 7.50089C3.74316 9.57275 5.42869 11.2586 7.50056 11.2586C9.57209 11.2586 11.258 9.57309 11.258 7.50089C11.258 5.42869 9.57209 3.74316 7.50056 3.74316Z"
      fill="currentColor"
    />
    <path
      d="M7.50219 2.63891C7.09725 2.63891 6.76904 2.31071 6.76904 1.9061V0.733142C6.76904 0.328203 7.09725 0 7.50219 0C7.90712 0 8.23533 0.328203 8.23533 0.733142V1.9061C8.23533 2.31071 7.90679 2.63891 7.50219 2.63891Z"
      fill="currentColor"
    />
    <path
      d="M7.50219 12.3623C7.09725 12.3623 6.76904 12.6905 6.76904 13.0954V14.2681C6.76904 14.6733 7.09725 15.0015 7.50219 15.0015C7.90712 15.0015 8.23533 14.6733 8.23533 14.2681V13.0954C8.23533 12.6905 7.90679 12.3623 7.50219 12.3623Z"
      fill="currentColor"
    />
    <path
      d="M10.9372 4.06317C10.6512 3.77683 10.6512 3.31276 10.9372 3.02641L11.7666 2.19693C12.0527 1.91092 12.5171 1.91092 12.8034 2.19693C13.0898 2.48328 13.0898 2.94768 12.8034 3.2337L11.9739 4.06317C11.6879 4.34952 11.2238 4.34952 10.9372 4.06317Z"
      fill="currentColor"
    />
    <path
      d="M4.06427 10.9396C3.77793 10.6529 3.31386 10.6529 3.02751 10.9396L2.19803 11.7688C1.91202 12.0548 1.91169 12.5195 2.19803 12.8055C2.48438 13.0915 2.94878 13.0915 3.2348 12.8055L4.06427 11.9757C4.35062 11.6897 4.35062 11.2253 4.06427 10.9396Z"
      fill="currentColor"
    />
    <path
      d="M12.3599 7.49974C12.3599 7.0948 12.6881 6.7666 13.093 6.7666H14.266C14.6709 6.7666 14.9991 7.0948 14.9991 7.49974C14.9991 7.90468 14.6709 8.23255 14.266 8.23255H13.093C12.6881 8.23255 12.3599 7.90468 12.3599 7.49974Z"
      fill="currentColor"
    />
    <path
      d="M2.63891 7.49974C2.63891 7.0948 2.31071 6.7666 1.90577 6.7666H0.733142C0.328203 6.7666 0 7.0948 0 7.49974C0 7.90468 0.328203 8.23255 0.733142 8.23255H1.9061C2.31071 8.23255 2.63891 7.90468 2.63891 7.49974Z"
      fill="currentColor"
    />
    <path
      d="M10.9374 10.9401C11.2238 10.6541 11.6882 10.6541 11.9742 10.9401L12.8037 11.7696C13.09 12.0553 13.09 12.52 12.8037 12.806C12.5173 13.092 12.0532 13.092 11.7669 12.806L10.9374 11.9765C10.6511 11.6902 10.6511 11.2261 10.9374 10.9401Z"
      fill="currentColor"
    />
    <path
      d="M4.0644 4.06309C4.35075 3.77674 4.35075 3.31267 4.0644 3.02633L3.23492 2.19718C2.94857 1.91084 2.48451 1.91084 2.19816 2.19718C1.91181 2.4832 1.91181 2.9476 2.19816 3.23361L3.02764 4.06309C3.31398 4.34977 3.77805 4.34977 4.0644 4.06309Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "SunIcon"
};
</script>
