import Vue from "vue";
import dayjs from "@/plugins/dayjs";

Vue.mixin({
  data() {
    return {
      tableItemPerPage: 15
    };
  },
  methods: {
    getDailyReportWeatherIcons(item) {
      let icons = [null, "$sun", "$cloudy", "$umbrella"];
      let newIcons = [];

      newIcons.push(icons[item?.lunch_weather]); // lunch weather
      newIcons.push(icons[item?.dinner_weather]); // dinner weather

      return [...new Set(newIcons)]; // return unique values
    },
    priceFormat(amount) {
      return this.$options.filters.formatMoney(amount);
    },
    formatNumber(value) {
      return this.$options.filters.formatNumber(value);
    },
    formatDate(date, format = "YYYY/MM/DD") {
      return dayjs(date).format(format);
    },
    getAuthShopUserDataApi() {
      if (!this.user) {
        return;
      }

      let authUserData = {
        shop_id: this.$store.getters.getFirstShopObject.id, //Shop Id
        user_id: this.user.id
      };

      return this.$store
        .dispatch("SHOP_USER_AUTH", authUserData)
        .finally(() => {
          this.pageLoading = false;
        });
    }
  },
  filters: {
    formatDate(value, format = "YYYY/MM/DD") {
      return dayjs(value).format(format);
    },
    formatNumber(value) {
      if (!Number.isInteger(value)) return value;
      return Number(value).toLocaleString("ja-JP");
    },
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;

      let currencyReplaced = false;
      return new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      })
        .formatToParts(value)
        .map((item, idx, arr) => {
          if (
            (item.type === "currency" || item.type === "literal") &&
            currencyReplaced
          )
            return "";
          const nextCurrency =
            arr[idx + 1] &&
            arr[idx + 1].type === "currency" &&
            arr[idx + 1].value;
          if (item.type === "minusSign" && nextCurrency && !currencyReplaced) {
            currencyReplaced = true;
            return `${nextCurrency}${item.value}`;
          }
          return `${item.value}`;
        })
        .join("");
    }
  }
});
