<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="#ababab"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93944 1.47637V1.01465C7.93944 0.771748 7.74289 0.575195 7.49999 0.575195C7.25709 0.575195 7.06053 0.771748 7.06053 1.01465V1.47637C3.14601 1.70579 0 4.9537 0 8.9248C0 9.1677 0.196552 9.36425 0.439452 9.36425C0.682352 9.36425 0.878905 9.1677 0.878905 8.9248C0.878905 8.81537 1.34669 8.48534 2.19726 8.48534C3.04784 8.48534 3.54492 8.81537 3.54492 8.9248C3.54492 9.1677 3.74147 9.36425 3.98437 9.36425C4.22727 9.36425 4.42382 9.1677 4.42382 8.9248C4.42382 8.81537 4.8916 8.48534 5.74218 8.48534C6.59275 8.48534 7.06053 8.81537 7.06053 8.9248V13.8174C7.06053 14.7868 7.84888 15.5752 8.81834 15.5752C9.7878 15.5752 10.5762 14.7868 10.5762 13.8174C10.5762 13.5745 10.3796 13.3779 10.1367 13.3779C9.8938 13.3779 9.69725 13.5745 9.69725 13.8174C9.69725 14.3019 9.30285 14.6963 8.81834 14.6963C8.33383 14.6963 7.93944 14.3019 7.93944 13.8174V8.9248C7.93944 8.81537 8.40722 8.48534 9.25779 8.48534C10.0266 8.48534 10.4624 8.74805 10.543 8.88064C10.5428 8.88305 10.5429 8.88548 10.5427 8.88788C10.5238 9.12949 10.7045 9.34107 10.9465 9.36038C10.9581 9.36123 10.9697 9.36167 10.9813 9.36167C10.9852 9.36167 10.9885 9.35968 10.9923 9.35959C11.0005 9.36003 11.0074 9.36425 11.0156 9.36425C11.2585 9.36425 11.4551 9.1677 11.4551 8.9248C11.4551 8.81537 11.9229 8.48534 12.7734 8.48534C13.624 8.48534 14.1211 8.81537 14.1211 8.9248C14.1211 9.1677 14.3176 9.36425 14.5605 9.36425C14.8034 9.36425 15 9.1677 15 8.9248C15 4.9537 11.854 1.70579 7.93944 1.47637Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "UmbrellaIcon"
};
</script>
