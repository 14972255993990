import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
Vue.use(VueRouter);
var routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "Top" },
    meta: { forRoles: ["M", "S", "P"] }
  },
  {
    path: "/top",
    name: "Top",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home/index"),
    meta: {
      title: "トップ",
      showShopDropdown: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/confirm-invitation",
    name: "ConfirmInvitaion",
    component: () =>
      import(
        /* webpackChunkName: "confirm-invitation" */ "../views/auth/ConfirmInvitaion"
      ),
    meta: {
      title: "店舗に追加されました",
      allAccess: true,
      pubilc: true,
      isWithoutHeader: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/login-top",
    name: "AuthPage",
    component: () =>
      import(/* webpackChunkName: "auth-page" */ "../views/auth/AuthPage"),
    meta: {
      title: "",
      public: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/Login"),
    meta: {
      title: "ログイン",
      public: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/forgot-password",
    name: "forgot_password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPassword"
      ),
    meta: {
      title: "パスワード再発行",
      public: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/auth/Register"),
    meta: {
      title: "新規登録",
      public: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/reset-password/:token/:email",
    name: "reset_password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/auth/ResetPassword"
      ),
    meta: {
      title: "パスワード再設定",
      public: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/profile/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "setting" */ "../views/user/Setting"),
    meta: {
      title: "プロフィールの編集",
      forRoles: ["M", "P"]
    }
  },
  {
    path: "/profile/edit",
    name: "edit_profile",
    component: () =>
      import(
        /* webpackChunkName: "profile-edit" */ "../views/user/EditProfile"
      ),
    meta: { title: "プロフィールの編集", forRoles: ["M", "P"] }
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "notifications" */ "../views/user/Withdraw"),
    meta: { title: "退会", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/contact",
    name: "contact_form",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactForm"),
    meta: { title: "コンタクトフォーム", forRoles: ["M", "P"] }
  },
  {
    path: "/change-password",
    name: "change_password",
    component: () =>
      import(
        /* webpackChunkName: "change_password" */ "../views/user/ChangePassword"
      ),
    meta: { title: "パスワードリセット", forRoles: ["M", "P"] }
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import(/* webpackChunkName: "faq" */ "../views/FAQ"),
    meta: {
      public: true,
      allAccess: true,
      title: "よくある質問",
      forRoles: ["M", "P"]
    }
  },
  {
    path: "/privacy-policy",
    name: "privacy_policy",
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy"),
    meta: {
      title: "プライバシーポリシー",
      allAccess: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/terms-conditions",
    name: "terms_and_conditions",
    component: () =>
      import(
        /* webpackChunkName: "terms-conditions" */ "../views/TermsAndConditions"
      ),
    meta: {
      title: "利用規約",
      allAccess: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/notifications",
    name: "notifications_list",
    component: () =>
      import(/* webpackChunkName: "notifications" */ "../views/Notifications"),
    meta: { title: "お知らせ一覧", forRoles: ["M", "S", "P"] }
  },

  {
    path: "/notifications/category/:category_id",
    name: "notifications_list_category",
    component: () =>
      import(/* webpackChunkName: "notifications" */ "../views/Notifications"),
    meta: { title: "お知らせ一覧", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/notifications/tag/:tag_id",
    name: "notifications_list_tag",
    component: () =>
      import(/* webpackChunkName: "notifications" */ "../views/Notifications"),
    meta: { title: "お知らせ一覧", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/notifications/:id",
    name: "notification_detail",
    component: () =>
      import(
        /* webpackChunkName: "notification-detail" */ "../components/layout/notifications/NotificationDetail"
      ),
    meta: { title: "お知らせ一覧", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/link-posts",
    name: "link_posts",
    component: () =>
      import(/* webpackChunkName: "link_posts" */ "../views/LinkPosts"),
    meta: { title: "お知らせ一覧", forRoles: ["M", "S", "P"] }
  },

  {
    path: "/shop-registration",
    name: "add_store",
    component: () =>
      import(/* webpackChunkName: "shop_reg" */ "../views/shop-reg/AddStore"),
    meta: {
      title: "店舗登録",
      isWithoutHeader: true,
      forRoles: ["M", "P"]
    }
  },

  {
    path: "/shop-registration/details",
    name: "ShopRegForm",
    component: () =>
      import(
        /* webpackChunkName: "shop_reg" */ "../views/shop-reg/ShopRegForm"
      ),
    meta: {
      isWithoutHeader: true,
      title: "店舗登録",
      forRoles: ["M", "P"]
    }
  },
  {
    path: "/shop",
    name: "managedStores",
    component: () =>
      import(
        /* webpackChunkName: "managedStores" */ "../views/managed-stores/ListStores"
      ),
    meta: { title: "管理店舗一覧", forRoles: ["M", "P"] }
  },
  {
    path: "/shop-detail/:id",
    name: "StoreDetails",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "managedStores" */ "../views/managed-stores/StoreDetails"
      ),
    meta: { title: "店舗登録", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/new-user-registration/:shop",
    name: "addUser",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "addUser" */ "../views/managed-stores/AddUser"
      ),
    meta: { title: "ユーザー追加", forRoles: ["M", "P"] }
  },
  {
    path: "/invite-new-user/:shop",
    name: "inviteUser",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "inviteUser" */ "../views/managed-stores/InviteUser"
      ),
    meta: { title: "ユーザー追加", forRoles: ["M", "P"] }
  },
  {
    path: "/add-new-user/:shop",
    name: "createUser",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "createUser" */ "../views/managed-stores/CreateUser"
      ),
    meta: { title: "ユーザー追加", forRoles: ["M", "P"] }
  },
  {
    path: "/user-info/:id",
    name: "userDetails",
    component: () =>
      import(
        /* webpackChunkName: "userDetails" */ "../views/managed-stores/UserDetails"
      ),
    meta: { title: "ユーザー情報", forRoles: ["M", "P"] },
    props: true
  },
  {
    path: "/shared-account-registration/:shop",
    name: "SharedAccountRegistration",
    component: () =>
      import(
        /* webpackChunkName: "SharedAccountRegistration" */ "../views/shared-accounts/register"
      ),
    meta: {
      title: "共有用アカウント作成",
      forRoles: ["M", "P"]
      // isWithoutHeader: true
    },
    props: true
  },
  {
    path: "/shared-account/:id",
    name: "SharedAccountIndex",
    component: () =>
      import(
        /* webpackChunkName: "SharedAccountStepIndex" */ "../views/shared-accounts/index"
      ),
    meta: { title: "共有用アカウント", forRoles: ["M", "P"] },
    props: true
  },
  {
    path: "/supplier-list",
    name: "SupplierListing",
    component: () =>
      import(
        /* webpackChunkName: "SupplierListing" */ "../views/supplier/index"
      ),
    meta: { title: "取引先一覧", showShopDropdown: true, forRoles: ["M", "P"] }
  },
  {
    path: "/supplier/:id",
    name: "SupplierEdit",
    component: () =>
      import(/* webpackChunkName: "SupplierEdit" */ "../views/supplier/edit"),
    meta: { title: "取引先一覧", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/supplier/new",
    name: "SupplierCreate",
    component: () =>
      import(
        /* webpackChunkName: "SupplierCreate" */ "../views/supplier/create"
      ),
    meta: { title: "取引先一覧", forRoles: ["M", "P"] }
  },
  {
    path: "/supplier-details/:id",
    name: "SupplierDetails",
    component: () =>
      import(
        /* webpackChunkName: "SupplierDetails" */ "../views/supplier/SupplierDetails"
      ),
    meta: { title: "取引先", forRoles: ["M", "P"] }
  },
  {
    path: "/business-day-setting",
    name: "BusinessDaySetting",
    component: () =>
      import(
        /* webpackChunkName: "BusinessDaySetting" */ "../views/business/index"
      ),
    meta: {
      title: "営業日管理",
      showShopDropdown: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/mailing-list/edit/:id",
    name: "editSupplierMail",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "editSupplierMail" */ "../views/mail/EditMail"
      ),
    meta: { title: "メール通知管理", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/mailing-list/",
    name: "mailingList",
    component: () =>
      import(/* webpackChunkName: "mailingList" */ "../views/mail/MailingList"),
    meta: {
      title: "メール通知管理",
      showShopDropdown: true,
      forRoles: ["M", "P"]
    }
  },
  {
    path: "/mailing-list/add-mail",
    name: "addSupplierMail",
    component: () =>
      import(/* webpackChunkName: "addSupplierMail" */ "../views/mail/AddMail"),
    meta: { title: "メール通知管理", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/cashbook/edit/:id",
    name: "cashbookEdit",
    component: () =>
      import(
        /* webpackChunkName: "cashbookCreateEdit" */ "../views/cashbook/CashbookCreateEdit"
      ),
    meta: { title: "売上・コスト登録", forRoles: ["M", "P"] }
  },
  {
    path: "/cashbook/create",
    name: "cashbookCreate",
    component: () =>
      import(
        /* webpackChunkName: "cashbookCreateEdit" */ "../views/cashbook/CashbookCreateEdit"
      ),
    meta: { title: "現金出納登録", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/cashbook",
    name: "cashbookList",
    component: () =>
      import(/* webpackChunkName: "CashBookList" */ "../views/cashbook/Index"),
    meta: { title: "現金出納表", showShopDropdown: true, forRoles: ["M", "P"] }
  },
  {
    path: "/accounts/",
    name: "accountsList",
    component: () =>
      import(
        /* webpackChunkName: "accountsList" */ "../views/accounts-payable/Index"
      ),
    meta: { title: "買掛表", showShopDropdown: true, forRoles: ["M", "P"] }
  },
  {
    path: "/accounts/create",
    name: "accountsPayableCreate",
    component: () =>
      import(
        /* webpackChunkName: "accountsPayableCreate" */ "../views/accounts-payable/AccountsCreateEdit"
      ),
    meta: { title: "買掛登録", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/accounts/edit/:id",
    name: "accountsPayableEdit",
    component: () =>
      import(
        /* webpackChunkName: "accountsPayableEdit" */ "../views/accounts-payable/AccountsCreateEdit"
      ),
    meta: { title: "売上・コスト登録", forRoles: ["M", "P"] }
  },
  {
    path: "/fixed-cost/",
    name: "fixedCostList",
    component: () =>
      import(
        /* webpackChunkName: "fixedCostList" */ "../views/fixed-cost/Index"
      ),
    meta: { title: "固定費設定", showShopDropdown: true, forRoles: ["M", "P"] }
  },
  {
    path: "/fixed-cost/edit",
    name: "costInput",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "costInput" */ "../views/fixed-cost/CostInput"
      ),
    meta: { title: "月コスト管理", forRoles: ["M", "P"] }
  },
  {
    path: "/daily-report/",
    name: "drList",
    component: () =>
      import(/* webpackChunkName: "drList" */ "../views/daily-report/Index"),
    meta: { title: "日報一覧", showShopDropdown: true, forRoles: ["M", "P"] }
  },
  {
    path: "/daily-report/input/:date",
    name: "drInput",
    component: () =>
      import(/* webpackChunkName: "DrInput" */ "../views/daily-report/DrInput"),
    meta: { title: "日報作成", forRoles: ["M", "S", "P"] }
  },
  {
    path: "/daily-report/confirm/:date",
    name: "drConfirm",
    component: () =>
      import(
        /* webpackChunkName: "drConfirm" */ "../views/daily-report/DrConfirm"
      ),
    meta: { title: "日報作成", forRoles: ["M", "P"] }
  },
  {
    path: "/daily-report/view/:id",
    name: "drView",
    component: () =>
      import(
        /* webpackChunkName: "drConfirm" */ "../views/daily-report/DrView"
      ),
    meta: {
      title: "日報作成",
      isWithoutHeader: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/inventory-management/",
    name: "InventoryManagementList",
    component: () =>
      import(
        /* webpackChunkName: "InventoryManagementList" */ "../views/inventory-management/Index"
      ),
    meta: { title: "棚卸管理", showShopDropdown: true, forRoles: ["M", "P"] }
  },
  {
    path: "/inventory-management/edit/",
    name: "InventoryManagementEdit",
    component: () =>
      import(
        /* webpackChunkName: "InventoryManagementEdit" */ "../views/inventory-management/InventoryManagementEdit"
      ),
    meta: { title: "棚卸設定", forRoles: ["M", "P"] }
  },
  {
    path: "/cash-sales",
    name: "CashSalesList",
    component: () =>
      import(
        /* webpackChunkName: "CashSalesList" */ "../views/cash-sales/Index"
      ),
    meta: {
      title: "現金売上",
      showShopDropdown: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/goal-setting",
    name: "GoalSetting",
    component: () =>
      import(/* webpackChunkName: "costInput" */ "../views/goal-setting/Index"),
    meta: {
      title: "売上目標の設定",
      showShopDropdown: true,
      forRoles: ["M", "P"]
    }
  },
  {
    path: "/report",
    name: "BusinessReportIndex",
    component: () =>
      import(/* webpackChunkName: "costInput" */ "../views/report/Index"),
    meta: {
      title: "日報作成",
      showShopDropdown: true,
      forRoles: ["M", "S", "P"]
    }
  },
  {
    path: "/monthly-report",
    name: "BusinessReportBudget",
    component: () =>
      import(
        /* webpackChunkName: "costInput" */ "../views/report/yearlyBudget"
      ),
    meta: {
      title: "月報一覧",
      showShopDropdown: true,
      forRoles: ["M", "P"]
    }
  },
  {
    path: "/monthly-report/:date",
    name: "monthlyReport",
    component: () =>
      import(
        /* webpackChunkName: "monthlyReport" */ "../views/monthly-report/index"
      ),
    meta: {
      title: "売上目標の設定",
      isWithoutHeader: true,
      forRoles: ["M", "S", "P"]
    }
  },
  // All route should be defined above this route(not found route).
  {
    path: "*",
    name: "Error",
    component: () => import("../views/error/Error"),
    meta: {
      fullWidthPage: true,
      forRoles: ["M", "S", "P"]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let previousUrl = window.location.host + from.fullPath;
  localStorage.setItem("previousUrl", previousUrl);

  if (to.meta.allAccess) return next();

  if (store.state.modalVisible) store.commit("hideModal");

  const authRequired = !to.matched.some(record => record.meta.public);
  const loggedIn = store.getters.token;

  store.commit("clearFormErrors");

  if (authRequired && !loggedIn) {
    if (to.fullPath !== "" && to.fullPath !== "/") {
      next({
        path: "/login-top",
        query: { redirect: to.fullPath }
      });
    } else {
      next({ name: "AuthPage" });
    }
  } else if (!authRequired && loggedIn) {
    next({ name: "Top" });
  }

  next();
});

// title
router.afterEach(to => {
  if (to.meta.title !== undefined) {
    document.title =
      to.meta.title != ""
        ? `${to.meta.title} | Cross Point 日報管理アプリ`
        : `Cross Point 日報管理アプリ`;
  }
});

export default router;
