export const FIXEDCOST_REQUEST = "FIXEDCOST_REQUEST";

export const FIXEDCOST_GET_ALL = "FIXEDCOST_GET_ALL";
export const FIXEDCOST_GET_ALL_SUCCESS = "FIXEDCOST_GET_ALL_SUCCESS";

export const FIXEDCOST_REGISTER = "FIXEDCOST_REGISTER";
export const FIXEDCOST_REGISTER_SUCCESS = "FIXEDCOST_REGISTER_SUCCESS";

export const FIXEDCOST_GET_BY_YEAR_MONTH = "FIXEDCOST_GET_BY_YEAR_MONTH";
export const FIXEDCOST_GET_BY_YEAR_MONTH_SUCCESS = "FIXEDCOST_GET_BY_YEAR_MONTH_SUCCESS";

export const FIXEDCOST_GET_PREVIOUS_MONTH = "FIXEDCOST_GET_PREVIOUS_MONTH";
export const FIXEDCOST_GET_PREVIOUS_MONTH_SUCCESS = "FIXEDCOST_GET_PREVIOUS_MONTH_SUCCESS";

export const FIXEDCOST_UPDATE = "FIXEDCOST_UPDATE";
export const FIXEDCOST_UPDATE_SUCCESS = "FIXEDCOST_UPDATE_SUCCESS";

export const FIXEDCOST_DELETE = "FIXEDCOST_DELETE";
export const FIXEDCOST_DELETE_SUCCESS = "FIXEDCOST_DELETE_SUCCESS";