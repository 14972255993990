import axios from "@/services/api"; 
import {
    FIXEDCOST_REQUEST,
    FIXEDCOST_GET_ALL,
    FIXEDCOST_GET_ALL_SUCCESS,
    FIXEDCOST_GET_BY_YEAR_MONTH,
    FIXEDCOST_GET_BY_YEAR_MONTH_SUCCESS,
    FIXEDCOST_REGISTER,
    FIXEDCOST_REGISTER_SUCCESS,
    FIXEDCOST_UPDATE,
    FIXEDCOST_UPDATE_SUCCESS,
    FIXEDCOST_GET_PREVIOUS_MONTH,
    FIXEDCOST_GET_PREVIOUS_MONTH_SUCCESS
} from "@/store/actions/fixed-cost";

function initialState() {
  return {
    fixedcosts: [],
    fixedcostByYearMonth: {},
    previousFixedCost:{}
  };
}

const state = initialState();

const getters = {
  getFixedCosts: state => state.fixedcosts,
  getFixedcostByYearMonth: state => state.fixedcostByYearMonth,
  getPreviousFixedCost: state => state.previousFixedCost
};

const actions = {
  [FIXEDCOST_GET_ALL]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("FIXEDCOST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.id}/fixed-cost-monthly`)
        .then(response => {
          commit("FIXEDCOST_GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [FIXEDCOST_GET_BY_YEAR_MONTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("FIXEDCOST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/fixed-cost-monthly?year_month=${params.year_month}`)
        .then(response => {
          commit("FIXEDCOST_GET_BY_YEAR_MONTH_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [FIXEDCOST_GET_PREVIOUS_MONTH]: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit("FIXEDCOST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .get(`/user/shops/${params.shop_id}/previous-fixed-cost?year_month=${params.year_month}`)
        .then(response => {
          commit("FIXEDCOST_GET_PREVIOUS_MONTH_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  },

  [FIXEDCOST_REGISTER]:({ commit, dispatch },{params,shop_id})=>{
    return new Promise((resolve,reject) => {
      commit('FIXEDCOST_REQUEST');
      dispatch('API_PROCESSING', true, { root: true });
      axios
        .post(`/user/shops/${shop_id}/fixed-cost-monthly`, params)
        .then(response => {
          commit("FIXEDCOST_REGISTER_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    })
  },

  [FIXEDCOST_UPDATE]: ({ commit, dispatch }, {params,shop_id,fixed_cost_id}) => {
    return new Promise((resolve, reject) => {
      commit("FIXEDCOST_REQUEST");
      dispatch("API_PROCESSING", true, { root: true });
      axios
        .put(`/user/shops/${shop_id}/fixed-cost-monthly/${fixed_cost_id}`, params)
        .then(response => {
          commit("FIXEDCOST_UPDATE_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          dispatch("API_PROCESSING", false, { root: true });
        });
    });
  }
};

const mutations = {
  [FIXEDCOST_REQUEST]: state => {
    state.status = "loading";
  },

  [FIXEDCOST_GET_ALL_SUCCESS]: (state, params) => {
    state.status = "success";
    state.fixedcosts = params.data.fixed_cost_monthly;
  },

  [FIXEDCOST_GET_BY_YEAR_MONTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.fixedcostByYearMonth = params.data.fixed_cost_monthly;
  },

  [FIXEDCOST_GET_PREVIOUS_MONTH_SUCCESS]: (state, params) => {
    state.status = "success";
    state.previousFixedCost = params.data.fixed_cost_monthly;
  },

  [FIXEDCOST_REGISTER_SUCCESS]: (state, params) => {
    state.status = "success";
  },

  [FIXEDCOST_UPDATE_SUCCESS]: (state, params) => {
    state.status = "success";
  }
  
};

export default {
  state,
  getters,
  actions,
  mutations
};
