<template>
  <svg
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="14px"
    height="14px"
    viewBox="0 0 45.125 46.125"
    enable-background="new 0 0 45.125 46.125"
    xml:space="preserve"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="currentColor"
        d="M43.702,4.442c0,0.285,0,0.464,0,0.642c0,3.799,0,7.597,0,11.396
		c0,3.767-0.007,7.533,0.01,11.299c0.002,0.398-0.13,0.638-0.433,0.876c-1.669,1.312-3.393,2.532-5.319,3.451
		c-3.532,1.685-6.979,1.511-10.363-0.418c-1.47-0.837-2.934-1.685-4.428-2.476c-3.629-1.922-7.294-1.788-10.939-0.054
		c-1.11,0.528-2.162,1.182-3.241,1.778c-0.131,0.072-0.257,0.152-0.437,0.26c-0.014-0.178-0.031-0.305-0.031-0.433
		C8.52,23.106,8.518,15.449,8.527,7.791C8.528,7.606,8.602,7.337,8.734,7.25c2.09-1.364,4.273-2.526,6.746-3.046
		c2.851-0.599,5.511-0.041,8.032,1.321c1.648,0.892,3.26,1.849,4.921,2.714c3.272,1.704,6.537,1.491,9.785-0.12
		c1.855-0.921,3.522-2.118,5.136-3.401C43.437,4.652,43.52,4.587,43.702,4.442z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="currentColor"
        d="M4.331,1c1.29,0.004,2.445,0.778,2.934,1.967
		c0.492,1.199,0.255,2.591-0.679,3.46C6.208,6.778,6.114,7.131,6.115,7.613c0.011,10.34,0.011,20.68,0,31.019
		c0,0.365,0.1,0.608,0.368,0.859c1.049,0.98,1.318,2.466,0.717,3.729c-0.596,1.253-1.97,1.988-3.356,1.798
		c-1.358-0.186-2.46-1.219-2.707-2.61c-0.214-1.202,0.168-2.217,1.072-3.047c0.134-0.123,0.243-0.351,0.243-0.53
		C2.463,28.292,2.464,17.755,2.449,7.217c0-0.202-0.143-0.45-0.296-0.598c-1.026-0.99-1.354-2.363-0.84-3.639
		C1.807,1.763,2.974,0.997,4.331,1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FlagIcon"
};
</script>

<style scoped></style>
